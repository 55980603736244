import { TableContainer, Paper, Table, TableRow, TableHead, TableCell, TableBody, Button, TextField, TableFooter, TablePagination, IconButton, Dialog, DialogActions, DialogTitle, DialogContent, FormControl, InputLabel, Select, MenuItem, Tooltip, Collapse, Grid, Typography, Toolbar, AppBar } from "@mui/material";
import { useEffect, useRef, useState, useTransition } from "react";
import { useGlobalContext } from "../global_context";
import SyncIcon  from '@mui/icons-material/Sync';
import localization from '../localization.json';
import CloseIcon from '@mui/icons-material/Close';
import { getPayment, getPayments } from "../api/payment";

export default function PaymentsTable(props){
    const didMount = useRef(false);

    const [isPending, startTransition] = useTransition();
    const [context,setContext] = useGlobalContext();
    const [payments,setPayments] = useState([]);
    const [count, setCount] = useState(0);

    const [orderId,setOrderId] = useState("");
    const [transId,setTransId] = useState("");
    const [customerNo,setCustomerNo] = useState("");
    const [docNo,setDocNo] = useState("");
    const [page,setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);

    useEffect(() => {
        if (!didMount.current) return;
        loadPayments();
    },[orderId,transId,customerNo,docNo,page,rowsPerPage]);

    const loadPayments = () => {
        startTransition( async () => {
            let res = await getPayments(orderId,transId,customerNo,docNo,page,rowsPerPage);
            if (res.message === "Not authorized") return;
            setCount(res.count);
            setPayments(res.payments);
        });
    }

    
    useEffect(() => {
        if (!didMount.current) didMount.current = true;
        loadPayments();
    }, [])

    const handleChangePage = (e,n) => {
        setPage(n);
    }

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }

    const headProps = {orderId,setOrderId,transId,setTransId,docNo,setDocNo,customerNo,setCustomerNo,loadPayments};
    
    return (
    <div style={{height:"100%", width:"100%", position: "relative"}}>
        <TableContainer component={Paper} sx={{height:"calc(100% - 52px)", borderBottomLeftRadius:0, borderBottomRightRadius:0}}>
            <Table stickyHeader dense size="small">
                <DesktopHead props={headProps} />
                <TableBody>
                    {payments.map((payment) => {
                        const rowProps = {payment};
                        return <DesktopRow props={rowProps} />
                    })}
                </TableBody>
                <TableFooter >
                    
                </TableFooter>
            </Table>
        </TableContainer>
        <TablePagination
            rowsPerPageOptions={[50, 200, 1000]}
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{position:"absolute",right:0,bottom:0}}
        />
    </div>);
}

function DesktopHead(props){
    const [context,setContext] = useGlobalContext();
    const {orderId,setOrderId,transId,setTransId,docNo,setDocNo,customerNo,setCustomerNo,loadPayments} = props.props;
    return (
        <TableHead>
            <TableRow>
                <TableCell sx={{maxWidth:150}}>Date</TableCell>
                <TableCell sx={{maxWidth:100}}>
                    <TextField type="search" label="Order ID" size="small" value={orderId} onChange={(e)=>{
                        setOrderId(e.target.value);
                    }} />
                </TableCell>
                <TableCell sx={{maxWidth:200}}>
                    <TextField type="search" label="Transaction ID" size="small" value={transId} fullWidth onChange={(e)=>{
                        setTransId(e.target.value);
                    }} />
                </TableCell>
                <TableCell sx={{maxWidth:100}}>
                    <TextField type="search" label="Document No." size="small" value={docNo} fullWidth onChange={(e)=>{
                        setDocNo(e.target.value.replace(/[^0-9]/g, '') || undefined);
                    }} />
                </TableCell>
                <TableCell sx={{maxWidth:100}}>
                    <TextField type="search" label="Customer No." size="small" value={customerNo} fullWidth onChange={(e)=>{
                        setCustomerNo(e.target.value.replace(/[^0-9]/g, '') || undefined);
                    }} />
                </TableCell>
                <TableCell width={50}>
                    <IconButton onClick={() => {loadPayments()}}>
                        <SyncIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
        </TableHead>
    )
}

function DesktopRow(props){
    const [context,setContext] = useGlobalContext();
    const { payment } = props.props
    return (
        <TableRow hover key={payment._id}>
            <TableCell>{new Date(payment.date).toLocaleString('en-GB')}</TableCell>
            <TableCell>{payment.order_id}</TableCell>
            <TableCell>{payment.trans_id}</TableCell>
            <TableCell>{payment.doc_no}</TableCell>
            <TableCell>{payment.customer_no}</TableCell>
            <TableCell>
                <PaymentDialog orderId={payment.order_id} />
            </TableCell>
        </TableRow>
    )
}

function PaymentDialog(props) {
    const {orderId} = props;
    const [open,setOpen] = useState(false);
    const [payment,setPayment] = useState({});

    const handleClose = () => {
        setOpen(false);
        setPayment({});
    }

    const loadPayment = async () => {
        setPayment(await getPayment(orderId));
    }

    const handleOpen = () => {
        setOpen(true);
        loadPayment();
    }

    return (
        <>
            <Button size="small" onClick={handleOpen} variant="outlined">
                View
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <AppBar sx={{ position: 'sticky' }}>
                    <Toolbar variant="dense">
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon fontSize="small"/>
                        </IconButton>
                        <Typography>Payment ({orderId})</Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Grid container gap={2} sx={{width:"100%"}}>
                        {Object.keys(payment).map(e => {
                            if (e === 'order_id') return <></>;
                            let value = payment[e];
                            if (e === 'date') value = new Date(value).toLocaleString('en-GB');
                            return (<Grid item xs={12}>
                                <TextField
                                    inputProps={{readOnly:true}}
                                    fullWidth
                                    value={value}
                                    label={e}
                                    variant="standard" 
                                />
                            </Grid>)
                        })}
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
}


