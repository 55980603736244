import request from "./request";

const getUsers = async () => {
    return await request("POST", "users/get")
}

const addUser = async (username,password,fullName) => {
    return await request("POST", "users/add", {username,password,fullName});
}

const removeUser = async (username) => {
    return await request("POST", "users/remove", {username});
}

const setPassword = async (username, password) => {
    return await request("POST", "users/password", {username, password})
}

const setFullName = async (username, fullName) => {
    return await request("POST", "users/full_name", {username, fullName});
}

const setAdmin = async (username, admin) => {
    return await request("POST", "users/admin", {username, admin})
}

export {
    getUsers,
    addUser,
    removeUser,
    setPassword,
    setFullName,
    setAdmin
}