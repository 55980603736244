import { Box, Button, Card, CardActions, CardContent, Divider, Grid, Paper, Typography } from '@mui/material';
import { useGlobalContext } from '../global_context';
import FiberBackground from '../images/fiber-background.jpg';
import localization from '../localization.json';

export default function HomeTab(props){
    const [context,setContext] = useGlobalContext();
    return (
    <Box sx={{height: 1, overflow: 'auto'}}>
        <Grid container sx={{width: 1, minHeight: 400}}>
            <Grid item xs={12} md={6} sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column', borderSize: 'border-box', p: 8}}>
                <Typography variant="h3" sx={{ mb: 1.5 }} color="text.secondary">
                    {localization.homePage.box1.part1[context.lang]}
                </Typography>
                <Typography>
                    {localization.homePage.box1.part2[context.lang]}
                    <br/><br/>
                    {localization.homePage.box1.part3[context.lang]}
                </Typography>
                <Button sx={{mt: 2, mb: 2}} size="small" fullWidth onClick={()=>{window.open("https://www.akcaba.com/")}}>{localization.homePage.box1.part4[context.lang]}</Button>
            </Grid>
            <Grid item xs={12} md={6}>
                <iframe width="100%" style={{height: '100%', minHeight: 400}} src="https://www.youtube.com/embed/NMRhq-8W9r0?autoplay=1&mute=1&loop=1&controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen" allowFullScreen></iframe>
            </Grid>
        </Grid>
        <Divider />
        <Grid container sx={{width: 1, bgcolor: '#ADD8E622'}}>
            <Grid item xs={12} md={6} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', borderSize: 'border-box', p: 8}}>
                <img 
                    src={require('../images/create-ticket.png')}
                    style={{
                        height: 400,
                        border: '1px solid lightgrey',
                        borderRadius: 4
                    }}
                />
            </Grid>
            <Grid item xs={12} md={6} sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column', borderSize: 'border-box', p: 8}}>
                <Typography variant="h4" sx={{ mb: 1.5}} color="text.secondary">
                    {localization.homePage.box2.part1[context.lang]}
                </Typography>
                <Typography>
                    {localization.homePage.box2.part2[context.lang]}
                    <br/> <br/>
                    {localization.homePage.box2.part3[context.lang]} <a style={{textDecoration: 'none'}} href="mailto:ticket@akcaba.com">ticket@akcaba.com</a>.
                </Typography>
                <Button sx={{mt: 2, mb: 2}} size="small" onClick={props.createTicket}>{localization.homePage.box2.part4[context.lang]}</Button>
            </Grid>

        </Grid>
        <Divider />
        <Grid container sx={{width: 1}}>
            <Grid item xs={12} md={6} sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column', borderSize: 'border-box', p: 8}}>
                <Typography variant="h4" sx={{ mb: 1.5 }} color="text.secondary">
                    {localization.homePage.box3.part1[context.lang]}
                </Typography>
                <Typography>
                    {localization.homePage.box3.part2[context.lang]}
                    <br/><br/>
                    {localization.homePage.box3.part3[context.lang]}
                </Typography>
            </Grid>
            <Grid item xs={12} md={6} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', borderSize: 'border-box', p: 8}}>
                <img 
                    src={require('../images/waiting.png')}
                    style={{
                        height: 300,
                    }}
                />
            </Grid>
        </Grid>
        <Divider />
        <Grid container sx={{width: 1, bgcolor: '#ADD8E622'}}>
            <Grid item xs={12} md={6} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', borderSize: 'border-box', p: 8}}>
                <img 
                    src={require('../images/repair.png')}
                    style={{
                        height: 300,
                    }}
                />
            </Grid>
            <Grid item xs={12} md={6} sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column', borderSize: 'border-box', p: 8}}>
                <Typography variant="h4" sx={{ mb: 1.5 }} color="text.secondary">
                    {localization.homePage.box4.part1[context.lang]}
                </Typography>
                <Typography>
                    {localization.homePage.box4.part2[context.lang]}
                    <br/><br/>
                    {localization.homePage.box4.part3[context.lang]}
                </Typography>
            </Grid>
        </Grid>
    </Box>
    );
}