import request from "./request";

const getTicket = async (id, _id) => {
    return await request("POST", "ticket/get", {id, _id});
}

const createTicket = async ({person, email, number, company, title, details, city, voucherId, priority}) => {
    return await request("POST", "ticket/create", {person, email, number, company, title, details, city, voucherId, priority});
}

const removeTicket = async (id,_id) => {
    return await request("POST", "ticket/remove", {id,_id});
}

const addResponse = async ({id, response, files}) => {
    let formData = new FormData();
    formData.append("id", id);
    formData.append("content", response);
    for (let file of files){
        formData.append("files", file);
    }
    return await request("POST", "ticket/add_response", formData, true);
}

const editResponse = async ({ticketId, responseId, content}) => {
    return await request("POST", "ticket/edit_response", {ticketId,responseId,content});
}

const deleteResponse = async ({ticketId, responseId}) => {
    return await request("POST", "ticket/delete_response", {ticketId,responseId});
}

const editTicket = async ({id, _id, person, company, number, title, city, priority}) => {
    return await request("POST", "ticket/edit", {id, _id, person, company, number, title, city, priority});
}

const editTicketDetails = async ({id, content}) => {
    return await request("POST", "ticket/edit_details", {id, content});
}

const setStatus = async (id, status) => {
    return await request("POST", "ticket/set_status", {id, status});
}

const getCustomers = async () => {
    return await request("POST", "ticket/get_customers");
}

const getContacts = async (customer_no) => {
    return await request("POST", "ticket/get_contacts", {customer_no});
}

const getProblemTypes = async () => {
    return await request("POST", "ticket/get_problem_types");
}

const getCallTypes = async () => {
    return await request("POST", "ticket/get_call_types");
}

const getEmployees = async () => {
    return await request("POST", "ticket/get_employees");
}

const getPriorities = async () => {
    return await request("POST", "ticket/get_priorities");
}

const setComplete = async (id) => {
    return await setStatus(id,"Complete");
}

const addServiceCall = async (id, _id, customer_no, contact_code, title, problemType, callType, technician, priority) => {
    return await request("POST", "ticket/add_service_call",
    {
        id, _id, customer_no, contact_code, title, problemType, callType, technician, priority
    });
}

const getTickets = async (status,company,title,page,rowsPerPage,callID,fromDate,toDate,city,jobBy,idSort) => {
    return await request("POST", "ticket/get_all", {status,company,title,page,rowsPerPage,callID,fromDate,toDate,city,jobBy,idSort});
}

const updateCallID = async (DocNum) => {
    return await request("POST", "ticket/call_id", {DocNum});
}

const getContactByNo = async (no) => {
    return await request("POST", "ticket/get_contact", {no});
}

const setJobBy = async (id,username) => {
    return await request("POST", "ticket/set_job_by", {id,username});
}

const setServiceTimes = async (id,times) => {
    return await request("POST", "ticket/set_service_times", {id,times});
}

const setServiceExpenses = async (id,expenses) => {
    return await request("POST", "ticket/set_service_expenses", {id,expenses});
}

const setServiceWorkers = async (id,workers) => {
    return await request("POST", "ticket/set_service_workers", {id,workers});
}

const uploadTicketFiles = async (formData) => {
    return await request ("POST", "ticket/upload_files", formData, true);
}

const getTicketFiles = async (id) => {
    return await request ("POST", "ticket/get_files", {id});
}

const getPrintTicket = async (_id) => {
    return await request ("GET",`ticket/print?_id=${_id}`);
}

export {
    getPrintTicket,
    getTicketFiles,
    uploadTicketFiles,
    getTickets,
    getTicket,
    createTicket, 
    removeTicket,
    addResponse, 
    editResponse,
    deleteResponse,
    editTicket,
    editTicketDetails,
    setStatus, 
    getCustomers,
    getContacts,
    getProblemTypes,
    getCallTypes,
    getEmployees,
    getPriorities,
    setComplete,
    addServiceCall,
    updateCallID,
    getContactByNo,
    setJobBy,
    setServiceExpenses,
    setServiceTimes,
    setServiceWorkers
}