import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useEffect, useState } from "react";

export default function AlertDialog(props){
    const [alerts,setAlerts] = useState([]);
    // useEffect(()=>{
    //     window.alert = (text) => {
    //         setAlerts([...alerts, text]);
    //     };
    // }, []);
    const handleClose = () => {
        if (alerts.length < 1) return;
        setAlerts(alerts.slice(1,));
    }
    return (
    <>
        <Dialog
            fullWidth
            open={alerts.length > 0}
            keepMounted

        >
            <DialogTitle>Alert</DialogTitle>
            <DialogContent>{alerts.length > 0 && alerts[0]}</DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>OK</Button>
            </DialogActions>
        </Dialog>
    </>)
}