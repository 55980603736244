import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from "@mui/material";
import { useEffect, useState, useTransition } from "react";
import { addBan, listBans, removeBan } from "../api/bans";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useInterval } from "../use_interval";

const blueColor = "#1976d2";
const deleteColor = "#dd0000";

export default function BansTable(props) {
    const [isPending, startTransition] = useTransition();
    const [page, setPage] = useState(0);
    const [rowsPerPage,setRowsPerPage] = useState(50);

    const [bans, setBans] = useState([]);
    const [count, setCount] = useState(0);

    const [ip, setIP] = useState("");
    const [subnet, setSubnet] = useState("");

    const [menu, setMenu] = useState(false);
    
    const closeMenu = () => {
        setMenu(false);
        setIP("");
        setSubnet("");
    }

    const loadBans = () => {
        startTransition(async () => {
            let res = await listBans({page,rowsPerPage});
            setBans(res.bans);
            setCount(res.count);
        });
    }

    useInterval(loadBans, 2500);
    
    const remove = async (_id) => {
        await removeBan(_id);
        loadBans();
    }

    const add = async () => {
        await addBan({ip, subnet});
        loadBans();
        closeMenu()
    }

    useEffect(() => {
        loadBans();
    }, [page,rowsPerPage]);

    return (
    <div style={{height:"100%", width:"100%", position: "relative"}}>
        <TableContainer component={Paper} sx={{height:'calc(100% - 52px)', borderBottomLeftRadius:0, borderBottomRightRadius:0}}>
            <Table stickyHeader>
                <TableHead>
                    <TableCell width={200}>Date</TableCell>
                    <TableCell>IP</TableCell>
                    <TableCell>Subnet</TableCell>
                    <TableCell width={30}>
                        <IconButton onClick={() => setMenu(true)}>
                            <AddIcon fontSize="small" sx={{color:blueColor}} />
                        </IconButton>
                    </TableCell>
                </TableHead>
                <TableBody>
                    {bans.map((ban) => {
                        return (
                            <TableRow hover key={ban._id}>
                                <TableCell>{new Date(ban.date).toLocaleString('en-GB')}</TableCell>
                                <TableCell>{ban.ip}</TableCell>
                                <TableCell>{ban.subnet}</TableCell>
                                <TableCell>
                                    <IconButton onClick={() => remove(ban._id)}>
                                        <DeleteIcon fontSize="small" sx={{color: deleteColor}} />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        )    
                    })}
                </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            page={page}
            rowsPerPageOptions={[50, 200, 1000]}
            rowsPerPage={rowsPerPage}
            count={count}
            onPageChange={(a,b) => setPage(b)}
            onRowsPerPageChange={(e) => setRowsPerPage(e.target.value)}
            sx={{position:"absolute",right:0,bottom:0}}
        />
        <Dialog
            open={menu}
            onClose={closeMenu}
        >
            <DialogTitle>Add a new rule</DialogTitle>
            <DialogContent>
                <TextField
                    label="IP"
                    value={ip}
                    fullWidth
                    sx={{mt: 2}}
                    placeholder={"ex. 98.55.31.233"}
                    onChange={(e) => setIP(e.target.value)}
                />
                <TextField
                    label="Subnet"
                    value={subnet}
                    fullWidth
                    placeholder={"ex. 255.255.255.0"}
                    sx={{mt: 2}}
                    onChange={(e) => setSubnet(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={add}>
                    Add
                </Button>
                <Button onClick={closeMenu}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    </div>);
}