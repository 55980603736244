import { TableContainer, Paper, Table, TableRow, TableHead, TableCell, TableBody, Button, TextField, TablePagination, IconButton, Tooltip, Alert, Collapse } from "@mui/material";
import { useEffect, useRef, useState, useTransition } from "react";
import { useGlobalContext } from "../global_context";
import SyncIcon  from '@mui/icons-material/Sync';
import { getEvents } from "../api/calendar";
import CreateEventDialog from './create_event_dialog';
import DatePickerDialog, {getAfterThreeMonthsStart, getNextMonthStart, getThisMonthStart} from "./date_filter";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EventDialog from './view_event_dialog';

export default function CalendarTable(props){
    const didMount = useRef(false);

    const [isPending, startTransition] = useTransition();
    const [context,setContext] = useGlobalContext();
    const [events,setEvents] = useState([]);

    const defaultFromDate = new Date(getThisMonthStart()).getTime();
    const defaultToDate = new Date(getAfterThreeMonthsStart()).getTime();

    const [fromDate,setFromDate] = useState(defaultFromDate);
    const [toDate,setToDate] = useState(defaultToDate);
    const [datePicker, setDatePicker] = useState(false);

    const [search,setSearch] = useState("");
    const [maxResults, setMaxResults] = useState(50);

    const loadEvents = () => {
        startTransition( async () => {
            let res = await getEvents({fromDate,toDate,search,maxResults});
            if (res.message === "Not authorized") return;
            if (!didMount.current) didMount.current = true;
            setEvents(res);
        });
    }

    
    useEffect(() => {
        loadEvents();
    }, [maxResults,fromDate,toDate])

    const handleChangeRowsPerPage = (e) => {
        setMaxResults(parseInt(e.target.value));
    }
    const applyDateFilter = (fromDate,toDate) => {
        setFromDate(fromDate);
        setToDate(toDate);
        setDatePicker(false)
    }
    const clearDateFilter = () => {
        setFromDate(defaultFromDate);
        setToDate(defaultToDate);
    }
    const headProps = {search,setSearch,fromDate,setFromDate,toDate,setToDate,setDatePicker,loadEvents};
    
    return (
    <div style={{height:"100%", width:"100%", position: "relative"}}>
        <DatePickerDialog 
            open={datePicker}
            isThreeMonths
            applyFilter={applyDateFilter}
            clearFilter={clearDateFilter}
            setDatePicker={setDatePicker}
        />
        <TableContainer component={Paper} sx={{height:"calc(100% - 52px)", borderBottomLeftRadius:0, borderBottomRightRadius:0}}>
            <Table stickyHeader dense size="small">
                {context.isMobile ? <MobileHead props={headProps} /> : <DesktopHead props={headProps} />}
                <TableBody>
                    {events.length > 0 && events.map((event) => {
                        const rowProps = {event};
                        return context.isMobile ? <MobileRow props={rowProps} /> : <DesktopRow props={rowProps} />
                    })
                    ||
                    <td colSpan={6} style={{width: "100%"}}>
                        <Alert sx={{width:"80%",maxWidth: 500, m:"auto", mt: 10}} severity={didMount.current ? "warning":"info"}>
                            {didMount.current && "There are no events with the specified criterea. Please change your search parameters" ||
                            "Loading results..."
                            }
                            
                        </Alert>
                    </td>
                    }
                </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            rowsPerPageOptions={[50, 200, 1000]}
            rowsPerPage={maxResults}
            backIconButtonProps={{style:{display:"none"}}}
            nextIconButtonProps={{style:{display:"none"}}}
            labelDisplayedRows={() => ""}
            labelRowsPerPage="Max results"
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{position:"absolute",right:0,bottom:0}}
        />
    </div>);
}

function DesktopHead(props){
    const [context,setContext] = useGlobalContext();
    const {search,setSearch,fromDate,setFromDate,toDate,setToDate,setDatePicker,loadEvents} = props.props;

    const detectEnter = (e) => {
        if(e.keyCode === 13) loadEvents();
    }

    return (
        <TableHead>
            <TableRow>
                <TableCell sx={{maxWidth:150}}>Creation date</TableCell>
                <TableCell sx={{maxWidth:100}}>
                    <TextField onKeyDown={detectEnter} type="search" label="Query" size="small" value={search} onChange={(e)=>{
                        setSearch(e.target.value);
                    }} />
                </TableCell>
                <TableCell sx={{maxWidth:200}}>Location</TableCell>
                <TableCell sx={{maxWidth:100}}>
                    Start time
                    <Tooltip title="Filter by start times">
                        <IconButton onClick={() => {setDatePicker(true);}}>
                            <CalendarMonthIcon />
                        </IconButton>
                    </Tooltip>
                </TableCell>
                <TableCell sx={{maxWidth:100}}>End time</TableCell>
                <TableCell width={120}>
                  <CreateEventDialog>
                    <Button size="small" variant="outlined">
                        Create event
                    </Button>
                  </CreateEventDialog>
                </TableCell>
                <TableCell width={50}>
                    <IconButton onClick={() => {loadEvents()}}>
                        <SyncIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
        </TableHead>
    )
}
function MobileHead(props){
    const {search,setSearch,fromDate,setFromDate,toDate,setToDate,setDatePicker,loadEvents} = props.props;

    const detectEnter = (e) => {
        if(e.keyCode === 13) loadEvents();
    }

    return (
        <TableHead>
            <TableRow>
                <TableCell colSpan={2} sx={{minWidth:110}}>
                    <TextField onKeyDown={detectEnter} type="search" label="Query" size="small" value={search} onChange={(e)=>{
                        setSearch(e.target.value);
                    }} />
                </TableCell>
                <TableCell sx={{maxWidth: 70}}>
                    <CreateEventDialog>
                        <Button size="small" variant="outlined">
                            Create
                        </Button>
                    </CreateEventDialog>
                </TableCell>
                <TableCell width={50}>
                    <Tooltip title="Filter by start times">
                        <IconButton onClick={() => {setDatePicker(true);}}>
                            <CalendarMonthIcon />
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
        </TableHead>
    )
}

function DesktopRow(props){
    const { event } = props.props
    return (
        <TableRow hover key={event.id}>
            <TableCell>{new Date(event.created).toLocaleString('en-GB')}</TableCell>
            <TableCell>{event.summary}</TableCell>
            <TableCell>{event?.location?.substring(0,32)}...</TableCell>
            <TableCell>{event.start.date || new Date(event.start.dateTime).toLocaleString('en-GB')}</TableCell>
            <TableCell>{event.end.date || new Date(event.end.dateTime).toLocaleString('en-GB')}</TableCell>
            <TableCell colSpan={2}>
                <EventDialog eventId={event.id}>
                    <Button size="small" variant="outlined">
                        View
                    </Button>
                </EventDialog>
            </TableCell>
        </TableRow>
    )
}

function MobileRow(props){
    const [open, setOpen] = useState();
    const { event } = props.props
    return (
        <>
            <TableRow hover sx={{ '& > *': { borderBottom: 'unset' }, height:50 }} key={event.id}>
                <TableCell colSpan={3} onClick={()=>{setOpen(!open)}}>{event.summary}</TableCell>
                <TableCell>
                    <EventDialog eventId={event.id}>
                        <Button size="small" variant="outlined">
                            View
                        </Button>
                    </EventDialog>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ padding: 0,borderBottom: 0 }} colSpan={5}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Table size="small">
                        <TableBody>
                            <TableRow sx={{fontSize: 12, lineHeight: 1}}>
                                <TableCell sx={{borderBottom:0}} colSpan={2}>Start date: {event.start.date || new Date(event.start.dateTime).toLocaleString('en-GB')}</TableCell>
                            </TableRow>
                            <TableRow sx={{fontSize: 12, lineHeight: 1}}>
                                <TableCell sx={{borderBottom:0}} colSpan={2}>End date: {event.end.date || new Date(event.end.dateTime).toLocaleString('en-GB')}</TableCell>
                            </TableRow>
                            <TableRow sx={{fontSize: 12, lineHeight: 1}}>
                                <TableCell colSpan={2}>Created: {new Date(event.created).toLocaleDateString('en-GB')}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}

