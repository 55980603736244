import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getUsers, removeUser, setAdmin, setFullName, setPassword } from '../api/users';
import { Box, Button, Checkbox, Collapse, TextField, Typography } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddUserDialog from './add_user_dialog';
import { useGlobalContext } from '../global_context';

export default function UsersTable() {
    const [context,setContext] = useGlobalContext();
    const [users, setUsers] = React.useState([]);
    const [fullNames, setFullNames] = React.useState({});
    const [passwords, setPasswords] = React.useState({});
    const [addDialog, setAddDialog] = React.useState(false);
    const loadUsers = async () => {
        let res = await getUsers();
        setUsers(res);
        setFullNames({});
        setPasswords({});
    }

    const updateUser = async (username) => {
      await changePassword(username, passwords[username]);
      await changeFullName(username, fullNames[username]);
      loadUsers();

    }

    const changePassword = async (username, password) => {
      if (!password) return;
      let res = await setPassword(username, password);
      alert(res.message);
    }
    const changeFullName = async (username, fullName) => {
      if (!fullName) return;
      let res = await setFullName(username, fullName);
      alert(res.message);
    }
    const setAdminStatus = async (username, admin) => {
      let res = await setAdmin(username, admin);
      loadUsers();
      alert(res.message);
    }

    const deleteUser = async (username) => {
        if (!window.confirm("Are you sure you want to delete that user?")) return;
        let res = await removeUser(username);
        alert(res.message);
        loadUsers();
    }
    React.useEffect(() => {
        if (!addDialog)
            loadUsers();
    }, [addDialog]);
  return (
    <TableContainer component={Paper} sx={{height: "100%"}}>
      <Table dense stickyHeader align="center">
        <TableHead>
          <TableRow>
            <TableCell align="center">Username</TableCell>
            {!context.isMobile && <TableCell align="center" width={200}>Full name</TableCell>}
            {!context.isMobile && <TableCell align="center">Is admin</TableCell>}
            {!context.isMobile && <TableCell align="center"></TableCell>}
            {!context.isMobile && <TableCell align="center"></TableCell>}
            <TableCell align="center"><Button onClick={()=>{setAddDialog(true)}}>Add User</Button></TableCell>
            {!context.isMobile && <TableCell align="center"></TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((row,i) => {
            const compProps = {row,setAdminStatus,setFullNames,setPasswords,updateUser,deleteUser,passwords,fullNames};
            return context.isMobile ? <MobileRow props={compProps} />:<DesktopRow props={compProps} />;
          })}
        </TableBody>
      </Table>
      {addDialog && <AddUserDialog addDialog={addDialog} setAddDialog={setAddDialog} />}
    </TableContainer>
  );
}

function DesktopRow(props){
  const { row,setAdminStatus,setFullNames,setPasswords,updateUser,deleteUser,passwords,fullNames } = props.props;
  return (
      <TableRow
        key={row.username}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell align="center" component="th" scope="row">{row.username}</TableCell>
        <TableCell align="center" component="th" scope="row">{row.name}</TableCell>
        <TableCell align="center" component="th" scope="row">
          <Checkbox checked={row.is_admin} onChange={(e) => {
            setAdminStatus(row.username,e.target.checked);
          }}></Checkbox>
        </TableCell>
        <TableCell align="center">
          <TextField size="small" label="New full name" onChange={(e) => {
              let obj = {...fullNames};
              obj[row.username] = e.target.value
              setFullNames(obj);
          }} defaultValue={row.name} InputLabelProps={{ shrink: true }} />
        </TableCell>
        <TableCell align="center">
          <TextField size="small" label="New Password" onChange={(e) => {
              let obj = {...passwords};
              obj[row.username] = e.target.value
              setPasswords(obj);
          }} />  
        </TableCell>
        <TableCell align="center" onClick={() => {updateUser(row.username)}}><Button>update</Button></TableCell>
        <TableCell align="center" onClick={()=> {deleteUser(row.username)}}><Button>delete</Button></TableCell>
      </TableRow>
  )
}

function MobileRow(props) {
  const { row,setAdminStatus,setFullNames,setPasswords,updateUser,deleteUser,passwords,fullNames } = props.props;
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow hover sx={{ '& > *': { borderBottom: 'unset' } }} onClick={() => setOpen(!open)}>
        <TableCell scope="row" align="center">{row.username}</TableCell>
        <TableCell></TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={2}>
          <Collapse in={open} timeout="auto" unmountOnExit>
              <Table size="small">
                  <TableBody>
                      <TableRow sx={{fontSize: 12, lineHeight: 1}}>
                          <TableCell sx={{borderBottom:0}} colSpan={2}>
                          Is admin:
                          <Checkbox size="small" checked={row.is_admin} onChange={(e) => {
                            setAdminStatus(row.username,e.target.checked);
                          }}></Checkbox>
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{fontSize: 12, lineHeight: 1}}>
                          <TableCell sx={{borderBottom:0}} colSpan={2}>
                            <TextField fullWidth size="small" label="Full name" onChange={(e) => {
                                let obj = {...fullNames};
                                obj[row.username] = e.target.value
                                setFullNames(obj);
                            }} defaultValue={row.name} InputLabelProps={{ shrink: true }} />
                          </TableCell>
                      </TableRow>
                      <TableRow sx={{fontSize: 12, lineHeight: 1}}>
                          <TableCell colSpan={2}>
                            <TextField fullWidth size="small" label="New password" onChange={(e) => {
                                let obj = {...passwords};
                                obj[row.username] = e.target.value
                                setPasswords(obj);
                            }} />
                          </TableCell>
                      </TableRow>
                      <TableRow sx={{fontSize: 12, lineHeight: 1}}>
                          <TableCell colSpan={2}>
                            <div style={{display:"flex", justifyContent: "space-around"}}>
                              <Button onClick={() => {updateUser(row.username)}}>update</Button>
                              <Button onClick={() => {deleteUser(row.username)}}>delete</Button>
                            </div>
                          </TableCell>
                      </TableRow>
                  </TableBody>
              </Table>
          </Collapse>
          </TableCell>
      </TableRow>
    </>
  );
}