import { TableContainer, Paper, Table, TableRow, TableHead, TableCell, TableBody, Button, TextField, TablePagination, IconButton, Dialog, DialogActions, DialogTitle, DialogContent, FormControl, InputLabel, Select, MenuItem, Tooltip, Collapse, Checkbox, FormControlLabel, AppBar, Toolbar, Slide, Typography } from "@mui/material";
import { forwardRef, useEffect, useRef, useState, useTransition } from "react";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useInterval } from "../use_interval";
import { deleteItem, editItem, getItemDocs, getItems, handItem } from "../api/items";
import { TileLayer, Marker, Popup, MapContainer, useMapEvents } from 'react-leaflet';
import { addItem } from "../api/items";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import MapIcon from '@mui/icons-material/Map';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import EditIcon from '@mui/icons-material/Edit';
import ReactDOMServer from "react-dom/server";
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import DeleteIcon from '@mui/icons-material/Delete';
import L from 'leaflet';
import { useGlobalContext } from "../global_context";
const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const folderColor = "#fdb900";
const deleteColor = "dd0000";
const blueColor = "#1976d2";

const refreshTime = 10 * 1000; // refresh items every 10 seconds

export default function ItemsTable(props){
    const {type} = props;
    const [isPending, startTransition] = useTransition();
    const [context, setContext] = useGlobalContext();

    const [qDescription, setQDescription] = useState("");
    const [qLocationDescription, setQLocationDescription] = useState("");
    const [items, setItems] = useState([]);

    const [count, setCount] = useState(0);
    const [page,setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);

    useEffect(() => {
        loadItems();
    },[qDescription,qLocationDescription,page,rowsPerPage]);

    const loadItems = () => {
        startTransition( async () => {
            let res = await getItems({type, description: qDescription, locationDescription: qLocationDescription, page, rowsPerPage});
            if (res.message === "Not authorized") return;
            setItems(res.items);
            setCount(res.count);
        });
    }
    useInterval(loadItems, refreshTime);

    const handleChangePage = (e,n) => {
        setPage(n);
    }

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }

    return (
    <div style={{height:"100%", width:"100%", position: "relative"}}>
        <TableContainer component={Paper} sx={{height:'calc(100% - 52px)', borderBottomLeftRadius:0, borderBottomRightRadius:0}}>
            <Table stickyHeader size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Description ({type})</TableCell>
                        {(!context.isMobile && context.isAdmin) ? <TableCell sx={{width:20}}></TableCell> : null}
                        <TableCell sx={{width:20}}>
                            <MapView items={items} type={type} />
                        </TableCell>
                        <TableCell sx={{width:20}}>
                            <AddItem type={type} loadItems={loadItems} />
                        </TableCell>
                        <TableCell sx={{width:20}}>
                            <MobileFilter 
                                funcs={{setQDescription,setQLocationDescription}}
                                values={{qDescription,qLocationDescription}}
                            />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((item) => {
                        return <Row key={item._id} item={item} loadItems={loadItems} />
                    })}
                </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            rowsPerPageOptions={[50, 200, 1000]}
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{position:"absolute",right:0,bottom:0}}
        />
    </div>);
}

function Row(props) {
    const {item, loadItems} = props; 
    const [context] = useGlobalContext();
    const [open, setOpen] = useState(false); 

    const handleDeleteItem = async () => {
        if (!window.confirm("Are you sure you want to delete this "+item.type)) return;
        let res = await deleteItem({itemId: item._id});
        if (res.message) return alert(res.message);
        loadItems();
    }

    return (
      <>
        <TableRow className="pointer-cursor" hover sx={{ '& > *': { borderBottom: 'unset' }, height: 50, }}>
            <TableCell onClick={() => setOpen(e => !e)} colSpan={context.isMobile ? 2 : 1}>{item.docRef} - {item.description}</TableCell>
            {(!context.isMobile && context.isAdmin) ? <TableCell>
                <EditItem item={item} loadItems={loadItems} />
            </TableCell> : null}
            <TableCell>
                {!item.handIn ? <MapView items={[item]} type={item.type} defaultCenter={item.location} /> : null}
            </TableCell>
            <TableCell>
                <HandInDialog item={item} loadItems={loadItems} />
            </TableCell>
            {context.isMobile ? null : 
            <TableCell>
                <IconButton onClick={handleDeleteItem}>
                    <DeleteIcon style={{color: deleteColor}} />
                </IconButton>
            </TableCell>
            }
        </TableRow>
        <TableRow sx={{backgroundColor:"#f5f5f5"}} >
          <TableCell style={{ padding: 0,borderBottom: 0 }} colSpan={(!context.isMobile && context.isAdmin) ? 6 : 5}>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Table size="small">
                    <TableBody>
                        {item.handIn &&
                        <>
                            <TableRow sx={{fontSize: 12, lineHeight: 1}}>
                                <TableCell sx={{borderBottom:0}} colSpan={2}>Handed in by: {item.handIn.username}</TableCell>
                            </TableRow>
                            <TableRow sx={{fontSize: 12, lineHeight: 1}}>
                                <TableCell sx={{borderBottom:0}} colSpan={2}>Handed in at: {new Date(item.handIn.date).toLocaleString('en-GB')}</TableCell>
                            </TableRow>
                            <TableRow sx={{fontSize: 12, lineHeight: 1, whiteSpace: "pre-wrap", fontWeight:"bold"}}>
                                <TableCell sx={{borderBottom:0}} colSpan={2}>Hand in comment: {item.handIn.comment}</TableCell>
                            </TableRow>
                        </>
                        }
                        <TableRow sx={{fontSize: 12, lineHeight: 1}}>
                            <TableCell sx={{borderBottom:0}} colSpan={2}>Location: {`${item.location.description ? `${item.location.description} - `: ""}${item.location.city}`}</TableCell>
                        </TableRow>
                        <TableRow sx={{fontSize: 12, lineHeight: 1}}>
                            <TableCell sx={{borderBottom:0}} colSpan={2}>Document reference: {item.docRef}</TableCell>
                        </TableRow>
                        <TableRow sx={{fontSize: 12, lineHeight: 1}}>
                            <TableCell colSpan={2}>Entry creation date: {new Date(item.date).toLocaleString('en-GB')}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );

}

function HandInDialog(props){
    const {item,loadItems} = props;
    const [open,setOpen] = useState(false);

    const [comment, setComment] = useState("");

    const handleHandIn = async () => {
        if (item.handIn) return;
        if (!window.confirm("Are you sure you want to hand in this "+item.type)) return;
        let res = await handItem({itemId: item._id, comment});
        if (res.message) return alert(res.message);
        alert("Item handed in");
        loadItems();
        setOpen(false);
    }

    const handleUndoHandIn = async () =>{
        if (!window.confirm("Are you sure you want to undo the hand in")) return;
        let res = await handItem({itemId: item._id, comment, undo: true});
        if (res.message) return alert(res.message);
        alert("Item hand in undone");
        loadItems();
    }
    
    return (
        <>
            <Tooltip
                title="Hand in"
            >
                <IconButton
                    onClick={() => {
                            if(item.handIn) return handleUndoHandIn();
                            setOpen(true)
                        }
                    }
                >
                    {item.handIn ? 
                    <TurnedInIcon style={{color: blueColor}} />
                    :
                    <TurnedInNotIcon />
                    }
                </IconButton>
            </Tooltip>
            <Dialog
                open={open}
                onClose={() => {setOpen(false);}}
            >
                <DialogTitle>
                    Hand in ({item.docRef})
                </DialogTitle>
                <DialogContent>
                    <TextField
                        variant="standard"
                        sx={{minWidth: 250}}
                        fullWidth 
                        multiline
                        type="text" 
                        label="Hand in comment" 
                        value={comment} 
                        onChange={(e)=>{
                            setComment(e.target.value);
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleHandIn}
                    >
                        Hand in
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

function MobileFilter(props){
    const {
        funcs: {setQDescription,setQLocationDescription},
        values: {qDescription,qLocationDescription}
    } = props;
    const [open,setOpen] = useState(false);

    return (
        <>
            <IconButton onClick={() => {setOpen(!open)}}>
                <FilterAltIcon />
            </IconButton>
            <Dialog
                open={open}
                onClose={() => {setOpen(false);}}
            >
                <DialogContent>
                    <TextField
                        variant="standard"
                        sx={{m: "0.5em 0"}} 
                        fullWidth 
                        type="search" 
                        label="Description" 
                        value={qDescription} 
                        onChange={(e)=>{
                            setQDescription(e.target.value);
                        }}
                    />
                    <TextField
                        variant="standard"
                        sx={{m: "0.5em 0"}} 
                        fullWidth 
                        type="search" 
                        label="Location" 
                        value={qLocationDescription} 
                        onChange={(e)=>{
                            setQLocationDescription(e.target.value);
                        }}
                    />
                </DialogContent>
            </Dialog>
        </>
    )
}

const cityLocations = {
    "Lefkoşa": {longitude: 33.36050071321314,latitude: 35.18924177086569},
    "Girne": {longitude: 33.319580507772194,latitude: 35.33222981000151},
    "Gazimağusa": {longitude: 33.93230715080243,latitude: 35.12355915962479},
    "İskele": {longitude: 33.89233974327713,latitude: 35.28569573070157},
    "Güzelyurt": {longitude: 32.99361715218024,latitude: 35.1982198857009},
    "Lefke": {longitude: 32.84950767790793,latitude: 35.11295795965601},
}

function AddItem(props){
    const {type,loadItems} = props;
    const [open,setOpen] = useState(false);

    const [description, setDescription] = useState("");
    const [locationDescription, setLocationDescription] = useState("");
    const [city, setCity] = useState("Lefkoşa");
    const [docRef, setDocRef] = useState("");
    const [handIn, setHandIn] = useState(false);
    const [comment, setComment] = useState("");
    const [selectCoords, setSelectCoords] = useState(false);

    const [canAdd, setCanAdd] = useState(true);

    const [geoLocation, setGeoLocation] = useState({});
    
    const [docs, setDocs] = useState([]);

    const [myLocation, setMyLocation] = useState({});

    const loadMyCoords = () => {
        if (!open) return;
        navigator.geolocation.getCurrentPosition(({coords}) => {
            const {latitude,longitude} = coords;
            if (!latitude || !longitude) return;
            setMyLocation({latitude,longitude});
        },(err) => {
            navigator.geolocation.getCurrentPosition(({coords}) => {
                const {latitude,longitude} = coords;
                if (!latitude || !longitude) return;
                setMyLocation({latitude,longitude});
            },undefined,{enableHighAccuracy: false});
        },{enableHighAccuracy: true});
    }

    function MapHandler(props){
        useMapEvents({
            dblclick: (e) => {
                const {lat:latitude,lng:longitude} = e.latlng;
                setGeoLocation({latitude,longitude});
            }
        })
        return null;
    }

    useEffect(() => {
        if (!open) return;
        const loadDocs = async () => {
            let res = await getItemDocs({type});
            if (!res[0]) return;
            setDocs(res);
            setDocRef(res[0].DocNum);
            if (res[0].Address) setLocationDescription(res[0].Address);
        }
        loadDocs();
        loadMyCoords();
    }, [open]);

    const handleAdd = async () => {
        if (!canAdd) return;
        if (selectCoords && (!geoLocation.latitude || !geoLocation.longitude)) return alert("Please select a coordinate by double clicking on the map");
        if (!locationDescription) return alert('Enter a valid location description');
        setCanAdd(false);
        let res = await addItem({
            description,
            type,
            location: {
                description: locationDescription,
                longitude: selectCoords ? geoLocation.longitude : undefined,
                latitude: selectCoords ? geoLocation.latitude : undefined,
                city
            },
            docRef,
            handIn: {
                comment: comment ? comment : undefined
            }
        })
        if (res.message) return alert(res.message);
        alert("Item added");
        loadItems();
        setOpen(false);
        setCanAdd(true);
    }
    
    return (
        <>
            <IconButton onClick={() => {setOpen(!open)}}>
                <AddIcon style={{color: blueColor}} />
            </IconButton>
            <Dialog
                open={open}
                onClose={() => {setOpen(false);}}
            >
                <DialogTitle>
                    New {type}
                </DialogTitle>
                <DialogContent>
                    <TextField
                        variant="standard"
                        sx={{m: "0.5em 0"}}
                        required
                        fullWidth 
                        type="text" 
                        label="Description" 
                        value={description} 
                        onChange={(e)=>{
                            setDescription(e.target.value);
                        }}
                    />
                    <FormControl variant="standard" fullWidth sx={{pb:1.5}}>
                        <InputLabel>Select {type} document</InputLabel>
                        <Select
                            value={docRef}
                            onChange={(e) => {
                                setDocRef(e.target.value);
                                setLocationDescription(docs.find(doc => doc.DocNum === e.target.value).Address || "");
                            }}
                            label={`Select ${type} document`}
                        >
                            {docs.map((doc) => {

                                return <MenuItem key={doc.DocNum} value={doc.DocNum}>{doc.DocNum} - {doc.CardName} {doc.NumAtCard ? `(${doc.NumAtCard})` : ""}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <FormControl variant="standard" fullWidth sx={{}}>
                        <InputLabel>City</InputLabel>
                        <Select
                            value={city}
                            onChange={(e) => {setCity(e.target.value)}}
                            label="City"
                        >
                            <MenuItem value="Lefkoşa">Lefkoşa</MenuItem>
                            <MenuItem value="Girne">Girne</MenuItem>
                            <MenuItem value="Gazimağusa">Gazimağusa</MenuItem>
                            <MenuItem value="İskele">İskele</MenuItem>
                            <MenuItem value="Güzelyurt">Güzelyurt</MenuItem>
                            <MenuItem value="Lefke">Lefke</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        variant="standard"
                        sx={{m: "0.5em 0"}}
                        required
                        fullWidth 
                        type="text" 
                        label="Location's description" 
                        value={locationDescription} 
                        onChange={(e)=>{
                            setLocationDescription(e.target.value);
                        }}
                    />
                    <FormControlLabel 
                        control={<Checkbox onChange={(e) => setSelectCoords(e.target.checked)} checked={selectCoords} />} 
                        label="Select coordinates?"
                    />
                    {selectCoords && (myLocation.latitude && myLocation.longitude) ? 
                    <Paper
                        sx={{width: "100%", height: 500}}
                    >
                        <MapContainer 
                            center={[myLocation.latitude, myLocation.longitude]} 
                            zoom={13}
                            doubleClickZoom={false}
                        >
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            {(geoLocation.longitude && geoLocation.latitude) ? <Marker 
                                position={[geoLocation.latitude, geoLocation.longitude]}
                                draggable
                                eventHandlers={{
                                    drag: (e) => {
                                        const {latlng} = e;
                                        const {lat: latitude, lng: longitude} = latlng;
                                        setGeoLocation({latitude,longitude})
                                    }
                                }}
                            >
                                <Popup>
                                    Location: {geoLocation.latitude}, {geoLocation.longitude}
                                </Popup>
                            </Marker> : null}
                            <MapHandler />
                        </MapContainer>
                    </Paper> : null}
                    <FormControlLabel 
                        control={<Checkbox onChange={(e) => setHandIn(e.target.checked)} checked={handIn} />} 
                        label="Hand in?"
                    />
                    <TextField
                        variant="standard"
                        sx={{m: "0.5em 0"}}
                        disabled={!handIn}
                        fullWidth 
                        multiline
                        rows={4}
                        type="text" 
                        label="Hand in comment" 
                        value={comment} 
                        onChange={(e)=>{
                            setComment(e.target.value);
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleAdd}
                        // disabled={!canAdd}
                    >
                        Add {type}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

function EditItem(props){
    const {item,loadItems} = props;
    const [open,setOpen] = useState(false);

    const [description, setDescription] = useState(item.description);
    const [locationDescription, setLocationDescription] = useState(item.location.description);
    const [city, setCity] = useState(item.location.city);
    const [docRef, setDocRef] = useState(item.docRef);
    const [selectCoords, setSelectCoords] = useState(item.location.longitude && item.location.latitude);

    const [canEdit, setCanEdit] = useState(true);

    const [geoLocation, setGeoLocation] = useState({longitude: item.location.longitude, latitude: item.location.latitude});
    
    const [docs, setDocs] = useState([]);

    const [myLocation, setMyLocation] = useState({});

    const loadMyCoords = () => {
        if (!open) return;
        navigator.geolocation.getCurrentPosition(({coords}) => {
            const {latitude,longitude} = coords;
            if (!latitude || !longitude) return;
            setMyLocation({latitude,longitude});
        },(err) => {
            navigator.geolocation.getCurrentPosition(({coords}) => {
                const {latitude,longitude} = coords;
                if (!latitude || !longitude) return;
                setMyLocation({latitude,longitude});
            },undefined,{enableHighAccuracy: false});
        },{enableHighAccuracy: true});
    }

    function MapHandler(){
        useMapEvents({
            dblclick: (e) => {
                const {lat:latitude,lng:longitude} = e.latlng;
                setGeoLocation({latitude,longitude});
            }
        })
        return null;
    }

    useEffect(() => {
        if (!open) return;
        const loadDocs = async () => {
            let res = await getItemDocs({type: item.type});
            if (!res[0]) return;
            setDocs(res);
        }
        loadDocs();
        loadMyCoords();
    }, [open]);

    const handleEdit = async () => {
        if (!canEdit) return;
        if (selectCoords && (!geoLocation.latitude || !geoLocation.longitude)) return alert("Please select a coordinate by double clicking on the map");
        setCanEdit(false);
        let res = await editItem({
            _id: item._id,
            description,
            location: {
                description: locationDescription,
                longitude: selectCoords ? geoLocation.longitude : undefined,
                latitude: selectCoords ? geoLocation.latitude : undefined,
                city
            },
            docRef
        })
        if (res.message) return alert(res.message);
        alert("Item edited");
        loadItems();
        setOpen(false);
        setCanEdit(true);
    }
    
    return (
        <>
        
            <IconButton onClick={() => {setOpen(!open)}}>
                <EditIcon />
            </IconButton>
            <Dialog
                open={open}
                onClose={() => {setOpen(false);}}
            >
                <DialogTitle>
                    Edit {item.type}
                </DialogTitle>
                <DialogContent>
                    <TextField
                        variant="standard"
                        sx={{m: "0.5em 0"}}
                        required
                        fullWidth 
                        type="text" 
                        label="Description" 
                        value={description} 
                        onChange={(e)=>{
                            setDescription(e.target.value);
                        }}
                    />
                    <FormControl variant="standard" fullWidth sx={{pb:1.5}}>
                        <InputLabel>Select {item.type} document</InputLabel>
                        <Select
                            value={docRef}
                            onChange={(e) => {
                                setDocRef(e.target.value);
                                setLocationDescription(docs.find(doc => doc.DocNum === e.target.value).Address || "");
                            }}
                            label={`Select ${item.type} document`}
                        >
                            {docs.map((doc) => {

                                return <MenuItem key={doc.DocNum} value={doc.DocNum}>{doc.DocNum} - {doc.CardName} {doc.NumAtCard ? `(${doc.NumAtCard})` : ""}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <FormControl variant="standard" fullWidth sx={{}}>
                        <InputLabel>City</InputLabel>
                        <Select
                            value={city}
                            onChange={(e) => {setCity(e.target.value)}}
                            label="City"
                        >
                            <MenuItem value="Lefkoşa">Lefkoşa</MenuItem>
                            <MenuItem value="Girne">Girne</MenuItem>
                            <MenuItem value="Gazimağusa">Gazimağusa</MenuItem>
                            <MenuItem value="İskele">İskele</MenuItem>
                            <MenuItem value="Güzelyurt">Güzelyurt</MenuItem>
                            <MenuItem value="Lefke">Lefke</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        variant="standard"
                        sx={{m: "0.5em 0"}}
                        required
                        fullWidth 
                        type="text" 
                        label="Location's description" 
                        value={locationDescription} 
                        onChange={(e)=>{
                            setLocationDescription(e.target.value);
                        }}
                    />
                    <FormControlLabel 
                        control={<Checkbox onChange={(e) => setSelectCoords(e.target.checked)} checked={selectCoords} />} 
                        label="Select coordinates?"
                    />
                    {selectCoords && (myLocation.latitude && myLocation.longitude) ? 
                    <Paper
                        sx={{width: "100%", height: 500}}
                    >
                        <MapContainer 
                            center={(geoLocation.latitude && geoLocation.longitude) ? [geoLocation.latitude,geoLocation.longitude]:[myLocation.latitude, myLocation.longitude]} 
                            zoom={(geoLocation.latitude && geoLocation.longitude) ? 17 : 13}
                            doubleClickZoom={false}
                        >
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            {(geoLocation.longitude && geoLocation.latitude) ? <Marker 
                                position={[geoLocation.latitude, geoLocation.longitude]}
                                draggable
                                eventHandlers={{
                                    drag: (e) => {
                                        const {latlng} = e;
                                        const {lat: latitude, lng: longitude} = latlng;
                                        setGeoLocation({latitude,longitude})
                                    }
                                }}
                            >
                                <Popup>
                                    Location: {geoLocation.latitude}, {geoLocation.longitude}
                                </Popup>
                            </Marker> : null}
                            <MapHandler />
                        </MapContainer>
                    </Paper> : null}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleEdit}
                        disabled={!canEdit}
                    >
                        Apply edits
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

function MapView(props){
    const {items,type,defaultCenter} = props;
    const [open,setOpen] = useState(false);
    const coordsSet = useRef(Boolean(defaultCenter));
    const [geoLocation, setGeoLocation] = useState(defaultCenter ? ((defaultCenter.longitude && defaultCenter.latitude) ? defaultCenter : cityLocations[items[0]?.location.city]) : {});
    const [updatedGeoLocation, setUpdatedGeoLocation] = useState({});
    const [cityItems, setCityItems] = useState({});
    const loadMyCoords = () => {
        if (!open) return;
        navigator.geolocation.getCurrentPosition(({coords}) => {
            const {latitude,longitude} = coords;
            if (!latitude || !longitude) return;
            if (!coordsSet.current){
                setGeoLocation({latitude,longitude});
                coordsSet.current = true;
            }
            setUpdatedGeoLocation({latitude,longitude});
        },(err) => {
            navigator.geolocation.getCurrentPosition(({coords}) => {
                const {latitude,longitude} = coords;
                if (!latitude || !longitude) return;
                if (!coordsSet.current){
                    setGeoLocation({latitude,longitude});
                    coordsSet.current = true;
                }
                setUpdatedGeoLocation({latitude,longitude});
            },undefined,{enableHighAccuracy: false});
        },{enableHighAccuracy: true});
    }
    useEffect(() => {
        if (open) loadMyCoords();
    }, [open]);

    useEffect(() => {
        const cities = {
            "Lefkoşa": [],
            "Girne": [],
            "Gazimağusa": [],
            "İskele": [],
            "Güzelyurt": [],
            "Lefke": [],
        }
        items.forEach(item => {
            cities[item.location.city].push(item);
        })
        setCityItems(cities);
    },[items]);

    useInterval(() => {
        loadMyCoords();
    }, 3000);

    const handleClose = () => {
        setOpen(false);
    }


    return (
        <>
            <IconButton
                onClick={() => setOpen(true)}
            >
                <MapIcon style={{color: blueColor}} />
            </IconButton>
            {open ? <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
                keepMounted={false}
            >
                <AppBar sx={{ position: 'sticky' }}>
                    <Toolbar variant="dense">
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon fontSize="small"/>
                        </IconButton>
                        <Typography>
                            {type}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent sx={{p:0}}>
                    {(geoLocation.longitude && geoLocation.latitude) ? <MapContainer 
                        center={[geoLocation.latitude, geoLocation.longitude]} 
                        zoom={!defaultCenter ? 13:17}
                    >
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {Object.keys(cityItems).map(key => {
                            const itemList = cityItems[key];
                            return (
                                <>
                                    {itemList.filter(e => !Boolean(e.handIn)).length > 0 ? 
                                    <EnhancedMarker
                                        key={key}
                                        position={[cityLocations[key].latitude, cityLocations[key].longitude]}
                                        iconSize={[50, 50]}
                                        iconAnchor={[14, 18]}
                                        popupAnchor={[-2, -20]}
                                        icon={<LocationCityIcon style={{color: "black"}} />}
                                    >
                                        <Popup style={{display: "flex", flexDirection: "column"}}>
                                            {itemList.filter(e => !Boolean(e.handIn)).map(item => {
                                                return (
                                                    <Typography>
                                                        {item.docRef} - {item.description}
                                                    </Typography>
                                                )
                                            })}
                                        </Popup>
                                    </EnhancedMarker>  
                                    : null}
                                </>
                            )
                        })}
                        {(updatedGeoLocation.latitude && updatedGeoLocation.longitude) ?
                        <EnhancedMarker
                            position={[updatedGeoLocation.latitude, updatedGeoLocation.longitude]}
                            iconSize={[50, 50]}
                            iconAnchor={[14, 18]}
                            popupAnchor={[-2, -20]}
                            icon={<MyLocationIcon style={{color: blueColor}} />}
                        >
                            <Popup>
                                My location
                            </Popup>
                        </EnhancedMarker> : null}
                        {items.filter(e => !Boolean(e.handIn) && (e.location.latitude && e.location.longitude)).map(item => {
                            return (
                                <Marker
                                    key={item._id}
                                    position={[item.location.latitude, item.location.longitude]}
                                >
                                    <Popup>
                                        {item.docRef} - {item.description}
                                    </Popup>
                                </Marker>
                            )
                        })}
                    </MapContainer> : null }
                </DialogContent>
            </Dialog>: null}
        </>
    )
}

function EnhancedMarker({
    iconSize,
    iconAnchor,
    popupAnchor,
    icon,
    children,
    ...props
  }) {
    return (
      <Marker
        icon={L.divIcon({
          className: "custom icon",
          iconSize,
          iconAnchor,
          popupAnchor,
          html: ReactDOMServer.renderToString(icon),
        })}
        {...props}
      >
        {children}
      </Marker>
    );
};