import * as React from 'react';
import {Button,CssBaseline,TextField,FormControlLabel,Checkbox,Link,Box,Typography,Container, InputAdornment, IconButton} from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Logo from '../images/logo.png';
import {login} from "../api/auth";
import { useGlobalContext } from '../global_context';
import { useNavigate } from 'react-router';
import { getCustomers } from '../api/ticket';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.akcaba.com/">
        Akcaba Ltd.
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function LoginPage() {
  let navigate = useNavigate();
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [context, setContext] = useGlobalContext();
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let res = await login({username, password});
    if (res.message === "Success") {
      let customers = await getCustomers();
      setContext({...context, authorized: true, isAdmin: res.is_admin, fullName: res.name, username: res.username, customers});
      navigate("/");
    }
    else alert(res.message);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs" sx={{p:0}}>
        <CssBaseline />
        <Box sx={{p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent:"center", height:"100vh", overflow:"auto" }}>
          <img src={Logo} width={170} />
          <Box component="form" onSubmit={handleSubmit} action="/" sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              label="Username"
              autoComplete="username"
              autoFocus
              onChange={(e)=>{setUsername(e.target.value)}}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              label="Password"
              type={showPassword ? "text":"password"}
              autoComplete="current-password"
              onChange={(e)=>{setPassword(e.target.value)}}
              InputProps={{ // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography
                      color="#4169E1"
                      className="show-hover"
                      onClick={()=>{setShowPassword(!showPassword)}}
                    >
                      {showPassword ? "Hide":"Show"}
                    </Typography>
                  </InputAdornment>
                )
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Login
            </Button>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Box>
      </Container>
    </ThemeProvider>
  );
}