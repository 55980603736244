import axios from "axios";
import config from './config';
const api_location = `${config.ipAddress}`;

const request = async (method,path,body,is_upload) => {
  const response = await axios({
    method,
    url: `${api_location}/${path}`,
    withCredentials: true,
    params: method.toLowerCase() === 'get' ? body : undefined,
    headers: {
      Accept: "application/json",
      "Content-Type": is_upload ? "multipart/form-data":"application/json",
    },
    data: method.toLowerCase() === 'get' ? undefined : (is_upload ? body : JSON.stringify(body))
  });
  return response.data;
};

export default request;