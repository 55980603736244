import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from "@mui/material";
import { useEffect, useState, useTransition } from "react";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { addVoucher, addVoucherItem, listVoucherItems, listVouchers, removeVoucher, removeVoucherItem } from "../api/vouchers";

const blueColor = "#1976d2";
const deleteColor = "#dd0000";

export default function VouchersTable() {
    const [isPending, startTransition] = useTransition();
    const [page, setPage] = useState(0);
    const [rowsPerPage,setRowsPerPage] = useState(50);

    const [vouchers, setVouchers] = useState([]);
    const [count, setCount] = useState(0);

    const [timeLimit, setTimeLimit] = useState(0);

    const [menu, setMenu] = useState(false);
    
    const closeMenu = () => {
        setMenu(false);
        setTimeLimit(0);
    }

    const loadVouchers = () => {
        startTransition(async () => {
            let res = await listVouchers({page,rowsPerPage});
            setVouchers(res.vouchers);
            setCount(res.count);
        });
    }

    const handleAddVoucher = async () => {
        let res = await addVoucher(timeLimit);
        if (res.message) return alert(res.message);
        loadVouchers();
        closeMenu();
    }

    const handleRemoveVoucher = async (id) => {
        if (!window.confirm("Are you sure you want to delete this voucher?")) return;
        let res = await removeVoucher(id);
        if (res.message) return alert(res.message);
        loadVouchers();
    }

    useEffect(() => {
        loadVouchers();
    }, [page,rowsPerPage]);

    return (
    <div style={{height:"100%", width:"100%", position: "relative"}}>
        <TableContainer component={Paper} sx={{height:'calc(100% - 52px)', borderBottomLeftRadius:0, borderBottomRightRadius:0}}>
            <Table stickyHeader>
                <TableHead>
                    <TableCell width={200}>End date</TableCell>
                    <TableCell width={300}>ID</TableCell>
                    <TableCell>Time used</TableCell>
                    <TableCell>Ticket id</TableCell>
                    <TableCell>Invoice</TableCell>
                    <TableCell width={30}>
                        <VoucherItemsTable>
                            <Button>
                                ITEMS
                            </Button>
                        </VoucherItemsTable>
                    </TableCell>
                    <TableCell width={30}>
                        <IconButton onClick={() => setMenu(true)}>
                            <AddIcon fontSize="small" sx={{color:blueColor}} />
                        </IconButton>
                    </TableCell>
                </TableHead>
                <TableBody>
                    {vouchers.map((voucher) => {
                        return (
                            <Row 
                                voucher={voucher}
                                key={voucher._id}
                                remove={handleRemoveVoucher}
                            />
                        )    
                    })}
                </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            page={page}
            rowsPerPageOptions={[50, 200, 1000]}
            rowsPerPage={rowsPerPage}
            count={count}
            onPageChange={(a,b) => {setPage(b)}}
            onRowsPerPageChange={(e) => setRowsPerPage(e.target.value)}
            sx={{position:"absolute",right:0,bottom:0}}
        />
        <Dialog
            open={menu}
            onClose={closeMenu}
        >
            <DialogTitle>Add a voucher item</DialogTitle>
            <DialogContent>
                <TextField
                    label="Time limit (mins)"
                    type="number"
                    value={timeLimit}
                    fullWidth
                    sx={{mt: 2}}
                    onChange={(e) => setTimeLimit(parseInt(e.target.value))}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleAddVoucher}>
                    Add
                </Button>
                <Button onClick={closeMenu}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    </div>);
}

function VoucherItemsTable(props){
    const {children} = props;
    const [open, setOpen] = useState(false);
    const [voucherItems, setVoucherItems] = useState([]);

    const [itemCode, setItemCode] = useState("");
    const [timePerUnit, setTimePerUnit] = useState(0);

    const [menu, setMenu] = useState(false);
    
    const closeMenu = () => {
        setMenu(false);
        setItemCode("");
        setTimePerUnit(0);
    }

    const loadVoucherItems = async () => {
        let res = await listVoucherItems();
        setVoucherItems(res);
    }

    const handleRemoveVoucherItem = async (itemCode) => {
        if (!window.confirm("Are you sure you want to delete this voucher item?")) return;
        let res = await removeVoucherItem(itemCode);
        if (res.message) return alert(res.message);
        loadVoucherItems();
    }

    const handleAddVoucherItem = async () => {
        await addVoucherItem({itemCode,timePerUnit});
        loadVoucherItems();
        closeMenu()
    }

    useEffect(() => {
        if (open) loadVoucherItems();
    }, [open]);

    return (
        <>
            <div 
                style={{height:"100%", width:"100%"}}
                onClick={() => setOpen(true)}
            >
                {children}
            </div>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
            >
                <TableContainer component={Paper}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableCell>Item code</TableCell>
                            <TableCell>Time per unit (mins)</TableCell>
                            <TableCell width={30}>
                                <IconButton onClick={() => setMenu(true)}>
                                    <AddIcon fontSize="small" sx={{color:blueColor}} />
                                </IconButton>
                            </TableCell>
                        </TableHead>
                        <TableBody>
                            {voucherItems.map((voucherItem) => {
                                return (
                                    <TableRow hover key={voucherItem._id}>
                                        <TableCell>{voucherItem.itemCode}</TableCell>
                                        <TableCell>{voucherItem.timePerUnit}</TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => handleRemoveVoucherItem(voucherItem.itemCode)}>
                                                <DeleteIcon fontSize="small" sx={{color: deleteColor}} />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                )    
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Dialog
                    open={menu}
                    onClose={closeMenu}
                >
                    <DialogTitle>Add a new rule</DialogTitle>
                    <DialogContent>
                        <TextField
                            label="Item code"
                            value={itemCode}
                            fullWidth
                            sx={{mt: 2}}
                            placeholder={"ex. 100543 | S119098"}
                            onChange={(e) => setItemCode(e.target.value)}
                        />
                        <TextField
                            label="Time per unit (mins)"
                            type="number"
                            value={timePerUnit}
                            fullWidth
                            placeholder={"ex. 60 | 180 | 320"}
                            sx={{mt: 2}}
                            onChange={(e) => setTimePerUnit(parseInt(e.target.value))}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleAddVoucherItem}>
                            Add
                        </Button>
                        <Button onClick={closeMenu}>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </Dialog>
        </>
    );
}

function Row(props){
    const {voucher,remove} = props;
    return (
        <TableRow hover key={voucher._id}>
            <TableCell>{new Date(voucher.dateEnd).toLocaleString('en-GB')}</TableCell>
            <TableCell>{voucher.id}</TableCell>
            <TableCell>{voucher.timeUsed} / {voucher.timeLimit}</TableCell>
            <TableCell>{voucher.ticketId || "None"}</TableCell>
            <TableCell>{voucher.invoice || "None"}</TableCell>
            <TableCell></TableCell>
            <TableCell>
                <IconButton onClick={() => remove(voucher.id)}>
                    <DeleteIcon fontSize="small" sx={{color: deleteColor}} />
                </IconButton>
            </TableCell>
        </TableRow>
    )
}