import request from "./request";

const getInvoiceDetails = async (NumAtCard,DocNo) => {
    return await request("GET", "payment/invoice", {NumAtCard,DocNo});
}

const getPaymentForm = async ({Email,CardName,CardNo,CardCvv,CardExpireMonth,CardExpireYear,CustomerNo,DocNo,NumAtCard}) => {
    return await request("POST", "payment/invoice", {Email,CardName,CardNo,CardCvv,CardExpireMonth,CardExpireYear,CustomerNo,DocNo,NumAtCard});
}

const getPayments = async (orderId,transId,customerNo,docNo,page,rowsPerPage) => {
    return await request("POST", "payment/get_payments", {orderId,transId,customerNo,docNo,page,rowsPerPage});
}

const getPayment = async (orderId) => {
    return await request("POST", "payment/get_payment", {orderId});
}


export {
    getInvoiceDetails,getPaymentForm,getPayments,getPayment
}