import request from "./request";

const listBans = async ({page,rowsPerPage}) => {
    return await request("POST", "bans/list", {page,rowsPerPage});
}

const addBan = async ({ip,subnet}) => {
    return await request("POST", "bans/add", {ip,subnet})
}

const removeBan = async (_id) => {
    return await request("POST", "bans/delete", {_id})
}

export {
    listBans,
    addBan,
    removeBan
}