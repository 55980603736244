import request from "./request";

const getVoucher = async ({id,ticketId}) => {
    return await request("POST", "vouchers/get", {id,ticketId});
}

const listVouchers = async ({page,rowsPerPage}) => {
    return await request("POST", "vouchers/list", {page,rowsPerPage});
}

const addVoucher = async (timeLimit) => {
    return await request("POST", "vouchers/add", {timeLimit});
}

const removeVoucher = async (id) => {
    return await request("POST", "vouchers/remove", {id});
}

const consumeVoucher = async ({id,time}) => {
    return await request("POST", "vouchers/use", {id,time});
}

const assignVoucher = async ({voucherId,ticketId}) => {
    return await request("POST", "vouchers/assign", {voucherId,ticketId});
}
const addVoucherItem = async ({itemCode,timePerUnit}) => {
    return await request("POST", "vouchers/item/add", {itemCode,timePerUnit});
}
const removeVoucherItem = async (itemCode) => {
    return await request("POST", "vouchers/item/remove", {itemCode});
}
const listVoucherItems = async () => {
    return await request("POST", "vouchers/item/list", {});
}
export {
    getVoucher,
    listVouchers,
    addVoucher,
    removeVoucher,
    consumeVoucher,
    assignVoucher,
    addVoucherItem,
    removeVoucherItem,
    listVoucherItems
}