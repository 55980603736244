import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudIcon from '@mui/icons-material/Cloud';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import DownloadIcon from '@mui/icons-material/Download';
import SettingsIcon from '@mui/icons-material/Settings';
import Slide from '@mui/material/Slide';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { addResponse, deleteResponse, editResponse, editTicket, editTicketDetails, getContactByNo, getEmployees, getPrintTicket, getTicket, getTicketFiles, setComplete, setJobBy, setServiceExpenses, setServiceTimes, setServiceWorkers, setStatus, updateCallID } from '../api/ticket';
import { Alert, Avatar, Box, Checkbox, CircularProgress, Collapse, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, ImageList, ImageListItem, imageListItemBarClasses, InputLabel, Menu, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from '@mui/material';
import ServiceCall from './service_call';
import {useGlobalContext} from '../global_context';
// import UploadFormDialog from './upload_form';
import localization from '../localization.json';
import { getUsers } from '../api/users';
import CreateEventDialog from './create_event_dialog';
import EventDialog from './view_event_dialog';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import QNAPTable, { Download } from './qnap_table';
import { getVoucher, consumeVoucher, assignVoucher } from '../api/vouchers';
import config from '../api/config';
import { postPrompt } from '../api/openai';
import { grey, red } from '@mui/material/colors';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const deleteColor = "dd0000";

const languages = {
  'en': 'English',
  'tr': 'Turkish'
}

export default function Ticket(props) {
  const anchorEl = useRef();
  const fileRef = useRef();
  const [context, setContext] = useGlobalContext();
  const [ticket, setTicket] = useState({});
  const [newStatus, setNewStatus] = useState("");
  const [response, setResponse] = useState("");
  const [service, setService] = useState(undefined);
  const [settingsMenu, setSettingsMenu] = useState(false);
  const [infoMenu, setInfoMenu] = useState(!context.isMobile);
  const [editTitle, setEditTitle] = useState(false);
  const [images, setImages] = useState([]);

  const [contact, setContact] = useState({});

  const [title, setTitle] = useState("");
  const [person, setPerson] = useState("");
  const [company, setCompany] = useState("");
  const [number, setNumber] = useState("");
  const [city, setCity] = useState("");
  const [priority, setPriority] = useState("");
  const loadTicket = async (ignore) => {
    let res = await getTicket(props.ticketId, props.ticket_id);
    if (res.service_call && context.authorized){
      let [cntct] = await getContactByNo(res.service_call.contact_no);
      setContact(cntct);
    }
    if (res.service_call && !res.service_call.call_id){
      await updateCallID(res.service_call.file_no);
    }
    if (!Number.isInteger(res.id)){
      props.setEdit(false);
      return alert(res.message);
    }
    setTicket({...res});
    if (ignore) return;
    setTitle(res.title);
    setPerson(res.person);
    setCompany(res.company);
    setNumber(res.number);
    setCity(res.city);
    setNewStatus(res.status);
    setPriority(res.priority);
  }

  const closeSettingsMenu = () => {setSettingsMenu(false)}

  useEffect(()=>{
    if(service === false)
      loadTicket();
  }, [service]);

  useEffect(() => {
    if (props.edit)
      loadTicket();
  }, [props.edit]);

  const handleClose = (bypass) => {
    if (bypass === true) return props.setEdit(false);
    if (ticket.title !== title || ticket.person !== person || ticket.number !== number || ticket.company !== company || ticket.city !== city || response || newStatus !== ticket.status || priority !== ticket.priority){
      if(!window.confirm("You have made some changes! Are you sure you want to close this window?")) return; 
    }
    props.setEdit(false);
  };
  const handleChange = (e) => {setNewStatus(e.target.value);}
  const handleChangeImages = (e) => {
    const files = fileRef.current.files;
    setImages([...images,...files]);
    fileRef.current.value = '';
  }
  const handleDeleteImage = (index) => {
    let arr = [...images];
    arr.splice(index,1);
    setImages(arr);
  }
  const addServiceCall = () => {setService(true);}
  const saveChanges = async () => {
    if (response.length > 0 || images.length > 0){
      let res = await addResponse({id: ticket.id, response, files: images});
      alert(res.message);
    }
    if (newStatus !== ticket.status){
      if (newStatus === "Complete"){
        let res = await setComplete(ticket.id);
        alert(res.message);
      }
      else{
        let res = await setStatus(ticket.id, newStatus);
        alert(res.message);
      }
    }
    if (ticket.title !== title || ticket.person !== person || ticket.number !== number || ticket.company !== company || ticket.city !== city || ticket.priority !== priority){
      let res = await editTicket({
        id: ticket.id,
        _id: ticket._id,
        person,
        company,
        number,
        title,
        city,
        priority
      });
      alert (res.message)
    }
    handleClose(true);
  }

  const takeJob = async () => {
    if (!window.confirm("Are you sure you want to take this job? Once you confirm there is no going back.")) return;
    let res = await setJobBy(ticket.id);
    if (res.job_by) alert("You took this job");
    loadTicket();
  }

  const printTicket = async () => {
    window.open(`${config.ipAddress}/ticket/print?_id=${ticket._id}`);
  }

  return (
      <Dialog
        fullScreen
        open={props.edit}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'sticky', bgcolor: grey[200], color: 'black' }}>
          <Toolbar variant="dense">
            <Typography sx={{display: 'flex', flex: 1, alignItems: 'center' }} variant="h6" component="div">
              {context.isMobile ? null : 
              <>
                {editTitle && context.authorized ? 
                  <TextField
                    value={title}
                    sx={{width: '80%'}}
                    onChange={(e) => setTitle(e.target.value)}
                    size="small"
                  />
                :
                <>
                  {title}
                </>
                }
                {context.authorized ? 
                <IconButton sx={{ml: 1}} onClick={() => setEditTitle(e => !e)}>
                  {editTitle ? 
                  <CloseIcon fontSize="small" />
                  :
                  <EditIcon fontSize="small" />
                  }
                </IconButton>
                : null}
              </>
              }
            </Typography>
            {context.authorized ?
            <>
              <Tooltip title="Save">
                <IconButton
                  edge="end"
                  color="inherit"
                  sx={{mr:2}}
                  onClick={saveChanges}
                >
                  <SaveIcon style={{color: 'inherit'}} fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Options">
                <IconButton
                  edge="end"
                  color="inherit"
                  sx={{mr: 2}}
                  ref={anchorEl}
                  onClick={() => {setSettingsMenu(settingsMenu => !settingsMenu)}}
                >
                  <SettingsIcon style={{color: 'inherit'}} fontSize="small" />
                </IconButton>
              </Tooltip>
            </>
            : null}
            <Tooltip title="Close">
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon style={{color: 'inherit'}} fontSize="small" />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorEl.current}
              open={settingsMenu}
              onClose={closeSettingsMenu}
              keepMounted
            >
              {context.authorized && ticket.event && 
              <MenuItem 
                onClick={closeSettingsMenu}
                sx={{display:"block"}} 
                disableRipple
              >
                 <EventDialog eventId={ticket.event}>
                  EVENT
                 </EventDialog>
              </MenuItem>}
              {context.authorized && 
              <MenuItem 
                onClick={closeSettingsMenu}
                sx={{display:"block"}} 
                disableRipple
              >
                <UploadFileSection ticketId={ticket.id}>
                  UPLOAD FILES
                </UploadFileSection>
              </MenuItem>}
              {context.authorized && 
              <MenuItem 
                sx={{display:"block"}} 
                disableRipple
              >
                <ListFileSection ticketId={ticket.id}>
                  VIEW FILES
                </ListFileSection>
              </MenuItem>}
              {context.authorized && 
              <MenuItem 
                onClick={closeSettingsMenu}
                sx={{display:"block"}} 
                disableRipple
              >
                <VoucherSection ticketId={ticket.id}>
                  VOUCHER
                </VoucherSection>
              </MenuItem>}
              {context.authorized && 
              <MenuItem 
                onClick={closeSettingsMenu}
                sx={{display:"block"}} 
                disableRipple
              >
                <AskAIDialog ticket={ticket} loadTicket={loadTicket}>
                  ASK AI
                </AskAIDialog>
              </MenuItem>}
              {context.authorized && !ticket.event && 
              <MenuItem 
                onClick={closeSettingsMenu}
                sx={{display:"block"}} 
                disableRipple
              >
                <CreateEventDialog loadTicket={loadTicket} ticketId={ticket.id}>
                  CREATE EVENT
                </CreateEventDialog>
              </MenuItem>}
              {context.authorized && !context.isAdmin && !ticket.job_by && 
              <MenuItem 
                sx={{display:"block"}} 
                onClick={takeJob} 
                disableRipple
              >
                  TAKE JOB
              </MenuItem>}
              {context.authorized && context.isAdmin && 
              <MenuItem 
                onClick={closeSettingsMenu}
                sx={{display:"block"}} 
                disableRipple
              >
                <GiveJobDialog ticketId={ticket.id} loadTicket={loadTicket}>
                  {ticket.job_by ? "REASSIGN JOB": "GIVE JOB"}
                </GiveJobDialog>
              </MenuItem>}
              {context.authorized && !ticket.service_call && 
              <MenuItem 
                sx={{display:"block"}} 
                onClick={addServiceCall} 
                disableRipple
              >
                ADD SERVICE CALL
              </MenuItem>}
              {context.authorized && 
              <MenuItem 
                sx={{display:"block"}} 
                onClick={printTicket} 
                disableRipple
              >
                PRINT
              </MenuItem>}
              {((context.authorized && ticket.status !== "Complete") || context.isAdmin) && 
              <MenuItem 
                sx={{display:"block"}} 
                onClick={saveChanges} 
                disableRipple
              >
                SAVE
              </MenuItem>}
            </Menu>
          </Toolbar>
        </AppBar>
        <List dense>
          {context.isMobile && <ListItem>
            <Typography color="rgb(0,0,0,0.6)" fontSize="large" fontWeight="bold" sx={{width: 1}}>
            <>
                {editTitle && context.authorized ? 
                  <TextField
                    value={title}
                    sx={{width: '80%'}}
                    onChange={(e) => setTitle(e.target.value)}
                    size="small"
                  />
                :
                <>
                  {title}
                </>
                }
                {context.authorized ? 
                <IconButton sx={{ml: 1}} onClick={() => setEditTitle(e => !e)}>
                  {editTitle ? 
                  <CloseIcon fontSize="small" />
                  :
                  <EditIcon fontSize="small" />
                  }
                </IconButton>
                : null}
              </>
            </Typography>
            
          </ListItem>}
          {context.isMobile && ticket.job_by && 
            <ListItem>
              <Typography>
                Job taken by: {ticket.job_by}
              </Typography>
            </ListItem>
          }
          {context.isMobile && <ListItem sx={{p:1}}>
            <Button
              onClick={() => setInfoMenu(e => !e)}
            >
              {infoMenu ? 'Hide':'View'} ticket info
            </Button>
          </ListItem>}
          <Collapse in={infoMenu}>
            <ListItem>
              <Grid container spacing={2}>
                  <Grid item lg={2} md={6} xs={12}>
                    <TextField fullWidth variant="standard" label={localization.ticket.status.text[context.lang]} value={ticket.status} InputLabelProps={{ shrink: true }}/>
                  </Grid>
                  <Grid item lg={2} md={6} xs={12}>
                    <TextField fullWidth variant="standard" label={localization.ticket.name[context.lang]} value={person} onChange={(e)=> {setPerson(e.target.value)}} readOnly={!context.authorized} InputLabelProps={{ shrink: true }} />
                  </Grid>
                  <Grid item lg={2} md={6} xs={12}>
                    <TextField fullWidth variant="standard" label={localization.ticket.phoneNumber[context.lang]} value={number} onChange={(e)=> {setNumber(e.target.value)}} readOnly={!context.authorized} InputLabelProps={{ shrink: true }} />
                  </Grid>
                  <Grid item lg={2} md={6} xs={12}>
                    <TextField fullWidth variant="standard" label={localization.ticket.company[context.lang]} value={company} onChange={(e)=> {setCompany(e.target.value)}} readOnly={!context.authorized} InputLabelProps={{ shrink: true }} />
                  </Grid>
                  <Grid item lg={2} md={6} xs={12}>
                    <TextField fullWidth variant="standard" label={localization.ticket.email[context.lang]} value={ticket.email} InputLabelProps={{ shrink: true }} />
                  </Grid>
                {(context.authorized && (context.isAdmin || ticket.status !== "Complete")) ? 
                <>
                  <Grid item lg={2} md={6} xs={12}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel shrink>{localization.ticket.location[context.lang]}</InputLabel>
                      <Select
                          value={city}
                          onChange={(e) => {setCity(e.target.value) }}
                          label={localization.ticket.location[context.lang]}
                      >
                          <MenuItem value="">...</MenuItem>
                          <MenuItem value="Office">{localization.ticket.inOffice[context.lang]}</MenuItem>
                          <MenuItem value="Lefkoşa">Lefkoşa</MenuItem>
                          <MenuItem value="Girne">Girne</MenuItem>
                          <MenuItem value="Gazimağusa">Gazimağusa</MenuItem>
                          <MenuItem value="İskele">İskele</MenuItem>
                          <MenuItem value="Güzelyurt">Güzelyurt</MenuItem>
                          <MenuItem value="Lefke">Lefke</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item lg={2} md={6} xs={12}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel>{localization.ticket.priority[context.lang]}</InputLabel>
                      <Select
                        value={priority}
                        onChange={(e) => {setPriority(e.target.value)}}
                        label={localization.ticket.priority[context.lang]}
                      >
                        <MenuItem value="high">High</MenuItem>
                        <MenuItem value="medium">Medium</MenuItem>
                        <MenuItem value="low">Low</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </>
                :
                <>
                  <Grid item lg={2} md={6} xs={12}>
                    <TextField
                      fullWidth
                      variant="standard" 
                      label={localization.ticket.location[context.lang]} 
                      value={ticket.city === "Office" ? localization.ticket.inOffice[context.lang]:ticket.city} 
                      InputLabelProps={{ shrink: true }} 
                    />
                  </Grid>
                  <Grid item lg={2} md={6} xs={12}>
                    <TextField
                      fullWidth
                      variant="standard" 
                      label={localization.ticket.priority[context.lang]} 
                      value={ticket.city} 
                      InputLabelProps={{ shrink: true }} 
                    />
                  </Grid>
                </>
                }
                {context.authorized && ticket.service_call ?
                <>
                  <Grid item lg={2} md={6} xs={12}>
                    <TextField fullWidth variant="standard" label={`SAP Contact (${contact.CardCode})`} value={contact.Name}  readOnly InputLabelProps={{ shrink: true }} />
                  </Grid>
                  <Grid item lg={2} md={6} xs={12}>
                    <TextField fullWidth variant="standard" label="SAP Cellular" value={contact.Cellolar} readOnly InputLabelProps={{ shrink: true }} />
                  </Grid>
                  <Grid item lg={2} md={6} xs={12}>
                    <TextField fullWidth variant="standard" label="SAP Tel1" value={contact.Tel1} InputLabelProps={{ shrink: true }}/>
                  </Grid>
                  <Grid item lg={2} md={6} xs={12}>
                    <TextField fullWidth variant="standard" label="SAP Tel2" value={contact.Tel2} readOnly InputLabelProps={{ shrink: true }} />
                  </Grid>
                  <Grid item lg={2} md={6} xs={12}>
                    <TextField fullWidth variant="standard" label="SAP E-Mail" value={contact.E_MailL} InputLabelProps={{ shrink: true }} />
                  </Grid>
                </> : null}
              </Grid>
            </ListItem>
          </Collapse>
          <TicketTextSection ticket={ticket} loadTicket={loadTicket} />
          <Divider />
          {ticket?.responses && ticket.responses.sort((a,b) => { 
            let timeA = new Date(a.date).getTime();
            let timeB = new Date(b.date).getTime();
            return  timeA > timeB ? -1 : 1;
          }).map((response) => {
            return (
              <TicketTextSection ticket={ticket} response={response} key={response._id} loadTicket={loadTicket}/>
            )
          })}
          <Divider />
          {((context.authorized && ticket.status !=="Complete") || context.isAdmin) &&  
          <>
            <ListItem>
              <Box component="div" sx={{display: 'flex', flexDirection: "column", width: "100%"}}>
                <FormControl variant="standard" sx={{ maxWidth: context.isMobile ? "100%":300 }}>
                  <InputLabel id="demo-simple-select-standard-label">{localization.ticket.status.text[context.lang]}</InputLabel>
                  <Select
                    value={newStatus}
                    onChange={handleChange}
                    label={localization.ticket.status.text[context.lang]}
                  >
                    <MenuItem value="Not reviewed">{localization.ticket.status.notReviewed[context.lang]}</MenuItem>
                    <MenuItem value="Incomplete">{localization.ticket.status.incomplete[context.lang]}</MenuItem>
                    <MenuItem value="Pending confirmation">{localization.ticket.status.pendingConfirmation[context.lang]}</MenuItem>
                    <MenuItem value="Complete">{localization.ticket.status.complete[context.lang]}</MenuItem>
                  </Select>
                </FormControl>
                {(ticket.status !=="Complete" || context.isAdmin) && 
                <>
                  <TextField
                    label="New response" 
                    multiline 
                    rows={4} 
                    sx={{width:"100%", mt:2, mb: 2}} 
                    onChange={(e) => setResponse(e.target.value)}
                  />
                  <input
                    ref={fileRef}
                    style={{display: "none"}} 
                    type="file"
                    accept="image/*"
                    onChange={handleChangeImages}
                    multiple
                  />
                  <div
                    style={{display: "flex", flexDirection: "row", width:"100%", flexFlow: "wrap"}}
                  >
                    {images.map((image,i) => {
                      return (
                        <div
                          style={{position: "relative"}}
                          key={i}
                        >
                          <img
                            src={URL.createObjectURL(image)}
                            alt={image.name}
                            loading="eager"
                            style={{height: 200, width:context.isMobile ? "100%":200, margin: "1em", borderRadius: 8, objectFit:"cover"}}
                          />
                          <IconButton
                            onClick={() => handleDeleteImage(i)}
                            sx={{position: "absolute", top: "0.6em", right: "0.6em"}}
                          >
                            <DeleteIcon style={{color: deleteColor}} />
                          </IconButton>
                        </div>
                      )
                      })
                    }
                  </div>
                  <Button onClick={() => fileRef.current.click()} sx={{width: 100}}>
                    <AddIcon />
                    Photo
                  </Button>
                </>
                }
              </Box>
            </ListItem>
            <Divider />
          </>}
          {context.authorized && ticket.service_call && <>
            <ListItem sx={{display:"flex", flexDirection:"column", p:0}}>
              <ServiceCallTables ticketId={ticket.id} serviceCall={ticket.service_call} loadTicket={loadTicket} />
            </ListItem>
          </>}
        </List>
        {service && <ServiceCall ticket={ticket} service={service} setService={setService} />}
      </Dialog>
  );
}

function TicketTextSection({response,ticket,loadTicket}) {
  const [context, setContext] = useGlobalContext();
  const [editMode, setEditMode] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [content, setContent] = useState('');
  const [translation, setTranslation] = useState('');
  
  useEffect(() => {
    if (content) return;
    if (response) setContent(response.content);
    else if (ticket.details) setContent(ticket.details)
  },[response,ticket]);

  const handleEditMode = () => {
    setAnchorEl(null);
    if (editMode && ((response ? response.content : ticket.details) !== content)){
      if (window.confirm('It looks like you have made some changes. Are you sure you want to cancel the edit?')) setContent(response ? response.content : ticket.details);
      else return;
    }
    setEditMode(e => !e);
  }
  const handleDelete = async () => {
    if (!response) return;
    setAnchorEl(null);
    if (!window.confirm('Are you sure you want to delete this response?')) return;
    const res = await deleteResponse({
      ticketId: ticket.id,
      responseId: response._id
    });
    if (res.message) alert(res.message);
    loadTicket(true);
  }
  const handleSave = async () => {
    setAnchorEl(null);
    let res;
    if (response) {
      res = await editResponse({
        ticketId: ticket.id,
        responseId: response._id,
        content
      });
    }
    else {
      res = await editTicketDetails({
        id: ticket.id,
        content
      });
    } 
    if (res.message) alert(res.message);
    setEditMode(false);
    loadTicket(true);
  }
  const handleOpenMenu = (e) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  }
  const handleCloseMenu = () => {
    setAnchorEl(null);
  }
  const handleTranslation = async () => {
    setAnchorEl(null)
    setTranslation('Translating...');
    const language = languages[context.lang];
    const res = await postPrompt(`Translate the following text into ${language} and only ${language}:\n${response?.content || ticket.details}`);
    const {choices} = res;
    if (!choices || choices.length < 1) return;
    const lastChoice = choices[choices.length - 1];
    setTranslation(`\n${language}:\n${lastChoice?.message?.content || ''}`);
  }
  return (
    <>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
      >
        {editMode ? <MenuItem onClick={handleSave}>Save Changes</MenuItem> : null}
        <MenuItem onClick={handleEditMode}>{editMode ? 'Cancel' : 'Edit'}</MenuItem>
        {!editMode ? <MenuItem onClick={handleTranslation}>Translate to ({context.lang})</MenuItem> : null}
        {response ? <MenuItem onClick={handleDelete}>Delete</MenuItem> : null}
      </Menu>
      <ListItem hover sx={{display: "flex", flexDirection:"column", alignItems:"flex-start"}}>
        <Divider />
        <Grid container sx={{width: 1}}>
          <Grid item xs={11} sx={{display:'flex', alignItems:'center'}}>
            {response ? 
            <Typography color="#4169E1">
              {`${response.name} (${new Date(response.date).toLocaleString("en-GB")})`}
            </Typography>
            : 
            <Typography color="#4169E1">Created {new Date(ticket.date).toLocaleString("en-GB")}</Typography>
            }
          </Grid>
          {context.authorized ? 
          <Grid item xs={1} sx={{display: 'flex', justifyContent: 'flex-end'}}>
            <IconButton itemProp={{style: {padding: 0}}} onClick={handleOpenMenu}>
              <MoreVertIcon fontSize="small" />
            </IconButton>
          </Grid>
          : null}
        </Grid>
        {editMode ? 
        <TextField
          multiline 
          sx={{width:"100%", mt:2, mb: 2}} 
          value={content}
          InputProps={{ style: { fontSize: 14, color: 'rgba(0, 0, 0, 0.6)', fontWeight: 'bold' } }}
          onChange={(e) => setContent(e.target.value)}
        />
        :
        <Typography
          fontSize={14}
          color="rgba(0, 0, 0, 0.6)"
          style={{whiteSpace: "pre-wrap", fontWeight:"bold"}}
        >
          {response ? response.content : ticket.details} 
        </Typography>
        }
        {translation ? 
        <Typography
          fontSize={14}
          color="rgba(0, 0, 0, 0.6)"
          style={{whiteSpace: "pre-wrap", fontWeight:"bold"}}
        >
          {translation} 
        </Typography>
        : null}
        {response ? 
        <div
          style={{display: "flex", flexDirection: "row", width:"100%", flexFlow: "wrap"}}
        >
          {response.attachments.map((filename,i) => {
            const src = `${config.ipAddress}/ticket/get_response_media/${ticket.id}/${filename}`;
            return (
              <img
                onClick={() => window.open(src,"_blank")}
                key={i}
                src={src}
                loading="eager"
                className="response-img"
                style={{height: 200, width:context.isMobile ? "100%":200, margin: "1em", borderRadius: 8, objectFit:"cover"}}
              />
            )
            })
          }
        </div>
        : null}
      </ListItem>
    </>
  )
}

function AskAIDialog({ticket,loadTicket,children}) {
  const [context, setContext] = useGlobalContext();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [prompt, setPrompt] = useState('');
  const [answer, setAnswer] = useState('');

  useEffect(() => {
    if (!ticket) return;
    const language = languages[context.lang];
    let promptStr = `This is a support ticket with the title '${ticket.title}'. Provided the following details about the support ticket:\n\n`;
    promptStr += `Ticket details:\n${ticket.details}\n\n`;
    ticket.responses?.forEach(response => promptStr += `Response:\n${response.content}\n\n`);
    promptStr += `Prompt: Can you assist me in resolving this issue and provide the solution in the ${language} language.`
    setPrompt(promptStr.trim());
  }, [ticket]);

  const handleAsk = async () => {
    setLoading(true);
    const res = await postPrompt(prompt);
    setLoading(false);
    const {choices} = res;
    if (!choices || choices.length < 1) return;
    const lastChoice = choices[choices.length - 1];
    setAnswer(lastChoice?.message?.content);
  }

  const handleClose = () => {
    loadTicket();
    setOpen(false);
  }

  return (
    <>
      <div onClick={() => setOpen(true)}>
        {children}
      </div>
      <Dialog open={answer.length > 0}>
        <DialogTitle>
          AI Response
          <IconButton
            aria-label="close"
            onClick={() => setAnswer('')}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{whiteSpace: 'pre-wrap'}}>
            {answer}
          </DialogContentText>
        </DialogContent>
      </Dialog>
      {open ? <Dialog
        open={open}
        onClose={handleClose}
        fullScreen={context.isMobile}
      >
        <DialogTitle>
          Ask AI
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{width:{lg: 500}}}>
          <Typography gutterBottom>Make sure to remove any confidential information before asking the AI bot. You can edit the prompt as you wish.</Typography>
          <TextField
            fullWidth
            multiline
            minRows={4}
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={handleAsk}>
            {loading ? 'Asking...':'Ask AI'}
          </Button>
        </DialogActions>
      </Dialog> : null}
    </>
  )
}

function GiveJobDialog(props) {
  const [open, setOpen] = useState(false);
  const [users,setUsers] = useState([]);
  const [currentUser,setCurrentUser] = useState("");

  useEffect(() => {
    loadUsers();
  }, []);
  
  const loadUsers = async () => {
    let res = await getUsers();
    setUsers(res);
    if (res.length > 0) setCurrentUser(res[0].username);
  }

  const handleChange = async (e) => {
    setCurrentUser(e.target.value);
  }
  const giveJob = async () => {
    if (!window.confirm("Are you sure you want to give this job? Once you confirm there is no going back.")) return;
    let res = await setJobBy(props.ticketId,currentUser);
    if (res.job_by) alert("You gave this job to "+res.job_by);
    props.loadTicket();
    handleClose();
  }

  const handleClose = () => {
    props.loadTicket()
    setOpen(false);
  }

  return (
    <>
      <div onClick={() => setOpen(true)}>
        {props.children}
      </div>
      {open ? <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          Give job
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{width:250}}>
          <FormControl fullWidth variant="standard" sx={{pt:1,pb:2}}>
            <InputLabel id="demo-simple-select-standard-label">User</InputLabel>
            <Select
              value={currentUser}
              onChange={handleChange}
              label="User"
            >
              {users.map(e => {
                return <MenuItem key={e.username} value={e.username}>{e.name}</MenuItem>
              })}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={giveJob}>Give the job to {currentUser}</Button>
        </DialogActions>
      </Dialog> : null}
    </>
  )
}

function ServiceCallTables({ticketId, serviceCall, loadTicket}) {
  const {expenses, times, workers} = serviceCall;
  const [open, setOpen] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [context, setContext] = useGlobalContext();

  useEffect(() => {
    (async () => {
      let res = await getEmployees();
      setEmployees(res);
      setWorker(res[0].empID);
    })();
  },[])

  const deleteColor = "dd0000";
  const addColor = "1976d2";
  
  // Service expenses
  const [itemNo, setItemNo] = useState("");
  const [description, setDescription] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [serialNo, setSerialNo] = useState("");
  const [expenseEditor, setExpenseEditor] = useState(false);
  
  // Service times
  const [work, setWork] = useState(false);
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [remarks,setRemarks] = useState("");
  const [timeEditor, setTimeEditor] = useState(false);

  // Workers
  const [worker, setWorker] = useState(-1);

  const clearTimeEntry = () => {
    // setWork(false);
    setStart("");
    setEnd("");
    setRemarks("");
  }

  const clearExpenseEntry = () => {
    setItemNo("");
    setDescription("");
    setQuantity(0);
    setSerialNo("");
  }
  
  const addServiceTime = async () => {
    let res = await setServiceTimes(ticketId, [...times,{work,start,end,remarks}]);
    if (res?.message) return alert(res.message);
    loadTicket(true);
    clearTimeEntry();
  }

  const addServiceExpense = async () => {
    let res = await setServiceExpenses(ticketId, [...expenses,{item_no: itemNo,description,quantity,serial_no: serialNo}]);
    if (res?.message) return alert(res.message);
    loadTicket(true);
    clearExpenseEntry()
  }

  const deleteServiceTime = async (index) => {
    delete times[index];
    let res = await setServiceTimes(ticketId, times.filter((e,i) => {return i !== index}));
    if (res?.message) return alert(res.message);
    loadTicket();
  }

  const deleteServiceExpense = async (index) => {
    let res = await setServiceExpenses(ticketId, expenses.filter((e,i) => {return i !== index}));
    if (res?.message) return alert(res.message);
    loadTicket();
  }

  const addServiceWorker = async () => {
    if (workers.find(id => id === worker)) return;
    let res = await setServiceWorkers(ticketId, [...workers, worker])
    if (res?.message) return alert(res.message);
    loadTicket(true)
  }

  const deleteServiceWorker = async (index) => {
    let res = await setServiceWorkers(ticketId, workers.filter((e,i) => {return i !== index}));
    if (res?.message) return alert(res.message);
    loadTicket();
  }

  return (
    <>
      <Collapse in={open} sx={{width:context.isMobile ? "100%":"calc(100% - 4em)", p: !context.isMobile && 4, pt: context.isMobile && 2 || 4, pb: context.isMobile && 2 || 4, backgroundColor: "#eeeeee !important"}} unmountOnExit>
        <div className="ticket-service-tables" style={{width: "100%"}}>
          <Paper elevation={2} sx={{width:context.isMobile?"100%":"calc(100% - 4em)", overflow:"auto", p:!context.isMobile && 4, mb:2}}>
            <Typography fontSize="large" fullWidth align="center" mb={1} mt={1}>Service expenses</Typography>
            <Table>
              {!context.isMobile && <TableHead>
                <TableCell>Item No.</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>S/N</TableCell>
                <TableCell sx={{width:30}}></TableCell>
              </TableHead>
              ||
              <TableHead>
                <TableCell>Name</TableCell>
                <TableCell sx={{width:30}}></TableCell>
                <TableCell sx={{width:30}}></TableCell>
              </TableHead>
              }
              <TableBody>
                {expenses.map((expense,i) => {
                  if (context.isMobile) return (
                    <TableRow key={expense._id}>
                      <TableCell>{expense.description} ({expense.quantity})</TableCell>
                      <TableCell>
                        <ServiceMobileDialog expense={expense} />
                      </TableCell>
                      <TableCell align="center">
                        <IconButton size="small" onClick={() => deleteServiceExpense(i)}>
                          <DeleteIcon style={{color:deleteColor}} size="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                  return (
                    <TableRow key={expense._id}>
                      <TableCell>
                        {expense.item_no}
                      </TableCell>
                      <TableCell>
                        {expense.description}
                      </TableCell>
                      <TableCell>
                        {expense.quantity}
                      </TableCell>
                      <TableCell>
                        {expense.serial_no}
                      </TableCell>
                      <TableCell align="center">
                        <IconButton size="small" onClick={() => deleteServiceExpense(i)}>
                          <DeleteIcon style={{color:deleteColor}} size="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                })}
                <TableRow>
                  {!context.isMobile && 
                  <>
                    <TableCell>
                      <TextField
                        variant="standard"
                        fullWidth
                        value={itemNo}
                        onChange={(e) => {setItemNo(e.target.value)}}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        variant="standard"
                        fullWidth
                        required
                        value={description}
                        onChange={(e) => {setDescription(e.target.value)}}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        variant="standard"
                        type="number"
                        defaultValue={0}
                        fullWidth
                        value={quantity.toString()}
                        onChange={(e) => {
                          if (e.target.value === "") return setQuantity(0);
                          setQuantity(Number.isNaN(parseInt(e.target.value)) ? quantity : parseInt(e.target.value))
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        variant="standard"
                        fullWidth
                        value={serialNo}
                        onChange={(e) => {setSerialNo(e.target.value)}}
                      />
                    </TableCell>
                  </>
                  }
                  <TableCell align="center" colSpan={context.isMobile && 5}>
                    <IconButton size="small" onClick={context.isMobile ? () => setExpenseEditor(true) : addServiceExpense}>
                      <AddIcon style={{color:addColor}} size="small" />
                    </IconButton>
                  </TableCell>
                  {context.isMobile && 
                  <Dialog
                    open={expenseEditor}
                    onClose={() => setExpenseEditor(false)}
                    fullScreen
                    TransitionComponent={Transition}
                  >
                    <DialogTitle>Expense</DialogTitle>
                    <DialogContent>
                      <TextField
                        variant="standard"
                        label="Item no."
                        fullWidth
                        sx={{mb:2}}
                        value={itemNo}
                        onChange={(e) => {setItemNo(e.target.value)}}
                      />
                      <TextField
                        variant="standard"
                        label="Description"
                        required
                        fullWidth
                        sx={{mb:2}}
                        value={description}
                        onChange={(e) => {setDescription(e.target.value)}}
                      />
                      <TextField
                        variant="standard"
                        type="number"
                        label="Quantity"
                        defaultValue={0}
                        fullWidth
                        sx={{mb:2}}
                        value={quantity.toString()}
                        onChange={(e) => {
                          if (e.target.value === "") return setQuantity(0);
                          setQuantity(Number.isNaN(parseInt(e.target.value)) ? quantity : parseInt(e.target.value))
                        }}
                      />
                      <TextField
                        variant="standard"
                        label="Serial no."
                        fullWidth
                        sx={{mb:2}}
                        value={serialNo}
                        onChange={(e) => {setSerialNo(e.target.value)}}
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() => {
                          setExpenseEditor(false);
                          addServiceExpense();
                        }}
                      >
                        Add expense
                      </Button>
                      <Button
                        onClick={() => {
                          setExpenseEditor(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </DialogActions>
                  </Dialog>}
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
          <Paper elevation={2} sx={{width:context.isMobile?"100%":"calc(100% - 4em)", overflow:"auto", p:!context.isMobile && 4, mb:2, mt:2}}>
            <Typography fontSize="large" fullWidth align="center" mb={1} mt={1}>Service times</Typography>
            <Table>
              {!context.isMobile && <TableHead>
                <TableCell>Work?</TableCell>
                <TableCell>Start</TableCell>
                <TableCell>End</TableCell>
                <TableCell sx={{width:60}}>Duration</TableCell>
                <TableCell>Remarks</TableCell>
                <TableCell sx={{width:30}}></TableCell>
              </TableHead>
              ||
              <TableHead>
                <TableCell>Duration</TableCell>
                <TableCell sx={{width:30}}></TableCell>
                <TableCell sx={{width:30}}></TableCell>
              </TableHead>
              }
              <TableBody>
                {times.map((time,i) => {
                  if (context.isMobile) return (
                    <TableRow key={time._id}>
                      <TableCell>{(new Date(time.end) - new Date(time.start)) / 60000} min</TableCell>
                      <TableCell>
                        <ServiceMobileDialog time={time} />
                      </TableCell>
                      <TableCell align="center">
                        <IconButton size="small" onClick={() => deleteServiceTime(i)}>
                          <DeleteIcon style={{color:deleteColor}} size="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                  return (
                    <TableRow key={time._id}>
                      <TableCell sx={{width: 70}}>
                        <Checkbox 
                          size="small"
                          checked={time.work}
                        />
                      </TableCell>
                      <TableCell sx={{width: 100}}>
                        <TextField
                            variant="standard"
                            type="datetime-local"
                            value={time.start}
                        />
                      </TableCell>
                      <TableCell sx={{width: 100}}>
                        <TextField
                            variant="standard"
                            type="datetime-local"
                            value={time.end}
                        />
                      </TableCell>
                      <TableCell>{parseInt((new Date(time.end) - new Date(time.start))/60000)} min</TableCell>
                      <TableCell style={{whiteSpace: "pre-wrap"}}>
                        {time.remarks}
                      </TableCell>
                      <TableCell align="center">
                        <IconButton size="small" onClick={() => deleteServiceTime(i)}>
                          <DeleteIcon style={{color:deleteColor}} size="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                })}
                <TableRow>
                  {!context.isMobile && 
                  <>
                    <TableCell sx={{width: 70}}>
                      <Checkbox 
                        size="small"
                        onChange={(e) => {setWork(e.target.checked)}}
                      />
                    </TableCell>
                    <TableCell sx={{width: 100}}>
                      <TextField
                          variant="standard"
                          type="datetime-local"
                          value={start}
                          onChange={(e) => {setStart(e.target.value)}}
                      />
                    </TableCell>
                    <TableCell sx={{width: 100}}>
                      <TextField
                          variant="standard"
                          type="datetime-local"
                          value={end}
                          onChange={(e) => {setEnd(e.target.value)}}
                      />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <TextField
                        variant="standard"
                        fullWidth
                        multiline
                        value={remarks}
                        onChange={(e) => {setRemarks(e.target.value)}}
                      />
                    </TableCell>
                  </>}
                  <TableCell align="center" colSpan={context.isMobile && 6}>
                    <IconButton size="small" onClick={context.isMobile ? () => setTimeEditor(true) : addServiceTime}>
                      <AddIcon style={{color:addColor}} size="small" />
                    </IconButton>
                  </TableCell>
                  {context.isMobile && 
                  <Dialog
                    open={timeEditor}
                    onClose={() => setTimeEditor(false)}
                    fullScreen
                    TransitionComponent={Transition}
                  >
                    <DialogTitle>Time</DialogTitle>
                    <DialogContent>
                      <div style={{display: "flex", alignItems:"center"}}>
                        Is work?: <Checkbox 
                          size="small"
                          onChange={(e) => {setWork(e.target.checked)}}
                        />
                      </div>
                      <TextField
                          variant="standard"
                          type="datetime-local"
                          label="Start time"
                          fullWidth
                          sx={{mb: 2}}
                          value={start}
                          onChange={(e) => {setStart(e.target.value)}}
                          InputLabelProps={{shrink: true}}
                      />
                      <TextField
                          variant="standard"
                          type="datetime-local"
                          label="End time"
                          fullWidth
                          sx={{mb: 2}}
                          value={end}
                          onChange={(e) => {setEnd(e.target.value)}}
                          InputLabelProps={{shrink: true}}
                      />
                      <TextField
                        variant="standard"
                        fullWidth
                        multiline
                        label="Remarks"
                        value={remarks}
                        onChange={(e) => {setRemarks(e.target.value)}}
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() => {
                          setTimeEditor(false);
                          addServiceTime();
                        }}
                      >
                        Add time
                      </Button>
                      <Button
                        onClick={() => {
                          setTimeEditor(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </DialogActions>
                  </Dialog>}
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
          <Paper elevation={2} sx={{width:context.isMobile?"100%":"calc(100% - 4em)", overflow:"auto", p:!context.isMobile && 4, mt:2}}>
            <Typography fontSize="large" fullWidth align="center" mb={1} mt={1}>Technicians</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>First name</TableCell>
                  <TableCell>Last name</TableCell>
                  <TableCell sx={{width:30}}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {employees.length && workers.map((worker, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell>
                        {worker}
                      </TableCell>
                      <TableCell>
                        {employees.find(e => e.empID === worker).firstName}
                      </TableCell>
                      <TableCell>
                        {employees.find(e => e.empID === worker).lastName}
                      </TableCell>
                      <TableCell>
                        <IconButton size="small" onClick={() => deleteServiceWorker(i)}>
                          <DeleteIcon style={{color:deleteColor}} size="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>    
                  )
                })}
                <TableRow>
                  <TableCell colSpan={3}>
                    <FormControl variant="standard" fullWidth >
                      <InputLabel shrink>Employees</InputLabel>
                      <Select
                          label="Employees"
                          value={worker}
                          onChange={(e) => setWorker(e.target.value)}
                      >
                        {employees.map(e => {
                          return (
                            <MenuItem key={e.empID} value={e.empID}>{e.firstName} {e.lastName}</MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <IconButton size="small" onClick={addServiceWorker}>
                      <AddIcon style={{color:addColor}} size="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </div>
      </Collapse>
      <Button fullWidth onClick={() => {setOpen(open => !open)}}>
        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      </Button>
    </>
  )
}

function ServiceMobileDialog({time,expense}){
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button variant="outlined" onClick={() => setOpen(true)}>
        View
      </Button>
      {open ? <Dialog 
        open={open}
      >
        {time && <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              Is work?:<Checkbox 
                size="small"
                checked={time.work}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Start time"
                variant="standard"
                type="datetime-local"
                value={time.start}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="End time"
                variant="standard"
                type="datetime-local"
                value={time.end}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Duration"
                variant="standard"
                type="text"
                value={`${parseInt((new Date(time.end) - new Date(time.start))/60000)} min`}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{width:"100%",whiteSpace: "pre-wrap"}}>
                {time.remarks}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>}
        {expense && <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="standard"
                label="Item no."
                fullWidth
                value={expense.item_no}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="standard"
                label="Description"
                fullWidth
                sx={{mb:2}}
                value={expense.description}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="standard"
                type="number"
                label="Quantity"
                fullWidth
                value={expense.quantity.toString()}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="standard"
                label="Serial no."
                fullWidth
                sx={{mb:2}}
                value={expense.serial_no}
              />
            </Grid>
          </Grid>
        </DialogContent>}
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog> : null}
    </>
  )
}

function UploadFileSection({ticketId,children}){
  const [open, setOpen] = useState(false);

  return (
    <>
      <div onClick={() => setOpen(true)}>
        {children}
      </div>
      {open ? <Dialog
        open={open}
        onClose={() => setOpen(false)}
      >
          <QNAPTable ticketId={ticketId} />
      </Dialog> : null}
    </>
  )
}

function ListFileSection({ticketId,children}){
  const [progress, setProgress] = useState(false);
  const [open, setOpen] = useState(false);

  const [files, setFiles] = useState({});

  const loadFiles = async () => {
    setProgress(true);
    let res = await getTicketFiles(ticketId);
    setFiles(res);
    setProgress(false);
  }
  
  useEffect(() => {
    open && loadFiles();
    !open && setFiles({});
  }, [open])

  return (
    <>
      <div onClick={() => setOpen(true)}>
        {children}
      </div>
      {open ? <Dialog
        open={open}
        onClose={() => setOpen(false)}
      >
        {progress && <div style={{display:"flex", justifyContent:"center",alignItems:"center", width: 400, height: 400, backgroundColor: "rgba(0,0,0,0.2)"}}>
          <CircularProgress />
        </div>}
        {!progress && (() => {
          let arr = [];
          Object.keys(files).forEach(company => {
            Object.keys(files[company]).forEach(location => {
              Object.keys(files[company][location]).forEach(project => {
                let backups = files[company][location][project].backups;
                let extras = files[company][location][project].extras;
                [{name:"extras",files: extras},{name:"backups", files: backups}].forEach(section => {
                  section.files?.length > 0 && arr.push(
                    <>
                      <TableContainer className="qnap-tables" component={Paper} sx={{height:"100%"}}>
                        <Table stickyHeader>
                          <TableHead>
                            <TableCell>
                              <div style={{display:"flex", alignItems: "center"}}>
                                {company}
                                <NavigateNextIcon fontSize="small" sx={{color:"gray"}} />
                                {location}
                                <NavigateNextIcon fontSize="small" sx={{color:"gray"}} />
                                {project}
                                <NavigateNextIcon fontSize="small" sx={{color:"gray"}} />
                                {section.name}
                              </div>
                            </TableCell>
                            <TableCell width={30}></TableCell>
                          </TableHead>
                          <TableBody>
                            {section.files.map(e => {
                              return (
                                <TableRow key={e} hover>
                                  <TableCell>
                                    <div style={{display:"flex", alignItems:"center"}}>
                                      <CloudIcon sx={{color: "#d0d0d0", mr:2}} />
                                      <Typography fontSize="small">{e}</Typography>
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <Download
                                      company={company}
                                      location={location}
                                      project={project}
                                      type={section.name}
                                      file={e}
                                    >
                                      <Tooltip title="Download">
                                        <IconButton size="small">
                                          <DownloadIcon fontSize="small" />
                                        </IconButton>
                                      </Tooltip>
                                    </Download>
                                  </TableCell>
                                </TableRow>
                              )
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  )
                })
              }) 
            })
          })
          if (arr.length === 0) return <Typography sx={{p:4}}>No files exist for this ticket </Typography>
          return arr
        })()
        }
        
      </Dialog> : null}
    </>
  )
}

function VoucherSection({ticketId,children}) {
  const [open, setOpen] = useState(false);

  const [assignId, setAssignId] = useState("");

  const [voucher, setVoucher] = useState({});
  const [time, setTime] = useState(0);

  const loadVoucher = async () => {
    let res = await getVoucher({ticketId});
    if (res?.ticketId === ticketId) setVoucher(res);
  }

  const handleAssignVoucher = async () => {
    let res = await assignVoucher({voucherId: assignId,ticketId});
    if (res.message) return alert(res.message);
    loadVoucher();
  }

  const handleUseVoucher = async () => {
    if (!window.confirm(`Are you sure you want to use up ${time} minutes of this voucher?`)) return;
    let res = await consumeVoucher({id: voucher.id, time});
    if (res.message) return alert(res.message);
    alert(`You have used up ${time} minutes of this voucher, you have ${parseInt(res.timeLimit) - parseInt(res.timeUsed)} minutes left`);
    loadVoucher();
  }

  useEffect(() => {
    if (open) loadVoucher();
    else setVoucher({});
  }, [open])
  
  return (
    <>
      <div onClick={() => setOpen(true)}>
        {children}
      </div>
      {open ? <Dialog
        open={open}
        onClose={() => setOpen(false)}
        keepMounted={false}
      >
        {voucher.ticketId === ticketId && 
          <>
            <DialogContent>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>End date</TableCell>
                    <TableCell>ID</TableCell>
                    <TableCell>Time used</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{new Date(voucher.dateEnd).toLocaleString('en-GB')}</TableCell>
                    <TableCell>{voucher.id}</TableCell>
                    <TableCell>{voucher.timeUsed} / {voucher.timeLimit}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <TextField
                fullWidth
                value={time}
                type="number"
                variant="standard"
                sx={{mt:2}}
                label="Time to use (mins)"
                onChange={(e) => setTime(parseInt(e.target.value))}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleUseVoucher}>Use voucher</Button>
            </DialogActions>
          </>
          ||
          <>
            <DialogContent style={{minWidth: 400}}>
              <TextField
                fullWidth
                margin="dense"
                variant="standard"
                label="Voucher ID"
                value={assignId}
                onChange={(e) => setAssignId(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleAssignVoucher}>Assign voucher</Button>
            </DialogActions>
          </>
        }
      </Dialog> : null}
    </>
  )
}