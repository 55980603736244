import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import { useEffect, useRef, useState, useTransition } from 'react';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import FolderIcon from '@mui/icons-material/Folder';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import LockIcon from '@mui/icons-material/Lock';
import DescriptionIcon from '@mui/icons-material/Description';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';
import BackupIcon from '@mui/icons-material/Backup';
import CloudIcon from '@mui/icons-material/Cloud';
import DownloadIcon from '@mui/icons-material/Download';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { addPassword, deletePassword, getChecklist, getNote, listFiles, listPasswords, mkdir, rename, setNote, updateChecklist } from '../api/qnap';
import UploadFormDialog from './upload_form';
import Ticket from './ticket';
import { useGlobalContext } from '../global_context';

const folderColor = "#fdb900";
const deleteColor = "dd0000";
const blueColor = "#1976d2";
export default function QNAPTable(props){
  const {ticketId} = props
  const [company, setCompany] = useState("");
  const [location, setLocation] = useState("");
  const [project, setProject] = useState("");

  const [section, setSection] = useState("");
  
  const stateProps = {company,setCompany,location,setLocation,project,setProject,section,setSection,ticketId}

  return (
    <TableContainer className="qnap-tables" component={Paper} sx={{height:"100%"}}>
      {!company && !location && !project && <CompaniesTable {...stateProps} />}
      {company && !location && !project && <LocationsTable {...stateProps} />}
      {company && location && !project && <ProjectsTable {...stateProps} />}
      {(company && location && project) &&
        (!section && <Project {...stateProps} />)
        ||
        (section === "notes" && <Notes {...stateProps} />)
        ||
        (section === "checklist" && <Checklist {...stateProps} />)
        ||
        (section === "passwords" && <Passwords {...stateProps} />)
        ||
        ((section === "backups" || section === "extras") && <FilesTable {...stateProps} />)
      }
    </TableContainer>
  )
}

function CompaniesTable(props) {
  const {setCompany} = props;
  const [context] = useGlobalContext();
  const {obj: companyObject, arr: customerArr} = context.customers;

  const [companyFilter, setCompanyFilter] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [companies, setCompanies] = useState([]);

  const [menu, setMenu] = useState();
  const [companyToAdd, setCompanyToAdd] = useState();


  const loadCompanies = async () => {
    setCompanyToAdd(customerArr[0].CardCode);
    setCompanyList(customerArr);
    setCompanies((await listFiles({})).datas);
  }

  const addCompany = async () => {
    if (companies.find(e => e.filename === companyToAdd)) return alert("Company already exists in the list");
    await mkdir({company: companyToAdd});
    setMenu(false);
    loadCompanies();
  }

  useEffect(() => {
    loadCompanies();
  }, []);

  return (
    <Table stickyHeader>
      <TableHead>
        <TableCell width={100}>Company name</TableCell>
        <TableCell>
          <TextField
            label="Find by name"
            type="search"
            size="small"
            value={companyFilter}
            onChange={(e) => setCompanyFilter(e.target.value)}
          />
        </TableCell>
        <TableCell width={30}>
          <Tooltip title="Add a company">
            <IconButton onClick={() => setMenu(true)}>
              <AddBusinessIcon sx={{color:blueColor}} />
            </IconButton>
          </Tooltip>
          <Dialog
            open={menu}
            onClose={() => setMenu(false)}
          >
            <DialogTitle>Add a company</DialogTitle>
            <DialogContent>
              <FormControl variant="standard" fullWidth>
                <InputLabel shrink>Select company to add</InputLabel>
                <Select
                  value={companyToAdd}
                  onChange={(e) => {setCompanyToAdd(e.target.value)}}
                  label="Company"
                >
                  {companyList.map((e) => {
                    return <MenuItem value={e.CardCode}>{e.CardName}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={addCompany}>Add</Button>
              <Button onClick={() => setMenu(false)}>Cancel</Button>
            </DialogActions>
          </Dialog>
        </TableCell>
      </TableHead>
      <TableBody>
        {companies.filter(e => companyObject[e.filename].toLowerCase().includes(companyFilter.toLowerCase())).sort((a,b) => companyObject[a.filename].localeCompare(companyObject[b.filename])).map(e => {
          return (
            <TableRow key={e.filename} hover onClick={() => setCompany(e.filename)}>
              <TableCell colSpan={3}>
                <div style={{display:"flex", alignItems:"center"}}>
                  <FolderIcon sx={{color: folderColor, mr:2}} />
                  <Typography fontSize="small">{companyObject[e.filename]}</Typography>
                </div>
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  );
}

function LocationsTable(props) {
  const {company, setCompany, setLocation} = props;

  const [context] = useGlobalContext();
  const {obj: companyObject} = context.customers;

  const [locations, setLocations] = useState([]);

  const [menu, setMenu] = useState(false);
  const [locationToAdd, setLocationToAdd] = useState("");

  const [newName, setNewName] = useState("");
  const [oldName, setOldName] = useState("");

  const loadLocations = async () => {
    setLocations((await listFiles({company})).datas);
  }

  const addLocation = async () => {
    if (locations.find(e => e.filename.toLowerCase() === locationToAdd.toLowerCase())) return alert("Location already exists in the list");
    await mkdir({company,location: locationToAdd});
    setMenu(false);
    loadLocations();
  }

  const renameFolder = async () => {
    let res = await rename({company,oldName,newName});
    if (res.message) return alert(res.message);
    if (res.status !== 1) return;
    cancelRename();
    loadLocations();
  }

  const cancelRename = () => {
    setOldName("");
    setNewName("");
  }

  useEffect(() => {
    loadLocations();
  }, []);

  return (
    <Table stickyHeader>
      <TableHead>
        <TableCell>{companyObject[company]} locations</TableCell>
        <TableCell width={30}>
          <Tooltip title="Add a location">
            <IconButton onClick={() => setMenu(true)}>
              <AddLocationIcon sx={{color:blueColor}} />
            </IconButton>
          </Tooltip>
          <Dialog
            open={menu}
            onClose={() => setMenu(false)}
          >
            <DialogTitle>Add a location</DialogTitle>
            <DialogContent>
              <TextField
                label="New location"
                value={locationToAdd}
                onChange={(e) => setLocationToAdd(e.target.value)}
                fullWidth
                sx={{mt:2}}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={addLocation}>Add</Button>
              <Button onClick={() => setMenu(false)}>Cancel</Button>
            </DialogActions>
          </Dialog>
        </TableCell>
      </TableHead>
      <TableBody>
        <TableRow hover onClick={() => setCompany("")}>
          <TableCell colSpan={2}>
            <div style={{display:"flex", alignItems:"center"}}>
              <FolderIcon sx={{color: folderColor, mr:2}} />
              <Typography fontSize="small">..</Typography>
            </div>
          </TableCell>
        </TableRow>
        {locations.map(e => {
          return (
            <TableRow key={e.filename} hover>
              <TableCell onClick={() => setLocation(e.filename)}>
                <div style={{display:"flex", alignItems:"center"}}>
                  <FolderIcon sx={{color: folderColor, mr:2}} />
                  <Typography fontSize="small">{e.filename}</Typography>
                </div>
              </TableCell>
              <TableCell>
                <Tooltip title="Rename">
                  <IconButton onClick={() => setOldName(e.filename)}>
                    <DriveFileRenameOutlineIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
      <Dialog
        open={oldName !== ""}
        onClose={cancelRename}
      >
        <DialogContent>
          <TextField 
            fullWidth
            variant="standard"
            label={oldName}
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={renameFolder}>rename</Button>
          <Button onClick={cancelRename}>cancel</Button>
        </DialogActions>
      </Dialog>
    </Table>
  );
}

function ProjectsTable(props) {
  const {location, company, setProject, setLocation} = props;

  const [context] = useGlobalContext();
  const {obj: companyObject} = context.customers;

  const [projects, setProjects] = useState([]);

  const [menu, setMenu] = useState(false);
  const [projectToAdd, setProjectToAdd] = useState("");

  const [newName, setNewName] = useState("");
  const [oldName, setOldName] = useState("");

  const renameFolder = async () => {
    let res = await rename({company,location,oldName,newName});
    if (res.message) return alert(res.message);
    if (res.status !== 1) return;
    cancelRename();
    loadProjects();
  }

  const cancelRename = () => {
    setOldName("");
    setNewName("");
  }

  const loadProjects = async () => {
    setProjects((await listFiles({company,location})).datas);
  }

  const addProject = async () => {
    if (projects.find(e => e.filename.toLowerCase() === projectToAdd.toLowerCase())) return alert("Project already exists in the list");
    await mkdir({company, location, project: projectToAdd});
    setMenu(false);
    loadProjects();
  }

  useEffect(() => {
    loadProjects();
  }, []);

  return (
    <Table stickyHeader>
      <TableHead>
        <TableCell>
          <div style={{display:"flex", alignItems: "center"}}>
            {companyObject[company]}
            <NavigateNextIcon fontSize="small" sx={{color:"gray"}} />
            {location}
          </div>
        </TableCell>
        <TableCell width={30}>
          <Tooltip title="Add a project">
            <IconButton onClick={() => setMenu(true)}>
              <AccountTreeIcon sx={{color:blueColor}} />
            </IconButton>
          </Tooltip>
          <Dialog
            open={menu}
            onClose={() => setMenu(false)}
          >
            <DialogTitle>Add a project</DialogTitle>
            <DialogContent>
              <TextField
                label="New project"
                value={projectToAdd}
                onChange={(e) => setProjectToAdd(e.target.value)}
                fullWidth
                sx={{mt:2}}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={addProject}>Add</Button>
              <Button onClick={() => setMenu(false)}>Cancel</Button>
            </DialogActions>
          </Dialog>
        </TableCell>
      </TableHead>
      <TableBody>
        <TableRow hover onClick={() => setLocation("")}>
          <TableCell colSpan={2}>
            <div style={{display:"flex", alignItems:"center"}}>
              <FolderIcon sx={{color: folderColor, mr:2}} />
              <Typography fontSize="small">..</Typography>
            </div>
          </TableCell>
        </TableRow>
        {projects.map(e => {
          return (
            <TableRow key={e.filename} hover>
              <TableCell onClick={() => setProject(e.filename)}>
                <div style={{display:"flex", alignItems:"center"}}>
                  <FolderIcon sx={{color: folderColor, mr:2}} />
                  <Typography fontSize="small">{e.filename}</Typography>
                </div>
              </TableCell>
              <TableCell>
                <Tooltip title="Rename">
                  <IconButton onClick={() => setOldName(e.filename)}>
                    <DriveFileRenameOutlineIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
      <Dialog
        open={oldName !== ""}
        onClose={cancelRename}
      >
        <DialogContent>
          <TextField 
            fullWidth
            variant="standard"
            label={oldName}
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={renameFolder}>rename</Button>
          <Button onClick={cancelRename}>cancel</Button>
        </DialogActions>
      </Dialog>
    </Table>
  );
}

function Project(props){
  const {location, company, setProject, project, setSection} = props;

  const [context] = useGlobalContext();
  const {obj: companyObject} = context.customers;

  return (
    <Table stickyHeader>
      <TableHead>
        <TableCell>
          <div style={{display:"flex", alignItems: "center"}}>
            {companyObject[company]}
            <NavigateNextIcon fontSize="small" sx={{color:"gray"}} />
            {location}
            <NavigateNextIcon fontSize="small" sx={{color:"gray"}} />
            {project}
          </div>
        </TableCell>
      </TableHead>
      <TableBody>
        {/* Go back */}
        <TableRow hover onClick={() => setProject("")}>
          <TableCell colSpan={2}>
            <div style={{display:"flex", alignItems:"center"}}>
              <FolderIcon sx={{color: folderColor, mr:2}} />
              <Typography fontSize="small">..</Typography>
            </div>
          </TableCell>
        </TableRow>
        {/* Backups */}
        <TableRow hover onClick={() => setSection("backups")}>
          <TableCell colSpan={2}>
            <div style={{display:"flex", alignItems:"center"}}>
              <FolderIcon sx={{color: folderColor, mr:2}} />
              <Typography fontSize="small">Backups</Typography>
            </div>
          </TableCell>
        </TableRow>
        {/* Extras */}
        <TableRow hover onClick={() => setSection("extras")}>
          <TableCell colSpan={2}>
            <div style={{display:"flex", alignItems:"center"}}>
              <FolderIcon sx={{color: folderColor, mr:2}} />
              <Typography fontSize="small">Extra files</Typography>
            </div>
          </TableCell>
        </TableRow>
        {/* Passwords */}
        <TableRow hover onClick={() => setSection("passwords")}>
          <TableCell colSpan={2}>
            <div style={{display:"flex", alignItems:"center"}}>
              <LockIcon sx={{mr:2}} />
              <Typography fontSize="small">Passwords</Typography>
            </div>
          </TableCell>
        </TableRow>
        {/* Notes */}
        <TableRow hover onClick={() => setSection("notes")}>
          <TableCell colSpan={2}>
            <div style={{display:"flex", alignItems:"center"}}>
              <DescriptionIcon sx={{color: "gray", mr:2}} />
              <Typography fontSize="small">Notes</Typography>
            </div>
          </TableCell>
        </TableRow>
        {/* Checklist */}
        <TableRow hover onClick={() => setSection("checklist")}>
          <TableCell colSpan={2}>
            <div style={{display:"flex", alignItems:"center"}}>
              <DescriptionIcon sx={{color: "gray", mr:2}} />
              <Typography fontSize="small">Checklist</Typography>
            </div>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

function FilesTable(props){
  const {company,location,project,section,setSection,ticketId} = props;

  const [context] = useGlobalContext();
  const {obj: companyObject} = context.customers;

  const [files, setFiles] = useState([]);

  const [viewTicket, setViewTicket] = useState(false);
  const [id, setID] = useState(-1);

  const loadFiles = async () => {
    setFiles((await listFiles({company,location,project,type: section})).datas);
  }

  useEffect(() => {
    if (viewTicket === false) setID(-1);
  }, [viewTicket])

  useEffect(() => {
    loadFiles();
  }, []);

  const handleOpenTicket = (filename) => {
    setID(parseInt(filename.split("_")[1]));
    setViewTicket(true); 
  }

  return (
    <Table stickyHeader>
      <TableHead>
        <TableCell>
          <div style={{display:"flex", alignItems: "center"}}>
            {companyObject[company]}
            <NavigateNextIcon fontSize="small" sx={{color:"gray"}} />
            {location}
            <NavigateNextIcon fontSize="small" sx={{color:"gray"}} />
            {project}
            <NavigateNextIcon fontSize="small" sx={{color:"gray"}} />
            {section}
          </div>
        </TableCell>
        <TableCell width={30}>
          <Tooltip title="Add files">
            <UploadFormDialog ticketId={ticketId} {...props} type={section} onClose={() => loadFiles()}>
              <IconButton>
                <BackupIcon sx={{color:blueColor}} />
              </IconButton>
            </UploadFormDialog>
          </Tooltip>
        </TableCell>
      </TableHead>
      <TableBody>
        <TableRow hover onClick={() => setSection("")}>
          <TableCell colSpan={2}>
            <div style={{display:"flex", alignItems:"center"}}>
              <FolderIcon sx={{color: folderColor, mr:2}} />
              <Typography fontSize="small">..</Typography>
            </div>
          </TableCell>
        </TableRow>
        {files.map(e => {
          if (ticketId && !e.filename.startsWith(`TICKET_${ticketId}`)) return <></>;
          let isForTicket = e.filename.startsWith('TICKET_') && !ticketId;
          return (
            <TableRow key={e.filename} hover>
              <TableCell
                className={isForTicket ? "upload-btn-glow":""} 
                onClick={isForTicket ? (() => handleOpenTicket(e.filename)) : (() => {})}
              >
                <div style={{display:"flex", alignItems:"center"}}>
                  <CloudIcon sx={{color: "#d0d0d0", mr:2}} />
                  <Typography fontSize="small">
                    {e.filename}
                  </Typography>
                </div>
              </TableCell>
              <TableCell>
                <Download
                  company={company}
                  location={location}
                  project={project}
                  type={section}
                  file={e.filename}
                >
                  <Tooltip title="Download">
                    <IconButton size="small">
                      <DownloadIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Download>
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
      {!ticketId && viewTicket && (id > -1) && <Ticket edit={viewTicket} setEdit={setViewTicket} ticketId={id} />}
    </Table>
  );
}

function Notes(props){
  const {company,location,project,setSection} = props;

  const [context] = useGlobalContext();
  const {obj: companyObject} = context.customers;

  const [notes,setNotes] = useState("");

  const loadNotes = async () => {
    let res = await getNote({company,location,project});
    setNotes(res);
  }

  const saveNotes = async () => {
    let res = await setNote({company,location,project,note:notes});
    if (res.status === 1) alert("Notes were successfuly saved")
  }

  useEffect(() => {
    loadNotes();
  },[]);

  return(
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell width={30}>
            <IconButton onClick={() => setSection("")}>
              <ArrowBackIcon fontSize="small" />
            </IconButton>
          </TableCell>
          <TableCell>
            <div style={{display:"flex", alignItems: "center"}}>
              {companyObject[company]}
              <NavigateNextIcon fontSize="small" sx={{color:"gray"}} />
              {location}
              <NavigateNextIcon fontSize="small" sx={{color:"gray"}} />
              {project}
              <NavigateNextIcon fontSize="small" sx={{color:"gray"}} />
              Notes
            </div>
          </TableCell>
          <TableCell width={30}>
            <IconButton onClick={() => saveNotes()}>
              <SaveIcon fontSize="small" sx={{color:blueColor}} />
            </IconButton>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableCell colSpan={3}>
          <TextField
            fullWidth
            multiline
            variant="standard"
            label="Notes"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          />
        </TableCell>
      </TableBody>
    </Table>
  )
}

function Passwords(props){
  const {company,location,project,setSection} = props;

  const [context] = useGlobalContext();
  const {obj: companyObject} = context.customers;

  const [passwords,setPasswords] = useState([]);

  const [menu, setMenu] = useState(false);
  const [passwordToAdd, setPasswordToAdd] = useState("");
  const [descriptionToAdd, setDescriptionToAdd] = useState("");

  const loadPasswords = async () => {
    let res = await listPasswords({company,location,project});
    setPasswords(res);
  }

  const add = async () => {
    let res = await addPassword({company,location,project,description:descriptionToAdd,password: passwordToAdd});
    if (res.status === 1) alert("New password added");
    setPasswordToAdd("");
    setDescriptionToAdd("");
    setMenu(false);
    loadPasswords();
  }

  const remove = async (index) => {
    if (!window.confirm("Are you sure you want to delete this password?")) return;
    await deletePassword({company,location,project,index});
    loadPasswords();
  }

  useEffect(() => {
    loadPasswords();
  },[]);

  return(
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell width={30}>
            <IconButton onClick={() => setSection("")}>
              <ArrowBackIcon fontSize="small" />
            </IconButton>
          </TableCell>
          <TableCell>
            <div style={{display:"flex", alignItems: "center"}}>
              {companyObject[company]}
              <NavigateNextIcon fontSize="small" sx={{color:"gray"}} />
              {location}
              <NavigateNextIcon fontSize="small" sx={{color:"gray"}} />
              {project}
              <NavigateNextIcon fontSize="small" sx={{color:"gray"}} />
              Passwords
            </div>
          </TableCell>
          <TableCell width={30} align="center">
            <Tooltip title="Add password">
              <IconButton onClick={() => setMenu(true)}>
                <AddIcon fontSize="small" sx={{color:blueColor}} />
              </IconButton>
            </Tooltip>
            <Dialog
            open={menu}
            onClose={() => setMenu(false)}
          >
            <DialogTitle>Add a new password</DialogTitle>
            <DialogContent>
              <TextField
                label="Description of the password"
                value={descriptionToAdd}
                onChange={(e) => setDescriptionToAdd(e.target.value)}
                fullWidth
                sx={{mt:2}}
              />
              <TextField
                label="New password"
                value={passwordToAdd}
                onChange={(e) => setPasswordToAdd(e.target.value)}
                fullWidth
                sx={{mt:2}}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={add}>Add</Button>
              <Button onClick={() => setMenu(false)}>Cancel</Button>
            </DialogActions>
          </Dialog>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {passwords.map((e,i) => {
          return (
            <TableRow key={i}>
              <TableCell colSpan={2}>
                <TextField
                  label={e.description}
                  fullWidth
                  size="small"
                  variant="standard"
                  value={e.password}
                />
              </TableCell>
              <TableCell>
                <IconButton onClick={() => remove(i)}>
                  <DeleteIcon style={{color:deleteColor}} />
                </IconButton>
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

function Checklist(props){
  const {company,location,project,setSection} = props;
  
  const [context] = useGlobalContext();
  const {obj: companyObject} = context.customers;

  const [isPending,startTransition] = useTransition();
  const [checklist, setChecklist] = useState({});

  const loadChecklist = async () => {
      let res = await getChecklist({company,location,project});
      setChecklist(res);
  }

  const saveChecklist = async () => {
    let res = await updateChecklist({company,location,project,checklist});
    if (res.status === 1) alert("The checklist was successfuly saved");
  }

  const updateField = (fieldName, value) => {
    startTransition(() => {
      let obj = {...checklist};
      for (let field of obj.fields){
        if (field.field === fieldName) {
          field.value = value;
          setChecklist(obj);
          return;
        }
      }
    });
  }

  useEffect(() => {
    loadChecklist();
  },[]);

  return (
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell width={30}>
            <IconButton onClick={() => setSection("")}>
              <ArrowBackIcon fontSize="small" />
            </IconButton>
          </TableCell>
          <TableCell>
            <div style={{display:"flex", alignItems: "center"}}>
              {companyObject[company]}
              <NavigateNextIcon fontSize="small" sx={{color:"gray"}} />
              {location}
              <NavigateNextIcon fontSize="small" sx={{color:"gray"}} />
              {project}
              <NavigateNextIcon fontSize="small" sx={{color:"gray"}} />
              Checklist
            </div>
          </TableCell>
          <TableCell width={30}>
            <IconButton onClick={() => saveChecklist()}>
              <SaveIcon fontSize="small" sx={{color:blueColor}} />
            </IconButton>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell colSpan={3}>
            {Array.isArray(checklist.fields) && checklist.fields.map((field,i) => {
              return (
              <div key={i}>
                {
                  field.type === "select" && (
                    <FormControl variant="standard" fullWidth>
                      <InputLabel id="demo-simple-select-standard-label">{field.label}</InputLabel>
                      <Select
                          value={field.value}
                          onChange={(e)=>{updateField(field.field,e.target.value)}}
                          label={field.label}
                      >
                        {field.selectOptions.map(e => {
                          return <MenuItem key={e} value={e}>{e}</MenuItem>
                        })}
                      </Select>
                    </FormControl>
                  )
                  ||
                  field.type === "checkbox" && (
                    <FormControlLabel control={<Checkbox onChange={(e) => {updateField(field.field,e.target.checked)}} checked={field.value} />} label={field.label} />
                  )
                  ||
                  field.type === "text" && (
                    <TextField
                      label={field.label}
                      variant="standard"
                      fullWidth
                      defaultValue={field.value}
                      onChange={(e) => updateField(field.field,e.target.value)}
                    />
                  )
                }
                {i !== (checklist.fields.length - 1) && <Divider sx={{mt:1,mb:1}} />}
              </div>
              )
            })}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{p:0,m:0}} colSpan={3}>
            <Table sx={{mb:4}} stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={{height: 40}}>
                    Table contents
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(checklist.tableContent) && checklist.tableContent.map((row,i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell>
                        <Typography sx={{mb:1}}>
                          {row.description}
                        </Typography>
                        <TextField
                          multiline
                          fullWidth
                          label="Comments"
                          defaultValue={row.comments}
                          // InputLabelProps={{shrink: row.comments.length > 0}}
                          onChange={(e) => startTransition(() => {
                            checklist.tableContent[i].comments = e.target.value;
                            setChecklist({...checklist});
                          })}
                          InputProps={{ // <-- This is where the toggle button is added.
                            endAdornment: (
                              <InputAdornment position="end">
                                <Typography
                                  color={row.check ? "#32CD32":"#dd0000"}
                                  className="show-hover"
                                  onClick={()=> startTransition(() =>{
                                    let obj = {...checklist};
                                    obj.tableContent[i].check = !obj.tableContent[i].check;
                                    setChecklist(obj);
                                  })}
                                >
                                  {row.check ? "DONE":"NOT DONE"}
                                </Typography>
                              </InputAdornment>
                            )
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

export function Download(props) {
  const {company,location,project,type,file} = props;
  const downloadRef = useRef();
  const download = (e) => {
    if (!window.confirm(`Are you sure you want to download ${file}`)) return e.preventDefault();
    e.stopPropagation();
  }
  return (
    <div onClick={() => downloadRef.current.click()}>
      {props.children}
      <a onClick={download} href={`${window.location.origin.replace(':3000','')}/qnap/download/${company}/${location}/${project}/${type}/${file}`} download={file} ref={downloadRef} style={{display:"none"}}></a>
    </div>
  )
}