import request from "./request";

const getMessages = async () => {
    return await request("POST", "messages/get")
}

const setMessages = async (close_ticket, new_ticket, pending, response, remind) => {
    return await request("POST", "messages/set", {close_ticket, new_ticket, pending, response, remind});
}

export { getMessages, setMessages };