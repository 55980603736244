import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { createTicket } from '../api/ticket';
import {useGlobalContext} from '../global_context';
import localization from '../localization.json';
import { forwardRef, useState } from 'react';
import Ticket from './ticket';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const validateEmail = (email) => {
	if (email.includes(",")){
		let allMatch = true;
		email.split(",").forEach(e => {
			allMatch &&= String(e)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			);
		})
		return allMatch;
	}
	return String(email)
	  .toLowerCase()
	  .match(
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	);
};
export default function CreateTicketDialog(props) {
  const [context,setContext] = useGlobalContext();
  const [person,setPerson] = useState(context.fullName);
  const [email,setEmail] = useState("");
  const [phone,setPhone] = useState("");
  const [company,setCompany] = useState("");
  const [city,setCity] = useState("Lefkoşa");
  const [priority, setPriority] = useState("medium");
  const [title,setTitle] = useState("");
  const [details,setDetails] = useState("");
  const [loading,setLoading] = useState(false);
  const [voucherId, setVoucherId] = useState("");
  const handleClose = () => {
    if (loading) return;
    props.setValue(props.lastValue);
  };

  const sendTicket = async () => {
    if (!person || !title || !details) return alert("Please fill at least (name, title, details)");
    setLoading(true);
    let res = await createTicket({
      person,
      email: validateEmail(email) ? email : "",
      number: phone || "",
      company: company || "",
      title,
      details,
      city,
      voucherId,
      priority
    });
    alert(res.message);
    setLoading(false);
    handleClose();
  }

  const viewTicket = () => {
    props.setViewTicket(true);
  }
  return (
    <Dialog
        open={props.value === 1}
        TransitionComponent={Transition}
        onClose={handleClose}
        fullWidth
        fullScreen={context.isMobile}
        aria-describedby="alert-dialog-slide-description"
    >
        <DialogTitle>{localization.ticket.newTicket[context.lang]}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {localization.ticket.enterInfo[context.lang]}
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item lg={6} xs={12}>
              <TextField
                autoFocus
                margin="dense"
                label={localization.ticket.name[context.lang]}
                type="text"
                fullWidth
                variant="standard"
                value={person}
                InputLabelProps={{
                  shrink: person.length > 0
                }}
                onChange={(e) => {setPerson(e.target.value);}}
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <TextField
                margin="dense"
                label={localization.ticket.email[context.lang]}
                type="email"
                fullWidth
                variant="standard"
                value={email}
                onChange={(e) => {setEmail(e.target.value);}}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                label={localization.ticket.phoneNumber[context.lang]}
                type="text"
                fullWidth
                variant="standard"
                value={phone}
                onChange={(e) => {setPhone(e.target.value);}}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                label={localization.ticket.company[context.lang]}
                type="text"
                fullWidth
                variant="standard"
                value={company}
                onChange={(e) => {setCompany(e.target.value);}}
              />
            </Grid>
            <Grid item lg={context.authorized ? 6 : 12} xs={12}>
              <FormControl variant="standard" fullWidth sx={{mt:2}}>
                <InputLabel>{localization.ticket.location[context.lang]}</InputLabel>
                <Select
                  value={city}
                  onChange={(e) => {setCity(e.target.value)}}
                  label={localization.ticket.location[context.lang]}
                >
                  {context.authorized && <MenuItem value="Office">{localization.ticket.inOffice[context.lang]}</MenuItem>}
                  <MenuItem value="Lefkoşa">Lefkoşa</MenuItem>
                  <MenuItem value="Girne">Girne</MenuItem>
                  <MenuItem value="Gazimağusa">Gazimağusa</MenuItem>
                  <MenuItem value="İskele">İskele</MenuItem>
                  <MenuItem value="Güzelyurt">Güzelyurt</MenuItem>
                  <MenuItem value="Lefke">Lefke</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {context.authorized ? 
            <Grid item lg={6} xs={12}>
              <FormControl variant="standard" fullWidth sx={{mt:2}}>
                <InputLabel>{localization.ticket.priority[context.lang]}</InputLabel>
                <Select
                  value={priority}
                  onChange={(e) => {setPriority(e.target.value)}}
                  label={localization.ticket.priority[context.lang]}
                >
                  <MenuItem value="high">High</MenuItem>
                  <MenuItem value="medium">Medium</MenuItem>
                  <MenuItem value="low">Low</MenuItem>
                </Select>
              </FormControl>
            </Grid> : null}
            <Grid item xs={12}>
              <TextField
                margin="dense"
                label={localization.ticket.title[context.lang]}
                type="text"
                fullWidth
                variant="standard"
                value={title}
                onChange={(e) => {setTitle(e.target.value);}}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                label={localization.ticket.details[context.lang]}
                type="text"
                fullWidth
                variant="standard"
                multiline
                value={details}
                onChange={(e) => {setDetails(e.target.value);}}
                rows={4}
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <TextField
                margin="dense"
                fullWidth
                label={localization.ticket.voucher[context.lang]}
                type="text"
                variant="standard"
                value={voucherId}
                onChange={(e) => {setVoucherId(e.target.value);}}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={viewTicket}>{localization.ticket.alreadyHaveTicket[context.lang]}</Button>
          <Button disabled={loading} onClick={sendTicket}>{localization.ticket.createTicket[context.lang]}</Button>
        </DialogActions>
      </Dialog>
  );
}

export function ViewTicketDialog(props) {
  const [context,setContext] = useGlobalContext();
  const [id, setId] = useState(false);
  const [_id, set_id] = useState(false);

  const [edit, setEdit] = useState(false);
  const handleClose = () => {
    props.deactivate();
    props.setValue(props.lastValue);
  };

  const viewTicket = () => {
    setEdit(true);
    // handleClose();
  }

  return (
    <Dialog
        open={props.active}
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{localization.ticket.viewTicket[context.lang]}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {localization.ticket.enterDetails[context.lang]}
          </DialogContentText>
          <TextField
            margin="dense"
            id="id"
            label={localization.ticket.id[context.lang]}
            type="text"
            fullWidth
            variant="standard"
            onChange={(e)=> {setId(e.target.value)}}
          />
          <TextField
            margin="dense"
            id="token"
            label={localization.ticket.token[context.lang]}
            type="text"
            fullWidth
            variant="standard"
            onChange={(e)=> {set_id(e.target.value)}}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={viewTicket}>{localization.ticket.viewTicket[context.lang]}</Button>
        </DialogActions>
        {edit && <Ticket edit={edit} setEdit={setEdit} ticketId={id} ticket_id={_id} />}
      </Dialog>
  );
}
