import {Routes, Route} from 'react-router-dom';
import AlertDialog from './components/alert_dialog';
import LoginPage from './pages/login';
import MainPage from './pages/main';

function App() {

  return (
    <>
      <Routes>
        <Route path="login" element={<LoginPage />} />
        <Route path="" element={<MainPage />} />
        <Route path="/payment" element={<MainPage payment />} />
        <Route path="/payment/success" element={<MainPage paymentOk />} />
        <Route path="/payment/fail" element={<MainPage paymentFail />} />
      </Routes>
      <AlertDialog />
    </>
  );
}

export default App;