import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { addServiceCall, getCallTypes, getContacts, getCustomers, getEmployees, getPriorities, getProblemTypes } from '../api/ticket';
import { useGlobalContext } from '../global_context';
function compare( a, b ) {
    if ( a.similarity < b.similarity ){
      return 1;
    }
    if ( a.similarity > b.similarity ){
      return -1;
    }
    return 0;
}
function getSortedCustomers(customers, company){
    let arr = [];
    for (let customer of customers){
        arr.push({customer: customer, similarity: window.stringSimilarity.compareTwoStrings(customer.CardName,company)});
    }
    arr.sort(compare);
    let newarr = [];
    for (let obj of arr)
        newarr.push(obj.customer);
    return newarr;
}
function getSortedContacts(contacts, person){
    let arr = [];
    for (let contact of contacts){
        arr.push({contact: contact, similarity: window.stringSimilarity.compareTwoStrings(contact.Name,person)});
    }
    arr.sort(compare);
    let newarr = [];
    for (let obj of arr)
        newarr.push(obj.contact);
    return newarr;
}
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ServiceCall(props) {

  const [context] = useGlobalContext();

  let [companies, setCompanies] = useState([]);
  let [contacts, setContacts] = useState([]);
  let [problemTypes, setProblemTypes] = useState([]);
  let [callTypes, setCallTypes] = useState([]);
  let [technicians, setTechnicians] = useState([]);
  let [priorities, setPriorities] = useState([]);

  let [company, setCompany] = useState("");
  let [contact, setContact] = useState("");
  let [problemType, setProblemType] = useState("");
  let [callType, setCallType] = useState("");
  let [technician, setTechnician] = useState("");
  let [priority, setPriority] = useState("");
  
  const loadLists = async () => {
    let {arr: customers_res} = context.customers;
    let problemTypes_res = await getProblemTypes();
    let callTypes_res = await getCallTypes();
    let employees_res = await getEmployees();
    let priorities_res = await getPriorities();
    let sorted_customers = getSortedCustomers(customers_res, props.ticket.company); 
    setCompanies(sorted_customers);
    setCompany(sorted_customers[0].CardCode);

    setProblemTypes(problemTypes_res);
    setProblemType(problemTypes_res[0].prblmTypID);

    setCallTypes(callTypes_res);
    setCallType(callTypes_res[0].callTypeID);
    
    setTechnicians(employees_res);
    setTechnician(employees_res[0].empID)

    setPriorities(priorities_res);
    setPriority(priorities_res[0].Descript[0]);
  }

  useEffect(() => {
    loadLists();
  },[])

  useEffect(() => {
    (async () => {
        if (!company) return;
        let contacts_res = await getContacts(company);
        if (contacts_res.length === 0) {
            setContacts([]);
            setContact("");
            return;
        }
        let sorted_contacts = getSortedContacts(contacts_res, props.ticket.person)
        setContacts(sorted_contacts);
        setContact(sorted_contacts[0].CntctCode);
    })();
  }, [company]);

  const addCall = async () => {
    let res = await addServiceCall(props.ticket.id, props.ticket._id, company, contact, props.ticket.title.replace("'","''"), problemType, callType, technician, priority);
    alert(res.message);
    handleClose();
  }

  const handleClose = () => {
    props.setService(false);
  };
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.service}
        fullWidth
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Service Call
        </BootstrapDialogTitle>
        <DialogContent dividers>
            <FormControl variant="standard" sx={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-standard-label">Company</InputLabel>
                <Select
                  value={company}
                  onChange={(e) => {setCompany(e.target.value)}}
                  label="Company"
                  sx={{mb:2}}
                >
                  {companies.map((e, i) => {
                      return <MenuItem key={i} value={e.CardCode}>{e.CardName}</MenuItem>
                  })}
                </Select>
            </FormControl>
            <FormControl variant="standard" sx={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-standard-label">Contact</InputLabel>
                <Select
                  value={contact}
                  onChange={(e) => {setContact(e.target.value)}}
                  label="Contact"
                  sx={{mb:2}}
                >
                  {contacts.map((e, i) => {
                      return <MenuItem key={i} value={e.CntctCode}>{e.Name}</MenuItem>
                  })}
                </Select>
            </FormControl>
            <FormControl variant="standard" sx={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-standard-label">Problem Type</InputLabel>
                <Select
                  value={problemType}
                  onChange={(e) => {setProblemType(e.target.value)}}
                  label="Problem Type"
                  sx={{mb:2}}
                >
                  {problemTypes.map((e, i) => {
                      return <MenuItem key={i} value={e.prblmTypID}>{e.Name}</MenuItem>
                  })}
                </Select>
            </FormControl>
            <FormControl variant="standard" sx={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-standard-label">Call Type</InputLabel>
                <Select
                  value={callType}
                  onChange={(e) => {setCallType(e.target.value)}}
                  label="Call Type"
                  sx={{mb:2}}
                >
                  {callTypes.map((e, i) => {
                      return <MenuItem key={i} value={e.callTypeID}>{e.Name}</MenuItem>
                  })}
                </Select>
            </FormControl>
            <FormControl variant="standard" sx={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-standard-label">Technician</InputLabel>
                <Select
                  value={technician}
                  onChange={(e) => {setTechnician(e.target.value)}}
                  label="Technician"
                  sx={{mb:2}}
                >
                  {technicians.map((e, i) => {
                      return <MenuItem key={i} value={e.empID}>{`${e.firstName} ${e.lastName}`}</MenuItem>
                  })}
                </Select>
            </FormControl>
            <FormControl variant="standard" sx={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-standard-label">Priority</InputLabel>
                <Select
                  value={priority}
                  onChange={(e) => {setPriority(e.target.value)}}
                  label="Priority"
                >
                  {priorities.map((e, i) => {
                      return <MenuItem key={i} value={e.Descript[0]}>{e.Descript}</MenuItem>
                  })}
                </Select>
            </FormControl>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={addCall}>
            add
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
