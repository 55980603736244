import {  Box, CircularProgress, Divider, Grid, Paper, Typography } from "@mui/material";
import { useLayoutEffect, useRef, useState } from "react";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import { useGlobalContext } from "../global_context";
import localization from '../localization.json';

export default function ContactTab(){
    const [context, setContext] = useGlobalContext();
    const [loaded, setLoaded] = useState(false);
    const map = useRef();
    const onMapLoad = () => {
        setLoaded(true);
    }
    useLayoutEffect(()=>{
        map.current.addEventListener('load', onMapLoad);
        return () => {
            map.current.removeEventListener('load',onMapLoad);
        };
    },[]);

    return (
        <Box sx={{height: `calc(100vh - ${context.isMobile ? 51:150}px)`, overflow:"auto"}}>
            <Typography variant="h4" color="text.secondary" align="center" gutterBottom mt={2}>
                {localization.contactUs.contactSupport[context.lang]}
            </Typography>
            <Typography align="center">
                {localization.contactUs.visitOffice1[context.lang]}<br/>
                {localization.contactUs.visitOffice2[context.lang]}
            </Typography>
            <Divider sx={{m:2}}/>
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <Paper elevation={4} sx={{display: "flex", alignItems:"center", justifyContent:"center", width: {xs: "100%", md: 700}, height: {xs: window.innerHeight - 180, md: 500}}}>
                    {!loaded && <CircularProgress />}
                    <iframe ref={map} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10968.166188922783!2d33.35160733402291!3d35.18515846392964!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x669190971631aa1f!2sAk%C3%A7aba%20Communications!5e0!3m2!1sen!2s!4v1660220966057!5m2!1sen!2s" width={loaded && "100%" || 0} height={loaded && "100%" || 0} style={{border:0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
                </Paper>
            </Box>
            <Box sx={{width: "100%", display: 'flex', alignItems: 'center', mt: 2, mb: 2, flexDirection: 'column'}}>
                <Typography variant="subtitle2" sx={{display:"flex", alignItems:"center"}}>
                    <EmailIcon />&nbsp;<a style={{textDecoration:"none", color:"#555"}} href="mailto:ticket@akcaba.com">ticket@akcaba.com</a>
                </Typography>
                <Typography variant="subtitle2" sx={{display:"flex", alignItems:"center"}}>
                    <PhoneIcon />&nbsp;<a style={{textDecoration:"none", color:"#555"}} href="tel:+903922291515">+90 (392) 229 15 15</a>
                </Typography>
                <Typography variant="subtitle2" sx={{display:"flex", alignItems:"center"}}>
                    <WhatsAppIcon />&nbsp;<a style={{textDecoration:"none", color:"#555"}} href="https://wa.me/905428791515" target="_blank">+90 (542) 879 15 15</a>
                </Typography>
            </Box>
            <Divider sx={{m:2}}/>
        </Box>
    );
}