import { TableContainer, Paper, Table, TableRow, TableHead, TableCell, TableBody, Button, TextField, TableFooter, TablePagination, IconButton, Dialog, DialogActions, DialogTitle, DialogContent, FormControl, InputLabel, Select, MenuItem, Tooltip, Collapse, Typography } from "@mui/material";
import { useEffect, useRef, useState, useTransition } from "react";
import { getTickets, removeTicket } from "../api/ticket";
import { useGlobalContext } from "../global_context";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SyncIcon  from '@mui/icons-material/Sync';
import Ticket from "./ticket";
import localization from '../localization.json';
import DatePickerDialog from "./date_filter";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import PersonIcon from '@mui/icons-material/Person';
import { useInterval } from "../use_interval";
import { getUsers } from "../api/users";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box } from "@mui/system";
import { green, grey, red } from "@mui/material/colors";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";

const folderColor = "#fdb900";
const deleteColor = "#dd0000";
const blueColor = "#1976d2";

// prop: {status}
const statuses = {
    0: undefined,
    1: "Not reviewed",
    2: "Incomplete",
    3: "Pending confirmation",
    4: "Complete"
};

const refreshTime = 10 * 1000; // refresh tickets every 10 seconds

export default function TicketsTable(props){
    const didMount = useRef(false);

    let [isPending, startTransition] = useTransition();
    let [context,setContext] = useGlobalContext();
    let [users,setUsers] = useState([]);

    let [searchTitle, setSearchTitle] = useState("");
    let [searchCompany, setSearchCompany] = useState("");
    let [searchCall, setSearchCall] = useState(undefined);
    let [tickets, setTickets] = useState([]);
    let [edit, setEdit] = useState(undefined);
    let [id, setId] = useState(-1);
    let [_id, set_id] = useState(-1);

    let [count, setCount] = useState(0);
    let [page,setPage] = useState(0);
    let [rowsPerPage, setRowsPerPage] = useState(50);

    const [city, setCity] = useState("");
    const [datePicker, setDatePicker] = useState(false);
    const [fromDate,setFromDate] = useState(0);
    const [toDate,setToDate] = useState(Number.MAX_SAFE_INTEGER);
    const [idSort,setIdSort] = useState('');

    const [jobBy,setJobBy] = useState("");


    useEffect(() => {
        if (!didMount.current) return;
        loadTickets();
    },[searchTitle,searchCompany,searchCall,fromDate,toDate,city,page,rowsPerPage,jobBy,idSort]);

    useEffect(()=>{
        !datePicker && loadTickets();
    },[datePicker])
    const loadTickets = () => {
        startTransition( async () => {
            let res = await getTickets(statuses[props.status],searchCompany,searchTitle,page,rowsPerPage,searchCall,fromDate,toDate,city,jobBy,idSort);
            if (res.message === "Not authorized") return;
            res.tickets.forEach(ticket => {
                let userUpdate = ticket.usersUpdated.find(userUpdate => {return userUpdate.username === context.username})
                if (userUpdate) 
                {
                    ticket.read = userUpdate.lastUpdate >= ticket.lastUpdate ? true:false
                }
                else ticket.read = false;
            });
            setTickets(res.tickets);
            setCount(res.count);
        });
    }
    useInterval(loadTickets, refreshTime);

    const editTicket = (id, _id) => {
        setEdit(true);
        setId(id);
        set_id(_id);
    }

    const deleteTicket = async (id,_id) => {
        if (!window.confirm("Are you sure you want to delete this ticket?")) return;
        let res = await removeTicket(id, _id);
        alert(res.message);
        loadTickets();
    }

    const loadUsers = async () => {
        if (context.isAdmin) setUsers(await getUsers());
    }
    
    useEffect(() => {
        if (!didMount.current) return;
        if (page === 0)
            loadTickets();
        else setPage(0);
    }, [props.status]);
    useEffect(() => {
        if (!didMount.current) didMount.current = true;
        if (edit === undefined) loadUsers();
        if (edit === false){
            loadTickets();
        }
    }, [edit])

    const handleChangePage = (e,n) => {
        setPage(n);
    }

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }

    const applyDateFilter = (fromDate,toDate) => {
        setFromDate(fromDate);
        setToDate(toDate);
        setDatePicker(false)
    }

    const detectEnter = (e) => {
        if(e.keyCode === 13 && tickets.length > 0)
            editTicket(tickets[0].id, tickets[0]._id);
    }

    const headProps = {status:props.status,users,setDatePicker,detectEnter,searchCompany,setSearchCompany,searchCall,setSearchCall,searchTitle,setSearchTitle,city,setCity,loadTickets,jobBy,setJobBy,idSort,setIdSort};
    
    return (
    <div style={{height:"100%", width:"100%", position: "relative"}}>
        <DatePickerDialog open={datePicker} applyFilter={applyDateFilter} clearFilter={() => {applyDateFilter(0,Number.MAX_SAFE_INTEGER)}} setDatePicker={setDatePicker} />
        <TableContainer component={Paper} sx={{height:'calc(100% - 52px)', borderBottomLeftRadius:0, borderBottomRightRadius:0}}>
            <Table stickyHeader size="small">
                {context.isMobile ? <MobileHead props={headProps} /> : <DesktopHead props={headProps} />}

                <TableBody>
                    {tickets.map((ticket) => {
                        const rowProps = {ticket,editTicket,deleteTicket,status:props.status};
                        return context.isMobile ? <MobileRow key={ticket.id} props={rowProps} /> : <DesktopRow key={ticket.id} props={rowProps} />
                    })}
                </TableBody>
                <TableFooter >
                    
                </TableFooter>
            </Table>
            {edit && <Ticket edit={edit} setEdit={setEdit} ticketId={id} ticket_id={_id} />}
        </TableContainer>
        <TablePagination
            rowsPerPageOptions={[50, 200, 1000]}
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{position:"absolute",right:0,bottom:0}}
        />
    </div>);
}

function DesktopHead(props){
    const [context] = useGlobalContext();
    const {users,setDatePicker,detectEnter,searchCompany,setSearchCompany,searchCall,setSearchCall,searchTitle,setSearchTitle,city,setCity,loadTickets,jobBy,setJobBy,status,idSort,setIdSort} = props.props;
    return (
        <TableHead>
            <TableRow>
                <TableCell sx={{maxWidth:20}} onClick={() => {
                    if (idSort === '') setIdSort('desc');
                    if (idSort === 'desc') setIdSort('asc');
                    if (idSort === 'asc') setIdSort('');
                }}>
                    <Box className="pointer-cursor" sx={{display: 'flex', alignItems: 'center'}}>
                        ID
                        {idSort === 'asc' ? 
                        <KeyboardArrowUp fontSize="small" />
                        :
                        <KeyboardArrowDown fontSize="small" style={{color: idSort ? 'black' : '#a0a0a0'}} />
                        }
                    </Box>
                </TableCell>
                <TableCell sx={{maxWidth:140}}>
                    Updated
                    <Tooltip title="If a date is in red color then it is the creation date because its status never changed after the date update.">
                        <IconButton onClick={() => {setDatePicker(true);}}>
                            <CalendarMonthIcon />
                        </IconButton>
                    </Tooltip>
                </TableCell>
                {status === 0 && <TableCell sx={{maxWidth:100}}>Status</TableCell>}
                <TableCell sx={{maxWidth:100}}>
                    <TextField onKeyDown={detectEnter}  type="search" label="Company" size="small" value={searchCompany} onChange={(e)=>{
                        setSearchCompany(e.target.value);
                    }} />
                </TableCell>
                <TableCell sx={{maxWidth:200}}>
                    <TextField onKeyDown={detectEnter} type="search" label="Title" size="small" value={searchTitle} fullWidth onChange={(e)=>{
                        setSearchTitle(e.target.value);
                    }} />
                </TableCell>
                <TableCell sx={{maxWidth:100}}>
                    <TextField onKeyDown={detectEnter}  type="search" label="Call No." size="small" value={searchCall} fullWidth onChange={(e)=>{
                        setSearchCall(e.target.value.replace(/[^0-9]/g, '') || undefined);
                    }} />
                </TableCell>
                <TableCell width={90}>
                    <FormControl variant="standard" fullWidth sx={{pb:1.5}}>
                        <InputLabel>{localization.ticket.location[context.lang]}</InputLabel>
                        <Select
                            value={city}
                            onChange={(e) => {setCity(e.target.value) }}
                            label={localization.ticket.location[context.lang]}
                        >
                            <MenuItem value="">...</MenuItem>
                            <MenuItem value="Office">In office</MenuItem>
                            <MenuItem value="Lefkoşa">Lefkoşa</MenuItem>
                            <MenuItem value="Girne">Girne</MenuItem>
                            <MenuItem value="Gazimağusa">Gazimağusa</MenuItem>
                            <MenuItem value="İskele">İskele</MenuItem>
                            <MenuItem value="Güzelyurt">Güzelyurt</MenuItem>
                            <MenuItem value="Lefke">Lefke</MenuItem>
                        </Select>
                    </FormControl>
                </TableCell>
                <TableCell width={75}>
                    <FormControl variant="standard" fullWidth sx={{pb:1.5}}>
                        <InputLabel>Job by</InputLabel>
                        <Select
                            value={jobBy}
                            onChange={(e) => {setJobBy(e.target.value)}}
                            label="Job by"
                        >
                            <MenuItem value={false}>All</MenuItem>
                            {
                                context.isAdmin && users.map(e => <MenuItem key={e.username} value={e.username}>{e.name}</MenuItem> )
                                ||
                                <MenuItem value="anything">My jobs</MenuItem>
                            }
                        </Select>
                    </FormControl>
                </TableCell>
            </TableRow>
        </TableHead>
    )
}

function MobileHead(props){
    const {loadTickets} = props.props;
    return (
    <TableHead>
        <TableRow>
            <TableCell width={20}>ID</TableCell>
            <TableCell>Title</TableCell>
            <TableCell width={10}> 
                <MobileFilter 
                    funcs={props.props}
                />
            </TableCell>
            <TableCell width={10}>
                <IconButton onClick={() => {loadTickets()}}>
                    <SyncIcon />
                </IconButton>
            </TableCell>
        </TableRow>
    </TableHead>
    )
}

function DesktopRow(props){
    const [context] = useGlobalContext();
    const { ticket, editTicket, deleteTicket, status } = props.props
    return (
        <TableRow hover key={ticket.id}>
            <TableCell style={!ticket.read && {fontWeight: "bold"} || {}}>{ticket.id}</TableCell>
            <TableCell style={!ticket.read && {fontWeight: "bold"} || {}} sx={{color: status > 1 && !ticket.status_date && "red"}}>
                {ticket.lastUpdate && (new Date(ticket.lastUpdate)).toLocaleString("en-GB") || (new Date(ticket.date)).toLocaleString("en-GB")}
            </TableCell>
            {status === 0 && <TableCell style={!ticket.read && {fontWeight: "bold"} || {}}>{ticket.status}</TableCell>}
            <TableCell style={!ticket.read && {fontWeight: "bold"} || {}}>
                {ticket.customer ? <PersonIcon style={{marginBottom:-5}} /> : null} {ticket.company}
            </TableCell>
            <TableCell>
                <Box
                    display="flex"
                    alignItems="center"
                >

                <PriorityBox priority={ticket.priority} />
                <Typography
                    fontSize={14}
                    fontWeight={ticket.read ? undefined : "bold" }
                    ml={1}
                    display="flex"
                    alignItems="center"
                >
                    {ticket.title}
                </Typography>
                </Box>
            </TableCell>
            <TableCell style={!ticket.read && {fontWeight: "bold"} || {}}>{ticket.service_call ? ticket.service_call.call_id : "N/A"}</TableCell>
            <TableCell style={!ticket.read && {fontWeight: "bold"} || {}}>{ticket.city}</TableCell>
            <TableCell align="right">
                <Tooltip title={(ticket.status === "Complete" && !context.isAdmin) ? "View":"Edit"}>
                    <IconButton
                        size="small"
                        variant="outlined"
                        onClick={() => {editTicket(ticket.id, ticket._id)}}
                    >
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                {context.isAdmin ?
                <Tooltip title="Delete">
                    <IconButton
                        size="small"
                        variant="outlined"
                        onClick={() => {deleteTicket(ticket.id, ticket._id)}}
                    >
                        <DeleteIcon style={{color: deleteColor}} />
                    </IconButton>
                </Tooltip> : null}
            </TableCell>
        </TableRow>
    )
}

function MobileRow(props) {
    const [context,setContext] = useGlobalContext();
    const { ticket, editTicket, deleteTicket, status } = props.props;
    const [open, setOpen] = useState(false);
  
    return (
      <>
        <TableRow hover sx={{ '& > *': { borderBottom: 'unset' }, height: 50 }} onClick={()=>{setOpen(!open)}}>
          <TableCell style={!ticket.read && {fontWeight: "bold"} || {}}>{ticket.id}</TableCell>
          <TableCell style={!ticket.read && {fontWeight: "bold"} || {}} colSpan={4} scope="row">{ticket.customer ? <PersonIcon style={{marginBottom:-5}} /> : null} {ticket.title}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ padding: 0,borderBottom: 0 }} colSpan={5}>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Table size="small">
                    <TableBody>
                        <TableRow sx={{...{fontSize: 12, lineHeight: 1}, color: status > 1 && !ticket.status_date && "red"}}>
                            <TableCell sx={{borderBottom:0}} colSpan={2}>Date: {ticket.lastUpdate && (new Date(ticket.lastUpdate)).toLocaleString("en-GB") ||
                                                        (new Date(ticket.date)).toLocaleString("en-GB") }
                            </TableCell>
                        </TableRow>
                        {status === 0 && <TableRow sx={{fontSize: 12, lineHeight: 1}}>
                            <TableCell sx={{borderBottom:0}} colSpan={2}>Status: {ticket.status}</TableCell>
                        </TableRow>}
                        <TableRow sx={{fontSize: 12, lineHeight: 1}}>
                            <TableCell sx={{borderBottom:0}} colSpan={2}>Company: {ticket.company}</TableCell>
                        </TableRow>
                        <TableRow sx={{fontSize: 12, lineHeight: 1}}>
                            <TableCell sx={{borderBottom:0}} colSpan={2}>Service call: {ticket.service_call ? ticket.service_call.call_id : "N/A"}</TableCell>
                        </TableRow>
                        <TableRow sx={{fontSize: 12, lineHeight: 1}}>
                            <TableCell colSpan={2}>{localization.ticket.location[context.lang]}: {ticket.city}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell><Button size="small" variant="outlined" onClick={() => {editTicket(ticket.id, ticket._id)}}>{(ticket.status === "Complete" && !context.isAdmin) ? "view":"edit"}</Button></TableCell>
                            <TableCell>
                                {context.isAdmin && <Button size="small" variant="outlined" onClick={() => {deleteTicket(ticket.id, ticket._id)}}>delete</Button>}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );

}

function MobileFilter(props){
    const [context, setContext] = useGlobalContext();
    const {setDatePicker,detectEnter,searchCompany,setSearchCompany,searchCall,setSearchCall,searchTitle,setSearchTitle,city,setCity,jobBy,setJobBy} = props.funcs;
    const [open,setOpen] = useState(false);
    const [users,setUsers] = useState([]);
    const loadUsers = async () => {
        if (context.isAdmin) setUsers(await getUsers());
    }

    useEffect(() => {
        loadUsers()
    }, []);
    return (
        <>
            <IconButton onClick={() => {setOpen(!open)}}>
                <FilterAltIcon />
            </IconButton>
            <Dialog
                open={open}
                onClose={() => {setOpen(false);}}
            >
                <DialogContent>
                    <Tooltip title="If a date is in red color then it is the creation date because its status never changed after the date update.">
                        <IconButton onClick={() => {setDatePicker(true);}} sx={{fontSize:"medium"}}>
                            Filter by date &nbsp;<CalendarMonthIcon />
                        </IconButton>
                    </Tooltip>
                    <TextField sx={{mb: 2}} onKeyDown={detectEnter} fullWidth type="search" label="Company" size="small" value={searchCompany} onChange={(e)=>{
                        setSearchCompany(e.target.value);
                    }} />
                    <TextField sx={{mb: 2}} onKeyDown={detectEnter} fullWidth type="search" label="Title" size="small" value={searchTitle} onChange={(e)=>{
                        setSearchTitle(e.target.value);
                    }} />
                    <TextField onKeyDown={detectEnter} fullWidth type="search" label="Call No." size="small" value={searchCall} onChange={(e)=>{
                        setSearchCall(e.target.value.replace(/[^0-9]/g, '') || undefined);
                    }} />
                    <FormControl variant="standard" fullWidth sx={{pb:1.5}}>
                        <InputLabel>{localization.ticket.location[context.lang]}</InputLabel>
                        <Select
                            value={city}
                            onChange={(e) => {setCity(e.target.value) }}
                            label={localization.ticket.location[context.lang]}
                        >
                            <MenuItem value="">...</MenuItem>
                            <MenuItem value="Office">In office</MenuItem>
                            <MenuItem value="Lefkoşa">Lefkoşa</MenuItem>
                            <MenuItem value="Girne">Girne</MenuItem>
                            <MenuItem value="Gazimağusa">Gazimağusa</MenuItem>
                            <MenuItem value="İskele">İskele</MenuItem>
                            <MenuItem value="Güzelyurt">Güzelyurt</MenuItem>
                            <MenuItem value="Lefke">Lefke</MenuItem>
                        </Select>
                    </FormControl>
                    <Tooltip title={`Filter jobs`}>
                        <FormControl variant="standard" fullWidth sx={{pb:1.5}}>
                            <InputLabel>Job by</InputLabel>
                            <Select
                                value={jobBy}
                                onChange={(e) => {setJobBy(e.target.value)}}
                                label="Job by"
                            >
                                <MenuItem value={false}>All</MenuItem>
                                {
                                    context.isAdmin && users.map(e => <MenuItem key={e.username} value={e.username}>{e.name}</MenuItem> )
                                    ||
                                    <MenuItem value="anything">My jobs</MenuItem>
                                }
                            </Select>
                        </FormControl>
                    </Tooltip>
                </DialogContent>
            </Dialog>
        </>
    )
}

function PriorityBox(props){
    const {priority} = props;

    const priorityColors = {
        high: red[600],
        medium: green[500],
        low: grey[500]
    }

    const priorityText = {
        high: "H",
        medium: "M",
        low: "L"
    }

    return (
        <Box
            borderRadius={5}
            bgcolor={priorityColors[priority]}
            p={0.5}
            pl={2}
            pr={2}
            color="white"
            display="flex"
            alignItems="center"
            justifyContent="center"
            height={20}
        >
            <Typography
                fontSize="small"
            >
                {priorityText[priority]}
            </Typography>
        </Box>
    )
}