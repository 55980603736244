import { AppBar, Button, Dialog, DialogContent, Grid, IconButton, TextField, Toolbar, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useState } from "react";
import { getEvent } from "../api/calendar";
import Ticket from "./ticket";

export default function EventDialog(props) {
    const {eventId,isForTicket} = props;
    const [open,setOpen] = useState(false);
    const [event,setEvent] = useState({});
    const [ticketId,setTicketId] = useState(undefined);
    const [edit,setEdit] = useState(false);

    const handleClose = () => {
        setOpen(false);
        setEvent({});
    }

    const loadEvent = async () => {
        setEvent(await getEvent(eventId));
    }

    const handleOpen = () => {
        setOpen(true);
        loadEvent();
    }

    const openTicket = (id) => {
        setTicketId(id);
        setEdit(true);
    }

    return (
        <>
            <div onClick={handleOpen}>
                {props.children}
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <AppBar sx={{ position: 'sticky' }}>
                    <Toolbar variant="dense">
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon fontSize="small"/>
                        </IconButton>
                        <Typography>Event</Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Grid container gap={2} sx={{width:"100%"}}>
                        {!isForTicket && event.ticketId && <Grid item xs={12}><Button onClick={() => openTicket(event.ticketId)} fullWidth>Go to ticket</Button></Grid>}
                        {Object.keys(event).map(e => {
                            let keyTemplate = template[e];
                            let value = event[e];
                            if (!keyTemplate?.display) return <></>;
                            if (keyTemplate.button) return <Grid key={value} item xs={12}><Button onClick={() => window.open(value,'_blank')} fullWidth>{keyTemplate.buttonText}</Button></Grid>
                            if (keyTemplate.isObject)
                            return (
                                <>
                                    {Object.keys(value).map(v => {
                                        return (
                                            <Grid item xs={12} key={v}>
                                                <TextField
                                                    inputProps={{readOnly:true}}
                                                    fullWidth
                                                    value={value[v]}
                                                    label={`${e} ${v}`}
                                                    variant="standard"
                                                />
                                            </Grid>
                                        )
                                    })}
                                </>
                            )
                            return (<Grid item xs={12} key={value}>
                                <TextField
                                    inputProps={{readOnly:true}}
                                    fullWidth
                                    multiline={keyTemplate.multiline}
                                    value={value}
                                    label={e}
                                    variant="standard" 
                                />
                            </Grid>)
                        })}
                    </Grid>
                </DialogContent>
            </Dialog>
            {edit && <Ticket edit={edit} setEdit={setEdit} ticketId={ticketId} />}

        </>
    )
}
const template = {
    kind: {display: false, multiline: false, isObject: false},
    etag: {display: false, multiline: false, isObject: false},
    id: {display: false, multiline: false, isObject: false},
    status: {display: false, multiline: false, isObject: false},
    htmlLink: {display: true, multiline: false, isObject: false, button: true, buttonText: "Go to calendar"},
    created: {display: false, multiline: false, isObject: false},
    updated: {display: true, multiline: false, isObject: false},
    summary: {display: true, multiline: false, isObject: false},
    description: {display: true, multiline: true, isObject: false},
    location: {display: true, multiline: false, isObject: false},
    creator: {display: true, multiline: false, isObject: true},
    start: {display: true, multiline: false, isObject: true},
    end: {display: true, multiline: false, isObject: true},
    iCalUID: {display: false, multiline: false, isObject: false},
    sequence: {display: false, multiline: false, isObject: false},
    reminders: {display: false, multiline: false, isObject: false},
    eventType: {display: false, multiline: false, isObject: false}
}