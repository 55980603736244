import request from "./request";

const getEmails = async () => {
    return await request("POST", "emails/get");
}

const setEmails = async ({notify,payment,forward,itemReminder,priorityForward}) => {
    return await request("POST", "emails/set", {notify,payment,forward,itemReminder,priorityForward});
}

export {
    getEmails,
    setEmails
}