import request from "./request";

const getSubscriptions = async () => {
    return await request("POST", "subscriptions/list", {});
}

const executeSubscription = async (_id) => {
    return await request("POST", "subscriptions/execute", {_id});
}

export {
    getSubscriptions,
    executeSubscription
}