import { Button, Divider, IconButton, Tab, Tabs } from "@mui/material";
import TabPanel from "../components/tab_panel";
import { useEffect, useState } from "react";
import ManageTickets from "../components/manage_tickets";
import UsersTable from "../components/users_table";
import MessagesTable from "../components/messages_table";
import QNAPTable from "../components/qnap_table";
import { useGlobalContext } from "../global_context";
import LogsTable from "../components/logs";
import PaymentsTable from "../components/payments_table";
import CalendarTable from "../components/calendar_table";
import Statistics from "../components/statistics";
import BansTable from "../components/bans_table";
import VouchersTable from "../components/vouchers_table";
import SubscriptionsTable from "../components/subscriptions_table";
import MenuIcon from '@mui/icons-material/Menu';
import { useSwipe } from "../use_swipe";
import ItemsTable from "../components/items_table";
import Emails from "../components/emails";
import TelnyxReservations from "../components/telnyx_reservations";

export default function ManageSection(props) {
  const {mobileViewSection} = props;
  const [context] = useGlobalContext();
  const [value,setValue] = useState("tickets");

  const [viewSections, setViewSections] = useState(!context.isMobile);
  const [viewItems, setViewItems] = useState(true);
  const [viewEvents, setViewEvents] = useState(false);
  const [viewStorage, setViewStorage] = useState(false);

  const [viewSecurity, setViewSecurity] = useState(false);
  const [viewManagement, setViewManagement] = useState(false);
  const [viewDiagnostics, setViewDiagnostics] = useState(false);
  const [viewMaterials, setViewMaterials] = useState(false);

  useEffect(() => {
    if (context.isMobile) setViewSections(mobileViewSection);
  },[mobileViewSection]);

  useEffect(() => {
    if (context.isMobile) setViewSections(false);
  },[context.isMobile])

  const handleChange = (e, newValue) => {
    setValue(newValue);
  }
  return (
    <div style={{display:"flex", width:"100%"}}>
      <div 
        style={{
          display:"flex", 
          flexDirection: "column",
          flex: 1,
          backgroundColor:"#EEEEEE",
          width: !context.isMobile ? "fit-content" : null
        }}
      >
        {!context.isMobile && 
        <IconButton 
          onClick={() => setViewSections(e => !e)}
          disableRipple
        >
          <MenuIcon />
        </IconButton>}
        {viewSections ? 
        <>
          <Tabs
            value={value}
            onChange={handleChange}
            orientation="vertical"
            className={"left-align-tab"}
          >
              {/* Items */}
              <Button className="tab-section-btn" onClick={() => setViewItems(e =>!e)}>Items</Button>
              {viewItems ? <Tab label="Tickets" value={"tickets"} /> : null}
              {viewItems ? <Tab label="Deliveries" value={"deliveries"} /> : null}
              {viewItems ? <Tab label="Invoices" value={"invoices"} /> : null}
              {/* Events */}
              <Button className="tab-section-btn" onClick={() => setViewEvents(e =>!e)}>Events</Button>
              {viewEvents ? <Tab label="Calendar" value={"calendar"} /> : null}
              {(!context.isMobile && viewEvents) ? <Tab label="Subscriptions" value={"subs"} /> : null}
              {/* Storage */}
              <Button className="tab-section-btn" onClick={() => setViewStorage(e =>!e)}>Storage</Button>
              {viewStorage ? <Tab label="QNAP" value={"qnap"} /> : null}
              {!context.isMobile && <Divider />}
              {/* Materials */}
              {(!context.isMobile && context.isAdmin) ? <Button className="tab-section-btn" onClick={() => setViewMaterials(e =>!e)}>Materials</Button> : null}
              {(context.isAdmin && !context.isMobile && viewMaterials) ? <Tab label="Vouchers" value={"vouchers"} /> : null}
              {/* Management */}
              {(!context.isMobile && context.isAdmin) ? <Button className="tab-section-btn" onClick={() => setViewManagement(e =>!e)}>Management</Button> : null}
              {(context.isAdmin && !context.isMobile && viewManagement) ? <Tab label="Users" value={"users"} /> : null}
              {(context.isAdmin && !context.isMobile && viewManagement) ? <Tab label="Messages" value={"messages"} /> : null}
              {(context.isAdmin && !context.isMobile && viewManagement) ? <Tab label="Emails" value={"emails"} /> : null}
              {/* Security */}
              {(!context.isMobile && context.isAdmin) ? <Button className="tab-section-btn" onClick={() => setViewSecurity(e =>!e)}>Security</Button> : null}
              {(context.isAdmin && !context.isMobile && viewSecurity) ? <Tab label="Bans" value={"bans"} /> : null}
              {/* Diagnostics */}
              {context.isAdmin ? <Button className="tab-section-btn" onClick={() => setViewDiagnostics(e =>!e)}>Diagnostics</Button> : null}
              {(context.isAdmin && !context.isMobile && viewDiagnostics) ? <Tab label="Stats" value={"stats"} /> : null}
              {(context.isAdmin && viewDiagnostics) ? <Tab label="Logs" value={"logs"} /> : null}
          </Tabs>
        </>
        : null}
      </div>
      <div style={{height:"100%", flexGrow: 1, width: (context.isMobile && viewSections) ? 0 : "100%"}}>
          <TabPanel value={value} index={"tickets"}>
            <ManageTickets />
          </TabPanel>
          <TabPanel value={value} index={"calendar"}>
            <CalendarTable />
          </TabPanel>
          <TabPanel value={value} index={"users"}>
            <UsersTable />
          </TabPanel>
          <TabPanel value={value} index={"messages"}>
            <MessagesTable />
          </TabPanel>
          <TabPanel value={value} index={"qnap"}>
            <QNAPTable />
          </TabPanel>
          <TabPanel value={value} index={"bans"}>
            <BansTable />
          </TabPanel>
          <TabPanel value={value} index={"logs"}>
            <LogsTable />
          </TabPanel>
          <TabPanel value={value} index={"payments"}>
            <PaymentsTable />
          </TabPanel>
          <TabPanel value={value} index={"stats"}>
            <Statistics />
          </TabPanel>
          <TabPanel value={value} index={"vouchers"}>
            <VouchersTable />
          </TabPanel>
          <TabPanel value={value} index={"subs"}>
            <SubscriptionsTable />
          </TabPanel>
          <TabPanel value={value} index={"deliveries"}>
            <ItemsTable type="delivery" />
          </TabPanel>
          <TabPanel value={value} index={"invoices"}>
            <ItemsTable type="invoice" />
          </TabPanel>
          <TabPanel value={value} index={"emails"}>
            <Emails />
          </TabPanel>
          <TabPanel value={value} index={"telnyx_reservations"}>
            <TelnyxReservations />
          </TabPanel>
      </div>
    </div>
  );
}
