import request from "./request";

const getItems = async ({description,type,locationDescription,page,rowsPerPage}) => {
    return await request("POST", "items/list", {description,locationDescription,type,page,rowsPerPage});
}

const addItem = async ({description,type,location: {description:locationDescription,longitude,latitude,city},docRef,handIn:{comment}}) => {
    return await request("POST", "items/add", {description,type,location: {description:locationDescription,longitude,latitude,city},docRef,handIn:{comment}});
}
const editItem = async ({_id,description,location: {description:locationDescription,longitude,latitude,city},docRef}) => {
    return await request("POST", "items/edit", {_id,description,location: {description:locationDescription,longitude,latitude,city},docRef});
}

const handItem = async ({itemId,comment,undo}) => {
    return await request("POST", "items/hand_in", {itemId,comment,undo});
}

const getItemDocs = async ({type}) => {
    return await request("POST", "items/get_docs", {type});
}

const deleteItem = async ({itemId}) => {
    return await request("POST", "items/delete", {itemId});
}

export {
    getItems,
    addItem,
    editItem,
    handItem,
    getItemDocs,
    deleteItem
}