import request from "./request";

const listFiles = async ({company,location,project,type}) => {
    return await request("POST", `qnap/list`, {company,location,project,type});
}

const mkdir = async ({company,location,project}) => {
    return await request("POST", `qnap/mkdir`, {company,location,project});
}

const setNote = async ({company,location,project,note}) => {
    return await request("POST", `qnap/note/set`, {company,location,project,note});
}

const getNote = async ({company,location,project}) => {
    return await request("POST", `qnap/note/get`, {company,location,project});
}

const uploadFiles = async (formData) => {
    return await request ("POST", "qnap/upload", formData, true);
}

const listPasswords = async ({company,location,project}) => {
    return await request("POST","qnap/passwords/list", {company,location,project});
}

const addPassword = async ({company,location,project,description,password}) => {
    return await request("POST","qnap/passwords/add", {company,location,project,description,password});
}

const deletePassword = async ({company,location,project,index}) => {
    return await request("POST","qnap/passwords/delete", {company,location,project,index});
}

const getChecklist = async ({company,location,project}) => {
    return await request("POST","qnap/checklist/get", {company,location,project});
}

const updateChecklist = async ({company,location,project,checklist}) => {
    return await request("POST","qnap/checklist/set", {company,location,project,checklist});
}

const rename = async ({company,location,oldName,newName}) => {
    return await request("POST","qnap/rename", {company,location,oldName,newName});
}

export {
    rename,
    getChecklist,
    updateChecklist,
    listPasswords,
    addPassword,
    deletePassword,
    uploadFiles,
    listFiles,
    mkdir,
    setNote,
    getNote
}