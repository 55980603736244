import { TableContainer, Paper, Table, TableRow, TableHead, TableCell, TableBody, Button, TextField, TableFooter, TablePagination, IconButton, Dialog, DialogActions, DialogTitle, DialogContent, FormControl, InputLabel, Select, MenuItem, Tooltip, Collapse, Grid, Typography, Toolbar, AppBar, Box, FormControlLabel, Checkbox } from "@mui/material";
import { useEffect, useState, useTransition } from "react";
import { deleteReservation, getReservations } from "../api/telnyx";
import SyncIcon  from '@mui/icons-material/Sync';
import DeleteIcon from '@mui/icons-material/Delete';

const deleteColor = "dd0000";

export default function TelnyxReservations(props){
    const [isPending, startTransition] = useTransition();
    const [reservations, setReservations] = useState([]);
    const [expired, setExpired] = useState(true);

    const [filter, setFilter] = useState('');

    const [count,setCount] = useState(0);
    const [page,setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);

    const loadReservations = () => {
        startTransition(async () => {
            let res = await getReservations({page,rowsPerPage,customerReference:filter,expired});
            if (res.message) return alert(res.message);
            setReservations(res.data);
            setCount(res.meta.total_results)
        });
    }

    useEffect(() => {
        loadReservations();
    }, [page,rowsPerPage,expired])

    const handleDeleteReservation = async (id) => {
        if (!window.confirm("Are you sure you want to cancel this reservation?")) return;
        let res = await deleteReservation(id);
        if (res.message) alert(res.message);
        loadReservations();
    }

    const handleChangePage = (e,n) => {
        setPage(n);
    }

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }

    return (
        <>
            <TableContainer component={Paper} sx={{height: "calc(100% - 52px)", borderBottomLeftRadius:0, borderBottomRightRadius:0}}>
                <Table stickyHeader size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell width={300}>Reference</TableCell>
                            <TableCell width={100}>Numbers</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>
                                <TextField
                                    fullWidth
                                    type="search"
                                    value={filter}
                                    onKeyDown={(e) => {
                                        if (e.code !== 'Enter') return;
                                        loadReservations();
                                    }}
                                    onChange={(e) => setFilter(e.target.value)}
                                    label="Customer email"
                                    size="small"
                                />
                            </TableCell>
                            <TableCell align="center">
                                <FormControlLabel control={<Checkbox checked={expired} onChange={(e) => setExpired(e.target.checked)} />} label="Expired" />
                            </TableCell>
                            <TableCell width={20}>
                                <IconButton onClick={loadReservations}>
                                    <SyncIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reservations.map(reservation => {
                            return (
                                <TableRow hover key={reservation.id}>
                                    <TableCell>{reservation.id}</TableCell>
                                    <TableCell>{reservation.phone_numbers.map(e => e.phone_number).join(", ")}</TableCell>
                                    <TableCell>{new Date(reservation.created_at).toLocaleDateString('en-GB')} -&gt; {new Date(reservation.phone_numbers[0].expired_at).toLocaleDateString('en-GB')}</TableCell>
                                    <TableCell>{reservation.customer_reference}</TableCell>
                                    <TableCell align="center">{reservation.phone_numbers[0].expired.toString()}</TableCell>
                                    <TableCell>
                                        <Tooltip title="Cancel reservation">
                                            <IconButton 
                                                onClick={() => handleDeleteReservation(reservation.id)}
                                            >
                                                <DeleteIcon style={{color: deleteColor}} />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[50, 200, 1000]}
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{position:"absolute",right:0,bottom:0}}
            />
        </>
    );
}

