import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { getMessages, setMessages } from "../api/messages";

export default function MessagesTable(){
    let [newTicket, setNewTicket] = useState("");
    let [response, setResponse] = useState("");
    let [pending, setPending] = useState("");
    let [closeTicket, setCloseTicket] = useState("");
    let [remind, setRemind] = useState("");

    const loadMessages = async () => {
        let res = await getMessages();
        setNewTicket(res.new_ticket);
        setResponse(res.response);
        setPending(res.pending);
        setCloseTicket(res.close_ticket);
        setRemind(res.remind);
    }
    useEffect(()=>{
        loadMessages();
    }, []);

    const updateMessages = async () => {
        let res = await setMessages(closeTicket,newTicket,pending,response,remind);
        alert(res.message);
        loadMessages();
    }

    return (
        <TableContainer component={Paper} sx={{height:"100%"}}>
            <Table stickyHeader align="center" sx={{ minWidth: 650 }}>
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Description</TableCell>
                        <TableCell align="left">Content</TableCell>
                        <TableCell align="right">
                            <Button onClick={updateMessages}>Update Messages</Button>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>
                        Message to send to email on a ticket submition<br/>
                        <br/>
                        This is your message when a new ticket is received either by email or through the website<br/>
                        <br/>
                        Please put the following in your text "%id%" , "%_id%"<br/>
                        <br/>
                        For example:<br/>
                        Your ticket with id: %id%, _id: %_id% has been received
                        </TableCell>
                        <TableCell colSpan={2}>
                            <TextField value={newTicket} multiline sx={{width: 800}} rows={6} onChange={(e) => {setNewTicket(e.target.value)}} />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                        Message to send when there is a new response<br/>
                        <br/>
                        This is your message when a new response is added to the ticket<br/>
                        <br/>
                        Please put the following in your text: "%date%" , "%from%", "%content%"<br/>
                        <br/>
                        For example:<br/>
                        You have received a new email from %from%<br/>
                        and its content is: %content%
                        </TableCell>
                        <TableCell colSpan={2}>
                            <TextField value={response} multiline sx={{width: 800}} rows={6} onChange={(e) => {setResponse(e.target.value)}} />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                        Message to send when ticket is put to pending<br/>
                        <br/>
                        Please put the following in your text: "%title%"<br/>
                        <br/>
                        For example:<br/>
                        The ticket %title% is now in pending status please respond!
                        </TableCell>
                        <TableCell colSpan={2}>
                            <TextField value={pending} multiline sx={{width: 800}} rows={6} onChange={(e) => {setPending(e.target.value)}} />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                        Message to send when ticket is closed<br/>
                        <br/>
                        Please put the following in your text: "%title%"<br/>
                        <br/>
                        For example:<br/>
                        The ticket %title% is now closed!
                        </TableCell>
                        <TableCell colSpan={2}>
                            <TextField value={closeTicket} multiline sx={{width: 800}} rows={6} onChange={(e) => {setCloseTicket(e.target.value)}} />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            Message to send when customer doesn't respond for 2 days on pending
                        </TableCell>
                        <TableCell colSpan={2}>
                            <TextField value={remind} multiline sx={{width: 800}} rows={6} onChange={(e) => {setRemind(e.target.value)}} />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}