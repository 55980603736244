import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { getEmails, setEmails } from "../api/emails";

const placeholder = "ex. email1@gmail.com,email2@gmail.com";

export default function Emails(){

    const [forward,setForward] = useState("");
    const [notify,setNotify] = useState("");
    const [payment,setPayment] = useState("");
    const [itemReminder,setItemReminder] = useState("");
    const [priorityForward,setPriorityForward] = useState("");

    const handleSaveEmails = async () => {
        let res = await setEmails({
            notify: notify.split(",").map(e => e.trim()).filter(e => e.length > 0),
            payment: payment.split(",").map(e => e.trim()).filter(e => e.length > 0),
            forward: forward.split(",").map(e => e.trim()).filter(e => e.length > 0),
            itemReminder: itemReminder.split(",").map(e => e.trim()).filter(e => e.length > 0),
            priorityForward: priorityForward.split(",").map(e => e.trim()).filter(e => e.length > 0)
        });
        if (res.message) return alert(res.message);
        alert("Emails have been saved");
        loadEmails();
    }

    const loadEmails = async () => {
        let res = await getEmails();
        if (res.message) return alert(res.message);
        const {emails} = res;
        setPriorityForward(emails.priorityForward.join(','));
        setForward(emails.forward.join(","))
        setNotify(emails.notify.join(","))
        setPayment(emails.payment.join(","))
        setItemReminder(emails.itemReminder.join(","))
    }
    useEffect(() => {
        loadEmails();
    },[]);

    return (
        <TableContainer component={Paper} sx={{height:"100%", width:"100%"}}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell width={150}>Emails</TableCell>
                        <TableCell></TableCell>
                        <TableCell width={20}>
                            <Button
                                onClick={handleSaveEmails}
                            >
                                Save
                            </Button>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>Forward emails</TableCell>
                        <TableCell colSpan={2}>
                            <TextField 
                                sx={{width: 500}}
                                placeholder={placeholder}
                                value={forward}
                                onChange={(e) => setForward(e.target.value)}
                                size="small"
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Priority forward emails</TableCell>
                        <TableCell colSpan={2}>
                            <TextField 
                                sx={{width: 500}}
                                placeholder={placeholder}
                                value={priorityForward}
                                onChange={(e) => setPriorityForward(e.target.value)}
                                size="small"
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Notification emails</TableCell>
                        <TableCell colSpan={2}>
                            <TextField
                                sx={{width: 500}}
                                placeholder={placeholder}
                                value={notify}
                                onChange={(e) => setNotify(e.target.value)}
                                size="small"
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Payment emails</TableCell>
                        <TableCell colSpan={2}>
                            <TextField
                                sx={{width: 500}}
                                placeholder={placeholder}
                                value={payment}
                                onChange={(e) => setPayment(e.target.value)}
                                size="small"
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Item reminder emails</TableCell>
                        <TableCell colSpan={2}>
                            <TextField
                                sx={{width: 500}}
                                placeholder={placeholder}
                                value={itemReminder}
                                onChange={(e) => setItemReminder(e.target.value)}
                                size="small"
                            />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}