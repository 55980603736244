import { Tab, Tabs } from "@mui/material";
import TabPanel from "./tab_panel";
import { useState } from "react";
import TicketsTable from "./tickets_table";
import { useGlobalContext } from "../global_context";

export default function ManageTickets() {
  const [context] = useGlobalContext();
  const [value,setValue] = useState(1);

  const handleChange = (e, newValue) => {
    setValue(newValue);
  }
  return (
    <>
        <Tabs
          centered
          value={value}
          onChange={handleChange}
          variant={context.isMobile ? "scrollable":"fullWidth"}
          sx={{bgcolor: "#EEEEEE"}}
        >
          <Tab label="All statuses" />
          <Tab label="Not reviewed" />
          <Tab label="Incomplete" />
          <Tab label="Pending confirmation" />
          <Tab label="Complete" />
        </Tabs>
        <TabPanel style={{height:"calc(100% - 48px)"}} value={value} index={value}>
          <TicketsTable status={value} />
        </TabPanel>
    </>
  );
}
