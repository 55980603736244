import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useState } from "react";

export function getThisMonthStart(){
    let date = new Date();
    return `${date.getFullYear()}-${date.getMonth()+1}-01`;
}

export function getNextMonthStart(){
    let date = new Date(getThisMonthStart());
	date.setMonth(date.getMonth() + 1);
    return `${date.getFullYear()}-${(date.getMonth()+1).toString().padStart(2,"0")}-01`
}

export function getAfterThreeMonthsStart(){
    let date = new Date(getThisMonthStart());
	date.setMonth(date.getMonth() + 3);
    return `${date.getFullYear()}-${(date.getMonth()+1).toString().padStart(2,"0")}-01`
}

export default function DatePickerDialog(props) {
    const {setDatePicker,isThreeMonths} = props;
    const [fromDate, setFromDate] = useState(getThisMonthStart());
    const [toDate, setToDate] = useState(isThreeMonths ? getAfterThreeMonthsStart() : getNextMonthStart());
    return (
        <Dialog
            open={props.open}
            onClose={() => setDatePicker(false)}
        >
            <DialogTitle>
                Filter by date
            </DialogTitle>
            <DialogContent>
                <TextField
                    sx={{mt: 2,mb: 2}}
                    fullWidth
                    label="From date"
                    type="date"
                    value={fromDate}
                    size="small"
                    onChange={(e) => setFromDate(e.target.value)}
                    InputLabelProps={{shrink: true}}
                />
                <TextField
                    sx={{mt: 2}}
                    fullWidth
                    label="To date"
                    type="date"
                    value={toDate}
                    size="small"
                    onChange={(e) => setToDate(e.target.value)}
                    InputLabelProps={{shrink: true,}}
                />
            </DialogContent>
            <DialogActions>
                <Button 
                    onClick={() => props.clearFilter()}>
                    Clear filter
                </Button>
                <Button 
                    onClick={() => props.applyFilter(new Date(fromDate).getTime(),new Date(toDate).getTime())}
                >
                    Apply filter
                </Button>
            </DialogActions>
        </Dialog>
    );
}