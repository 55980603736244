import { TableContainer, Paper, Table, TableRow, TableHead, TableCell, TableBody, TextField, TablePagination, IconButton, Dialog, DialogActions, DialogTitle, DialogContent, Button, Collapse } from "@mui/material";
import { useEffect, useState, useTransition } from "react";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SyncIcon from '@mui/icons-material/Sync';
import Ticket from "./ticket";
import { countLogs, listLogs } from "../api/logs";
import { useGlobalContext } from "../global_context";
import DatePickerDialog from "./date_filter";
import FilterAltIcon from '@mui/icons-material/FilterAlt';

export default function LogsTable(props){
    const [context, setContext] = useGlobalContext();

    let [isPending, startTransition] = useTransition();

    let [searchObject, setSearchObject] = useState("");
    let [searchType, setSearchType] = useState("");
    let [searchUser, setSearchUser] = useState(undefined);
    let [logs,setLogs] = useState([]);
    
    let [count, setCount] = useState(0);
    let [page,setPage] = useState(0);
    let [rowsPerPage, setRowsPerPage] = useState(50);

    let [edit, setEdit] = useState(false);
    let [id, setId] = useState(-1);

    const [datePicker, setDatePicker] = useState(false);
    const [fromDate,setFromDate] = useState(0);
    const [toDate,setToDate] = useState(Number.MAX_SAFE_INTEGER);

    useEffect(() => {
            loadLogs();
    },[searchUser,searchObject,searchType,fromDate,toDate]);

    const loadLogs = () => {
        startTransition(async() => {
            let {count} = await countLogs(searchUser,searchObject,searchType,fromDate,toDate);
            setCount(count);
            let res = await listLogs(searchUser,searchObject,searchType,page,rowsPerPage,fromDate,toDate);
            setLogs(res);
        });
    }

    useEffect(() => {
        loadLogs();
    },[page,rowsPerPage])
    const handleChangePage = (e,n) => {
        setPage(n);
    }

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }
    const applyDateFilter = (fromDate,toDate) => {
        setFromDate(fromDate);
        setToDate(toDate);
        setDatePicker(false)
    }

    const headProps = {loadLogs,setDatePicker,searchUser,searchObject,searchType,setSearchUser,setSearchObject,setSearchType};

    return (
    <div style={{height:"100%", width:"100%", position: "relative"}}>
        <DatePickerDialog open={datePicker} applyFilter={applyDateFilter} clearFilter={() => {applyDateFilter(0,Number.MAX_SAFE_INTEGER)}} setDatePicker={setDatePicker} />
        <TableContainer component={Paper} sx={{height: "calc(100% - 52px)", borderBottomLeftRadius:0, borderBottomRightRadius:0}}>
            <Table stickyHeader dense size="small">
                {context.isMobile ? <MobileHead props={headProps} />:<DesktopHead props={headProps} />}
                <TableBody>
                    {logs.map((e) => {
                        const rowProps = {e,setEdit,setId}
                        return context.isMobile ? <MobileRow props={rowProps} /> : <DesktopRow props={rowProps} />
                    })}
                </TableBody>
            </Table>
            {edit && <Ticket edit={edit} setEdit={setEdit} ticketId={id} />}
        </TableContainer>
        <TablePagination
            rowsPerPageOptions={[50, 200, 1000]}
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{position:"absolute",right:0,bottom:0}}
        />
    </div>);
}

function DesktopHead(props) {
    const {loadLogs,setDatePicker,searchUser,searchObject,searchType,setSearchUser,setSearchObject,setSearchType} = props.props;
    return (
        <TableHead>
            <TableRow>
                <TableCell sx={{width:150}}>Date
                    <IconButton onClick={() => {setDatePicker(true);}}>
                        <CalendarMonthIcon />
                    </IconButton>
                </TableCell>
                <TableCell sx={{width:200}}>
                <TextField type="search" label="Search user" size="small" value={searchUser} onChange={(e)=>{
                        setSearchUser(e.target.value);
                    }} />
                </TableCell>
                <TableCell sx={{width:250}}>
                    <TextField type="search" label="Search affected object" size="small" value={searchObject} fullWidth onChange={(e)=>{
                        setSearchObject(e.target.value);
                    }} />
                </TableCell>
                <TableCell sx={{width:250}}>
                    <TextField  type="search" label="Search action" size="small" value={searchType} fullWidth onChange={(e)=>{
                        setSearchType(e.target.value);
                    }} />
                </TableCell>
                <TableCell>
                    <IconButton onClick={() => {loadLogs()}}>
                        <SyncIcon />
                    </IconButton>
                </TableCell>
                <TableCell></TableCell>
            </TableRow>
        </TableHead>
    )
}

function MobileFilter(props){
    const [context, setContext] = useGlobalContext();
    const {setDatePicker,searchUser,searchObject,searchType,setSearchUser,setSearchObject,setSearchType} = props.funcs;
    const [open,setOpen] = useState(false);
    return (
        <>
            <IconButton onClick={() => {setOpen(!open)}}>
                <FilterAltIcon />
            </IconButton>
            <Dialog
                open={open}
                onClose={() => {setOpen(false);}}
            >
                <DialogContent>
                    <IconButton onClick={() => {setDatePicker(true);}} sx={{fontSize:"medium"}}>
                        Filter by date &nbsp;<CalendarMonthIcon />
                    </IconButton>
                    <TextField sx={{mb: 2}} type="search" label="Search user" size="small" value={searchUser} onChange={(e)=>{
                        setSearchUser(e.target.value);
                    }} />
                    <TextField sx={{mb: 2}} type="search" label="Search affected object" size="small" value={searchObject} fullWidth onChange={(e)=>{
                        setSearchObject(e.target.value);
                    }} />
                    <TextField sx={{mb: 2}}  type="search" label="Search action" size="small" value={searchType} fullWidth onChange={(e)=>{
                        setSearchType(e.target.value);
                    }} />
                </DialogContent>
            </Dialog>
        </>
    )
}

function MobileHead(props) {
    const {loadLogs} = props.props;
    return (
        <TableHead>
            <TableRow>
                <TableCell>Affected object</TableCell>
                <TableCell width={10}> 
                    <MobileFilter 
                        funcs={props.props}
                    />
                </TableCell>
                <TableCell>
                    <IconButton onClick={() => {loadLogs()}}>
                        <SyncIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
        </TableHead>
    )
}

function DesktopRow(props) {
    const { e, setEdit, setId } = props.props;
    return (
        <TableRow hover key={e._id}>
            <TableCell>{new Date(e.date).toLocaleString('en-GB')}</TableCell>
            <TableCell>{e.username}</TableCell>
            <TableCell>{
                !e.object.startsWith("Ticket") && e.object || 
                <div onClick={()=> {
                    setId(parseInt(e.object.split(" ")[1].replace(new RegExp("'",'g'),"")));
                    setEdit(true);
                }} className="log-ticket">{e.object}</div>
            }</TableCell>
            <TableCell>{e.type}</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
        </TableRow>
    )
}

function MobileRow(props) {
    const { e, setEdit, setId } = props.props;
    const [open, setOpen] = useState(false);
  
    return (
      <>
        <TableRow key={e._id} hover sx={{ '& > *': { borderBottom: 'unset' }, height: 50 }} onClick={()=>{setOpen(!open)}}>
          <TableCell colSpan={3}>{
                !e.object.startsWith("Ticket") && e.object || 
                <div style={{width:"fit-content"}} onClick={()=> {
                    setId(parseInt(e.object.split(" ")[1].replace(new RegExp("'",'g'),"")));
                    setEdit(true);
                }} className="log-ticket">{e.object}</div>
            }</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ padding: 0,borderBottom: 0 }} colSpan={5}>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Table size="small">
                    <TableBody>
                        <TableRow><TableCell sx={{borderBottom:0}}>Date: {new Date(e.date).toLocaleString('en-GB')}</TableCell></TableRow>
                        <TableRow><TableCell sx={{borderBottom:0}}>By user: {e.username}</TableCell></TableRow>
                        <TableRow><TableCell>Action: {e.type}</TableCell></TableRow>
                    </TableBody>
                </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
}