import { TableContainer, Paper, Table, TableRow, TableHead, TableCell, TableBody, Button, TextField, TableFooter, TablePagination, IconButton, Dialog, DialogActions, DialogTitle, DialogContent, FormControl, InputLabel, Select, MenuItem, Tooltip, Collapse, Grid, Typography, Toolbar, AppBar, Box } from "@mui/material";
import { useEffect, useState, useTransition } from "react";
import { useGlobalContext } from "../global_context";
import { executeSubscription, getSubscriptions } from "../api/subscriptions";
import { useInterval } from "../use_interval";
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab';

export default function SubscriptionsTable(props){
    const [context] = useGlobalContext();
    const [isPending, startTransition] = useTransition();
    const [subscriptions,setSubscriptions] = useState([]);

    const loadSubscriptions = () => {
        startTransition(async () => {
            let res = await getSubscriptions();
            if (res.message === "Not authorized") return;
            setSubscriptions(res);
        });
    }

    const handleExecuteSubscription = async (_id) => {
        if (!window.confirm("Are you sure you want to execute this subscription?")) return;
        let res = await executeSubscription(_id);
        if (res.message) return alert(res.message);
        loadSubscriptions();
    }

    useInterval(loadSubscriptions, 10*1000);

    useEffect(() => {
        loadSubscriptions();
    }, [])

    
    return (
        <TableContainer component={Paper} sx={{height:"100%", borderBottomLeftRadius:0, borderBottomRightRadius:0}}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>Code</TableCell>
                        <TableCell>Company</TableCell>
                        <TableCell>Draft no.</TableCell>
                        <TableCell width={200}>Days left</TableCell>
                        <TableCell>End date</TableCell>
                        <TableCell align="center">Executed?</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {[...subscriptions].map((subscription) => {
                        const {code,customer,invoice,endDate} = subscription;
                        return (
                            <TableRow hover key={subscription._id}>
                                <TableCell>{code}</TableCell>
                                <TableCell>{context.customers.obj[customer]}</TableCell>
                                <TableCell>{invoice}</TableCell>
                                <TableCell>
                                    {Math.floor(new Date(new Date(subscription.endDate) - new Date()).getTime()/86400000)} days
                                </TableCell>
                                <TableCell>{endDate ? new Date(endDate).toLocaleDateString('en-GB') : "N/A"}</TableCell>
                                <TableCell align="center">
                                    {!subscription.executed ? 
                                    <Tooltip title="Execute template">
                                        <IconButton onClick={() => handleExecuteSubscription(subscription._id)}>
                                            <KeyboardTabIcon />
                                        </IconButton>
                                    </Tooltip>: "Executed"}
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

