import request from "./request";

const getReservations = async ({page,rowsPerPage,customerReference,expired}) => {
    return await request("POST", "telnyx/get_reservations", {page,rowsPerPage,customerReference,expired});
}

const deleteReservation = async (id) => {
    return await request("POST", "telnyx/delete_reservation", {id});
}

export {
    getReservations,
    deleteReservation
}