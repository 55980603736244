import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { IconButton, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { addUser } from '../api/users';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddUserDialog(props) {
  const [username,setUsername] = React.useState("");
  const [password,setPassword] = React.useState("");
  const [fullName,setFullName] = React.useState("");

  const add = async () => {
    let res = await addUser(username, password, fullName);
    alert(res.message);
    handleClose()
  }
  const handleClose = () => {
    props.setAddDialog(false);
  };

  return (
    <Dialog
        open={props.addDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"New user"}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Enter the new user's information
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Username"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => {setUsername(e.target.value);}}
          />
          <TextField
            margin="dense"
            label="Full Name"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => {setFullName(e.target.value);}}
          />
          <TextField
            margin="dense"
            label="Password"
            type="password"
            fullWidth
            variant="standard"
            onChange={(e) => {setPassword(e.target.value);}}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={add}>Add</Button>
        </DialogActions>
      </Dialog>
  );
}
