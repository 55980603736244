import request from "./request";

const listLogs = async (username, object, type, page, rowsPerPage, fromDate, toDate) => {
    return await request("POST", "logs/list", {username, object, type, page, rowsPerPage, fromDate, toDate})
}

const countLogs = async (username, object, type, fromDate,toDate) => {
    return await request("POST", "logs/count", {username, object, type, fromDate, toDate})
}

export {
    countLogs,
    listLogs
}