import PropTypes from 'prop-types';

export default function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        hidden={value !== index}
        {...other}
        style={{...props.style, height: props?.style?.height || "100%"}}
      >
        {(value === index) && children}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };