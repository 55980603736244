import { Button, Divider, FormControl, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import request from "../api/request";
import { getStatisticsList } from "../api/statistics";

export default function Statistics(){
    const [list, setList] = useState([]);
    const [section, setSection] = useState({id: -1});
    const [results, setResults] = useState([]);
    const query = useRef({});
    
    const loadList = async () => {
        setList(await getStatisticsList());
    }

    const loadStatistics = async () => {
        setResults(await request("POST", section.api, query.current));
    }

    useEffect(() => {
        loadList()
    }, []);

    useEffect(() => {
        if (!section.queryParams) return;
        query.current = {};
        section.queryParams.forEach((param) => query.current[param.field] = param.default);
    }, [section])

    return (
        <Paper sx={{height:"100%", display: "flex", flexFlow: "column"}}>
            <FormControl sx={{ml:2,mr:2,mt:2}} variant="standard" >
                <InputLabel shrink>Statistics section</InputLabel>
                <Select
                    label="Statistics section"
                    value={section.id}
                    onChange={(e) => {
                        query.current = {};
                        setSection(list.find(x => x.id === e.target.value) || {id:-1})
                    }}
                >
                <MenuItem value={-1}>Select report type...</MenuItem>
                {list.map((e,i) => {
                    return (
                    <MenuItem key={i} value={e.id}>{e.name}</MenuItem>
                    )
                })}
                </Select>
            </FormControl>
            <Divider sx={{mt:2, mb:2}} />
            { section.queryParams && 
                <>
                    <div style={{marginLeft:"1em", marginRight:"1em", height:"fit-content", display:"flex", alignItems:"flex-end"}}>
                        {section?.queryParams?.map((e,i) => {
                            return (
                                <TextField
                                    key={e.field}
                                    defaultValue={e.default} 
                                    size="small" label={e.name} 
                                    sx={{mr:2}} variant="standard" 
                                    type={e.type} 
                                    required={e.required}
                                    onChange={(k) => query.current[e.field] = k.target.value}
                                    InputLabelProps={e.type === 'date' && {shrink: true}} 
                                />
                            )
                        })}
                        <Button size="small" variant="outlined" onClick={loadStatistics}>
                            Load
                        </Button>
                    </div>
                    <Divider sx={{mt:2}} />
                    <TableContainer sx={{height: "100%"}}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {section?.returnFields?.map(column => {
                                        return <TableCell key={column.field}>{column.name}</TableCell>
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {results.map((result,i) => {
                                    return (
                                        <TableRow hover key={i}>
                                            {section?.returnFields?.map(column => {
                                                return <TableCell key={column.field}>{result[column.field]}</TableCell>
                                            })}
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            }
        </Paper>
    )
}