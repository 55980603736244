import { Tab, Tabs, Box, IconButton, Select, MenuItem, Tooltip, Dialog, Toolbar, AppBar, Typography, Menu, Divider } from "@mui/material";
import Logo from '../images/logo.png';
import { useEffect, useState } from "react";
import HomeTab from "./home";
import ContactTab from "./contact";
import CreateTicketDialog, { ViewTicketDialog } from "../components/create_ticket_dialog";
import TabPanel from "../components/tab_panel";
import ManageSection from "./manage_section";
import LogoutIcon from '@mui/icons-material/Logout';
import { useGlobalContext } from "../global_context";
import { isLoggedIn, logout } from "../api/auth";
import { IconFlagTR, IconFlagUK } from 'material-ui-flags'
import localization from '../localization.json';
import { useNavigate } from 'react-router';
import MenuIcon from '@mui/icons-material/Menu';
import { useInterval } from "../use_interval";
import { useWindowSize } from "@react-hook/window-size";
import { getCustomers } from "../api/ticket";

export default function MainPage(props){
    let navigate = useNavigate();
    const [context,setContext] = useGlobalContext();
    const [lastValue,setLastValue] = useState(0);
    const [value,setValue] = useState(0);
    const [viewTicket, setViewTicket] = useState(false);
    const [langMenu,setLangMenu] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [windowWidth] = useWindowSize();
    const [mobileViewSection, setMobileViewSection] = useState(false);

    useEffect(() => {
        if (!context.stayMobile) setContext({...context, isMobile: windowWidth <= 768})
    }, [windowWidth]);

    useEffect(()=>{
        (async() => {
            let res = await isLoggedIn();
            if (!res.logged_in) return;
            let customers = await getCustomers();
            setContext({...context, authorized: true, isAdmin: res.is_admin, fullName: res.name, username: res.username, customers});
        })();
    },[]);

    const handleChange = function(e,newValue){
        if (newValue !== 4) navigate('/');
        else navigate("/payment");
        setLastValue(value);
        setValue(newValue);
    };
    const logoutAction = async () => {
        let res = await logout();
        setValue(0);
        setContext({...context, authorized: false, isAdmin: false, fullName: "", username: ""});
    }
    const languageChange = (e) => {
        setContext({...context, lang: e.target.value});
        localStorage.setItem('lang',e.target.value.toString());
        setLangMenu(false);
    }
    const handleClose = () => {
        setAnchorEl(null);
    }
    const handleViewSection = () => {
        if (value !== 3) return;
        setMobileViewSection(e => !e);
    }
    return (
        <Box sx={{display:"flex", flexDirection:"column", height: 1}}>
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    sx={{display: {xs: 'none', md: 'block'}, color: 'white'}}
                >
                    <img className="click-hover" style={{marginRight:"1em", height:150}} src={Logo} onClick={()=>{window.location.href = "https://www.akcaba.com/"}} />
                    <Tab value={0} label={localization.homePage.text[context.lang]} />
                    <Tab value={1} label={localization.ticket.createTicket[context.lang]} />
                    <Tab value={2} label={localization.contactUs.text[context.lang]} />
                    {context.authorized && <Tab value={3} label="management" />}
                    <div style={{display:"flex", alignItems: "center",marginLeft: 25, height: 150}}>
                        <Select
                            value={context.lang}
                            onChange={languageChange}
                            onClose={()=>{setLangMenu(false)}}
                            open={langMenu} sx={{width:0,height:0,opacity: 0}}
                        >
                            <MenuItem value="en"><IconFlagUK/></MenuItem>
                            <MenuItem value="tr"><IconFlagTR/></MenuItem>
                        </Select>
                        <Tooltip title="Click here to change the language">
                            <IconButton onClick={() => {setLangMenu(true)}} aria-label="logout">
                                {context.lang === 'en' && <IconFlagUK />}
                                {context.lang === 'tr' && <IconFlagTR />}
                            </IconButton>
                        </Tooltip>
                    </div>
                    {context.authorized && 
                        <div style={{display:"flex", alignItems: "center",marginLeft: 25, height: context.isMobile ? 51 : 150}}>
                            <Tooltip title="Click here to logout">
                                <IconButton onClick={logoutAction} aria-label="logout">
                                    <LogoutIcon fontSize="medium" />
                                </IconButton>
                            </Tooltip>
                        </div>
                    }
                </Tabs>
            </Box>
            <Divider />
            <AppBar sx={{ position: 'sticky', display: {xs: 'block', md: 'none'} }} color="inherit">
                <Toolbar variant="dense">
                    <Typography onClick={handleViewSection} sx={{ ml: 2,mr: 2, flex: 1 }} variant="h6" component="div">
                        {({
                            0:localization.homePage.text[context.lang],
                            1:localization.ticket.createTicket[context.lang],
                            2:localization.contactUs.text[context.lang],
                            3:"Management",
                        })[value].toUpperCase()}
                    </Typography>
                    <IconButton
                        onClick={(e) => {setAnchorEl(e.currentTarget)}}
                        edge="start"
                        color="inherit"
                        >
                            <MenuIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Menu onClose={handleClose} anchorEl={anchorEl} open={anchorEl !== null}>
                <div style={{width:"50vw"}}></div>
                <MenuItem onClick={() => {setValue(0); handleClose();}}>{localization.homePage.text[context.lang].toUpperCase()}</MenuItem>
                <MenuItem onClick={() => {setValue(1); handleClose();}}>{localization.ticket.createTicket[context.lang].toUpperCase()}</MenuItem>
                <MenuItem onClick={() => {setValue(2); handleClose();}}>{localization.contactUs.text[context.lang].toUpperCase()}</MenuItem>
                {context.authorized && <MenuItem onClick={() => {setValue(3); handleClose();}}>MANAGEMENT</MenuItem>}
                {context.authorized && <MenuItem onClick={logoutAction}>LOGOUT</MenuItem> ||
                <MenuItem onClick={() => {navigate("/login")}}>LOGIN</MenuItem>}
                <Tooltip title="Click here to change the language">
                    <MenuItem onClick={() => {setLangMenu(!langMenu)}}>
                        <Select
                            value={context.lang}
                            onChange={languageChange}
                            onClose={()=>{setLangMenu(false)}}
                            open={langMenu} sx={{width:0,height:0,opacity: 0}}
                        >
                            <MenuItem value="en"><IconFlagUK/></MenuItem>
                            <MenuItem value="tr"><IconFlagTR/></MenuItem>
                        </Select>
                            {context.lang === 'en' && <IconFlagUK />}
                            {context.lang === 'tr' && <IconFlagTR />}
                    </MenuItem>
                </Tooltip>
            </Menu>
            <Box sx={{height: {xs: "calc(100% - 48px)", md: "calc(100% - 150px)"}}}>
                <TabPanel value={value} index={0}><HomeTab createTicket={()=>{handleChange(undefined,1)}} /></TabPanel>
                <TabPanel value={value} index={1}>
                {!viewTicket && <CreateTicketDialog lastValue={lastValue} setValue={setValue} value={value} setViewTicket={setViewTicket} /> 
                || viewTicket && <ViewTicketDialog lastValue={lastValue} setValue={setValue} deactivate={() => {setViewTicket(false);}} active={viewTicket} />}
                </TabPanel>
                <TabPanel value={value} index={2}><ContactTab /></TabPanel>
                {context.authorized && <TabPanel style={{height:"100%"}} value={value} index={3}>
                    <Box sx={{bgcolor: 'background.paper', display: 'flex', height: "100%"}}>
                        <ManageSection mobileViewSection={mobileViewSection} />
                    </Box>
                </TabPanel>}
            </Box>
        </Box>
    );
}