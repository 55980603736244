import request from "./request";

const login = async (data) => {
    return await request("POST", "auth/login", data);
}

const logout = async () => {
    return await request("POST", "auth/logout");
}

const isLoggedIn = async () => {
    return await request("POST", "auth/logged_in");
}

export {login, logout, isLoggedIn};