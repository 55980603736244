import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './app';
import {BrowserRouter} from 'react-router-dom';
import GlobalStateProvider from './global_context';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <GlobalStateProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </GlobalStateProvider>
);

