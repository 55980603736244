import request from "./request";

const getEvents = async ({fromDate,toDate,search,maxResults}) => {
    return await request("POST", "calendar/get_events", {fromDate,toDate,search,maxResults});
}

const getEvent = async (eventId) => {
    return await request("POST", "calendar/get_event", {eventId});
}

const createEvent = async ({title,description,start,end,location,ticketId}) => {
    return await request("POST", "calendar/create_event",{title,description,start,end,location,ticketId});
}

export {getEvents, getEvent, createEvent};