import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {useGlobalContext} from '../global_context';
import localization from '../localization.json';
import { forwardRef, useEffect, useState } from 'react';
import { createEvent } from '../api/calendar';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function CreateEventDialog(props) {
  const {ticketId,isForTicket,loadTicket} = props;
  const [open,setOpen] = useState(false);
  const [context,setContext] = useGlobalContext();
  const [loading,setLoading] = useState(false);
  const [title,setTitle] = useState("");
  const [description,setDescription] = useState("");
  const [location,setLocation] = useState("");
  const [fromDate,setFromDate] = useState(new Date().toISOString().substring(0,16));
  const [toDate,setToDate] = useState(new Date().toISOString().substring(0,16));

  const handleClose = () => {
    if (loading) return;
    setOpen(false);
  };

  const sendEvent = async () => {
    setLoading(true);
    let res = await createEvent({title,description,start: new Date(fromDate).toISOString(),end: new Date(toDate).toISOString(),location,ticketId})
    setLoading(false);
    alert("Event has been added");
    if (isForTicket) loadTicket();
    handleClose();
  }

  return (
    <>
    <div onClick={() => setOpen(true)}>
      {props.children}
    </div>
    <Dialog
        open={open}
        TransitionComponent={Transition}
        onClose={handleClose}
        fullWidth
        fullScreen={context.isMobile}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Event
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Create a new event for the google calendar
          </DialogContentText>
          <Grid container gap={2} sx={{pt:2, pb:2, width:"100%"}} >
            <Grid item sx={{width:"100%"}}>
                <TextField
                    variant="standard" 
                    label="Title" 
                    fullWidth
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
            </Grid>
            <Grid item sx={{width:"100%"}}>
                <TextField
                    variant="standard" 
                    label="Description" 
                    multiline
                    fullWidth
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
            </Grid>
            <Grid item sx={{width:"100%"}}>
                <TextField
                    variant="standard" 
                    label="Location"
                    fullWidth
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                />
            </Grid>
            <Grid item sx={{width:"100%"}}>
                <TextField
                    variant="standard"
                    label="From"
                    type="datetime-local"
                    value={fromDate}
                    onChange={(e) => {setFromDate(e.target.value)}}
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid item sx={{width:"100%"}}>
                <TextField
                    variant="standard"
                    label="To"
                    type="datetime-local"
                    value={toDate}
                    onChange={(e) => {setToDate(e.target.value)}}
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={sendEvent}>Add event</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
