import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { CircularProgress, Container, Divider, FormControl, IconButton, InputLabel, List, ListItem, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { uploadFiles } from '../api/qnap';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { uploadTicketFiles } from '../api/ticket';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function UploadFormDialog(props) {
  const {ticketId,company,location,project,type,onClose} = props;
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [progress, setProgress] = useState(false);
  const fileRef = useRef();
  useEffect(()=>{
    if(open) {
      setFiles([]);
    }
  },[open]);

  const handleClose = (e) => {
    if(progress) return;
    setOpen(false);
    setProgress(false);
    onClose();
    if (e) e.stopPropagation();
  }
  const uploadButton = () => {if(progress) return; fileRef.current.click(); }
  const upload = async () => {
    if(progress) return;
    if (files.length === 0) return alert("Please select your files first");
    let formData = new FormData();
    formData.append('company', company);
    formData.append('location', location);
    formData.append('project', project);
    formData.append('type', type);
    if (ticketId) formData.append('ticketId', ticketId);
    for (let file of files){
      formData.append('files', file);
    }
    setProgress(true);
    let res = ticketId ? await uploadTicketFiles(formData): await uploadFiles(formData);
    if (res.status === 1 && res.success == 'true')
      alert("File uploaded successfuly");
    handleClose();
  }
  const fileSelected = () => {
    setFiles(fileRef.current.files);
  }
  return (
    <>
        <div onClick={()=>{setOpen(true)}}>
         {props.children}
        </div>
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >
            {progress && <div onClick={(e)=>{e.preventDefaults()}} style={{position:"absolute", display:"flex", justifyContent:"center",alignItems:"center", width: "100%", height: "100%", backgroundColor: "rgba(0,0,0,0.2)"}}>
              <CircularProgress />
            </div>}
            <DialogTitle>{"Upload Files"}
            <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
            >
            <CloseIcon />
            </IconButton>
            </DialogTitle>
            <DialogContent>
              <Paper sx={{display: "flex", flexDirection: "row"}}>
                  <IconButton disableRipple className="upload-btn-glow" sx={{ m: 0, p:1, display: "flex", flexDirection: "column"}} onClick={uploadButton}>
                      <UploadFileIcon fontSize="medium" />
                      <Typography component={Container}>
                          Click to upload file
                      </Typography>
                      {open && 
                          <input ref={fileRef} type="file" style={{display: "none"}} onChange={fileSelected} multiple />
                      }
                  </IconButton>
                  <Divider orientation="vertical" />
                  <Typography fontSize="small" sx={{display: "flex", alignItems: "center", m:2}}>{Array.from(files).map(file => file.name).join(', ')}</Typography>
              </Paper>
            </DialogContent>
            <DialogActions>
            <Button onClick={upload}>Upload</Button>
            </DialogActions>
        </Dialog>
    </>
  );
}
